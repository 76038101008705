import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NotFound404 = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <div className="container">
      <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <span className='text-lightgreen font-bold md:text-[10rem] leading-none text-3xl'>{isAuthenticated ? '404' : 'Connectez-vous'}</span>
        <span className='sm:text-4xl text-xl sm:my-4 my-2 text-darkgreen font-semibold text-center'>
          {isAuthenticated
            ? 'La page que vous recherchez n\'existe pas.'
            : 'Veuillez vous connecter pour continuer.'}
        </span>
        <Link className="btn" to={isAuthenticated ? '/' : '/auth/login'}>
          {isAuthenticated ? 'Retour à l\'accueil' : 'Connexion'}
        </Link>
      </section>
    </div>
  );
};

export default NotFound404;
