import React, { useRef, useState, useEffect } from 'react';

const UICustomerDropdown = ({ options, selectedOption, onSelect, enableSearch }) => {
  const [isOpen, setIsOpen] = useState(false); // Pour gérer l'ouverture/fermeture du menu
  const [searchTerm, setSearchTerm] = useState(''); // Pour stocker le terme de recherche
  const [filteredOptions, setFilteredOptions] = useState(options); // Pour stocker les options filtrées
  const dropdownRef = useRef(null);

  // Filtrer les options en fonction du terme de recherche
  useEffect(() => {
    setFilteredOptions(
      options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, options]);

  // Fermer le menu si l'utilisateur clique en dehors du composant
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    onSelect(option); // Appelle la fonction du parent avec l'option sélectionnée
    setIsOpen(false); // Fermer le menu après la sélection
    setSearchTerm(''); // Réinitialiser le terme de recherche
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="relative flex items-center form-control justify-between cursor-pointer w-full px-3 py-1.5 text-gray-900 bg-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <input
          type="text"
          value={options.find(o => o.value === selectedOption.value)?.label || 'Sélectionner une option'}
          readOnly
          className="w-full outline-none pointer-events-none"
        />
        <div className="border-l-2 pl-2">{isOpen ? '▲' : '▼'}</div>
      </div>
      {isOpen && (
        <div className="absolute top-full left-0 right-0 border border-gray-300 bg-white z-50 rounded-b-md">
          {/* Afficher le champ de recherche uniquement si enableSearch est true */}
          {enableSearch && (
            <div className="p-2">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Rechercher..."
                className="form-control"
              />
            </div>
          )}
          <div className='max-h-48 overflow-y-auto no-scrollbar'>
            {/* Affichage des options filtrées */}
            {filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`p-2 text-gray-950 cursor-pointer sm:text-sm px-3 ${selectedOption.value === option.value ? 'bg-lightgreen text-white' : 'hover:bg-gray-100'}`}
                onClick={() => handleSelect(option)}
              >
                {option.label}
                <span className='block text-xs'>{option.client_code}</span>
              </div>
            ))}
            {/* Message si aucune option n'est trouvée */}
            {filteredOptions.length === 0 && (
              <div className="p-2 text-gray-500 text-center">Aucune option trouvée</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UICustomerDropdown;
