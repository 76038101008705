import React from 'react';
import GuestHeader from '../components/guest-header';

const OrderLayout = ({ children }) => {
  return (
    <div>
      <GuestHeader />

      <section className="px-3 mt-20">
        {children}
      </section>
    </div>
  );
};

export default OrderLayout;
