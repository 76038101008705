import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Authentication Pages
import LoginPage from './pages/auth/login';

// Dashboard and Admin Pages
import AdminDashboard from './pages/admin/index';
import PrivateRoute from './routes/private-route';

// General Pages
import Welcome from './pages/welcome/welcome';
import NotFound404 from './pages/404';

// Application Pages
import Articles from './pages/articles';
import ProductDetails from './pages/articles/product-details';
import AddProduct from './pages/articles/add-product';
import Commandes from './pages/commandes';
import OrderDetails from './pages/commandes/order-details';
import Customers from './pages/customers';
import Personnel from './pages/personnel';
import Suppliers from './pages/suppliers';
import SupplierArticles from './pages/suppliers/articles';
import Stores from './pages/stores';
import Rapports from './pages/rapports';
import Category from './pages/categories';
import SubCategories from './pages/sub-categories';
import Units from './pages/units';
import Warehouses from './pages/warehouses';
import Validations from './pages/validations';
import Supply from './pages/supply';
import AddSupply from './pages/supply/add-supply';
import UpdateSupply from './pages/supply/update-supply';
import Brands from './pages/admin/brands';
import GuestProfile from './pages/profile/guest-profile';
import RolesPermissions from './pages/roles-permissions';
import UpdateInvoice from './pages/commandes/update-invoice';
import OrderValidation from './pages/commandes/validate';
import { useSelector } from 'react-redux';
import History from './pages/commandes/history';
import CaissierValidation from './pages/caissier';
import CaissierUpdateInvoice from './pages/caissier/update-invoice';
import CaissierHistory from './pages/caissier/history';
import MagasinierValidation from './pages/magasinier';
import MagasinierUpdateInvoice from './pages/magasinier/update-invoice';
import MagasinierHistory from './pages/magasinier/history';
import MagasinierSummary from './pages/magasinier/summary';
import EditProfile from './pages/magasinier/edit-profile';
import MagasinierEditProfile from './pages/magasinier/edit-profile';
import CaissierEditProfile from './pages/caissier/edit-profile';
import Comptable from './pages/comptable';
import ComptableEditProfile from './pages/comptable/edit-profile';
import Formats from './pages/formats';
import AdminOrdersList from './pages/admin/orders';
import AdminUpdateInvoice from './pages/commandes/admin-update-invoice';
import OauthAccess from './pages/admin/oauth_access/create';
import OauthAccessIndex from './pages/admin/oauth_access';
import OauthAccessUpdate from './pages/admin/oauth_access/edit';
import ComptableUpdateInvoice from './pages/comptable/invoice';
import ProFormatInvoice from './pages/commandes/pro-format-invoice';
import AdminProFormatInvoice from './pages/admin/pro-format-invoice';
import IsActif from './pages/IsActif';
import AdminSummary from './pages/admin/summary';

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const userRole = user?.personnels?.[0]?.poste_id;
  const userStatus = user?.personnels?.[0]?.statut;
  const userAdmin = user?.role === 'Administrateur';

  return (
    <Routes>

      {/* Authentication Routes */}
      <Route path="/auth/login" element={<LoginPage />} />

      {/* Private Routes (Requires Authentication) */}
      <Route element={<PrivateRoute />}>
        {isAuthenticated && (
          <>
            {userAdmin && (
              <>
                <Route path="/" element={<Welcome />} />
                <Route path="/articles" element={<Articles />} />
                <Route path="/product-details/:id" element={<ProductDetails />} />
                <Route path="/add-product" element={<AddProduct />} />
                <Route path="/personnel" element={<Personnel />} />
                <Route path="/roles-permissions" element={<RolesPermissions />} />
                <Route path="/commandes" element={<Commandes />} />
                <Route path="/clients" element={<Customers />} />
                <Route path="/fournisseurs" element={<Suppliers />} />
                <Route path="/fournisseurs/articles" element={<SupplierArticles />} />
                <Route path="/users-profile" element={<GuestProfile />} />
                <Route path="/admin" element={<AdminDashboard />} />
                <Route path="/categories" element={<Category />} />
                <Route path="/sub-categories" element={<SubCategories />} />
                <Route path="/admin/brands" element={<Brands />} />
                <Route path="/units" element={<Units />} />
                <Route path="/formats" element={<Formats />} />
                <Route path="/warehouses" element={<Warehouses />} />
                <Route path="/stores" element={<Stores />} />
                <Route path="/validations" element={<Validations />} />
                <Route path="/order-details" element={<OrderDetails />} />
                <Route path="/rapports" element={<Rapports />} />
                <Route path="/approvisionnement" element={<Supply />} />
                <Route path="/add-supply" element={<AddSupply />} />
                <Route path="/update-supply/:itemId" element={<UpdateSupply />} />
                <Route path="/commande/:reference" element={<AdminUpdateInvoice />} />
                <Route path="/commandes/validation" element={<OrderValidation />} />
                <Route path="/edit-profile" element={<CaissierEditProfile />} />
                <Route path="/commandes/pro-format" element={<AdminProFormatInvoice />} />
                <Route path="/marques" element={<Brands />} />
                <Route path="/admin/ventes" element={<AdminOrdersList />} />
                <Route path="/admin/summary" element={<AdminSummary />} />
                <Route path="/admin/oauth-access" element={<OauthAccess />} />
                <Route path="/admin/oauth-access/orders" element={<OauthAccessIndex />} />
                <Route path="/admin/oauth-access/:reference" element={<OauthAccessUpdate />} />
              </>
            )}

            {userRole === 2 && (
              userStatus === "actif" ?
                <>
                  <Route path="/" element={<CaissierValidation />} />
                  <Route path="/commande/:reference" element={<CaissierUpdateInvoice />} />
                  <Route path="/commandes/history" element={<CaissierHistory />} />
                  <Route path="/edit-profile" element={<CaissierEditProfile />} />
                </>
                :
                <Route path="/" element={<IsActif />} />
            )}

            {userRole === 4 && (
              userStatus === "actif" ?
                <>
                  <Route path="/" element={<MagasinierValidation />} />
                  <Route path="/commande/:reference" element={<MagasinierUpdateInvoice />} />
                  <Route path="/commandes/history" element={<MagasinierHistory />} />
                  <Route path="/summary" element={<MagasinierSummary />} />
                  <Route path="/edit-profile" element={<MagasinierEditProfile />} />
                </>
                :
                <Route path="/" element={<IsActif />} />
            )}

            {userRole === 1 && (
              userStatus === "actif" ?
                <>
                  <Route path="/" element={<Commandes />} />
                  <Route path="/commande/:reference" element={<UpdateInvoice />} />
                  <Route path="/commandes/pro-format" element={<ProFormatInvoice />} />
                  <Route path="/commandes/validation" element={<OrderValidation />} />
                  <Route path="/commandes/history" element={<History />} />
                  <Route path="/edit-profile" element={<EditProfile />} />
                </>
                :
                <Route path="/" element={<IsActif />} />
            )}

            {userRole === 8 && (
              userStatus === "actif" ?
                <>
                  <Route path="/" element={<Comptable />} />
                  <Route path="/commande/:reference" element={<ComptableUpdateInvoice />} />
                  <Route path="/edit-profile" element={<ComptableEditProfile />} />
                </>
                :
                <Route path="/" element={<IsActif />} />
            )}
          </>
        )}
      </Route>


      {/* Admin Routes (Requires Admin Authentication) */}
      {/* <Route element={<AdminRoute />}>
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/categories" element={<Category />} />
        <Route path="/sub-categories" element={<SubCategories />} />
        <Route path="/admin/brands" element={<Brands />} />
        <Route path="/units" element={<Units />} />
        <Route path="/warehouses" element={<Warehouses />} />
        <Route path="/stores" element={<Stores />} />
        <Route path="/validations" element={<Validations />} />
        <Route path="/order-details" element={<OrderDetails />} />
        <Route path="/rapports" element={<Rapports />} />
        <Route path="/approvisionnement" element={<Supply />} />
        <Route path="/add-supply" element={<AddSupply />} />
        <Route path="/update-supply/:itemId" element={<UpdateSupply />} />
        <Route path="/commande/:reference" element={<UpdateInvoice />} />
      </Route> */}

      {/* 404 Page */}
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

export default App;
