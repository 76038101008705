import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'; // Importer SweetAlert2
import Loader from "../../components/loader"; // Assurez-vous que ce composant Loader existe

export default function OrdersList({ refreshOrder, setOrderDetails }) {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true); // État pour gérer le chargement

  useEffect(() => {
    // Simuler un délai de chargement ou effectuer une opération async pour récupérer les commandes
    const fetchOrders = () => {
      setLoading(true); // Démarrer le chargement
      const savedOrders = JSON.parse(localStorage.getItem('orders')) || [];
      setOrders(savedOrders);
      setLoading(false); // Fin du chargement
    };

    fetchOrders();
  }, [refreshOrder]);

  const loadOrder = (orderId) => {
    const savedOrders = JSON.parse(localStorage.getItem('orders')) || [];
    const selectedOrder = savedOrders.find(order => order.orderId === orderId);

    if (selectedOrder) {
      // Charger les données de la commande dans l'interface parent
      setOrderDetails(selectedOrder);  // Passer les détails de la commande au parent
      const closeModal = document.getElementById('closeOrdersListModal');
      closeModal.click();
      // Afficher une alerte SweetAlert pour indiquer que la commande a été chargée
      Swal.fire({
        title: 'Commande chargée!',
        text: `La commande avec l'ID ${selectedOrder.orderId} a été chargée avec succès.`,
        icon: 'success',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtrer les commandes en fonction de la recherche
  const filteredOrders = orders.filter(order =>
    order.orderId.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Si les données sont en cours de chargement, afficher le loader
  if (loading) {
    return <Loader />;
  }

  return (
    <div className="modal fade" id="ordersListModal" tabIndex={-1} style={{ display: "none", whiteSpace: 'normal' }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-lg font-bold text-gray-600">Commandes</h5>
          </div>
          <button
            type="button"
            id="closeOrdersListModal"
            data-bs-dismiss="modal"
            className="hidden items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
          >
            Fermer
          </button>
          <div className="modal-body">
            <input
              type="search"
              className="form-control my-4"
              placeholder="Rechercher"
              value={searchQuery}
              onChange={handleSearch}
            />
            <div className="max-h-60 overflow-y-scroll no-scrollbar">
              <div className="rounded-xl">
                {filteredOrders.length === 0 ? (
                  <p className="text-center text-gray-500">Aucune commande trouvée.</p>
                ) : (
                  filteredOrders.map((order, index) => (
                    <div key={'order-' + index} className="rounded-xl border-gray-300 border p-3 mb-2">
                      <span className="badge bg-success">ID: {order.orderId}</span>
                      <div className="flex flex-row justify-between items-center mt-2">
                        <div className="w-1/2 flex flex-row items-center gap-x-2">
                          <p>Client :</p>
                          <p>{order.selectedCustomer?.label}</p>
                        </div>
                        <div className="w-1/2 flex flex-row items-center gap-x-2">
                          <p>Total :</p>
                          <p>{order.total} FCFA</p>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between items-center mt-2">
                        <div className="w-1/2 flex flex-row items-center gap-x-2">
                          <p>Date :</p>
                          <p>{new Date(order.dateCreated).toLocaleDateString()}</p>
                        </div>
                      </div>
                      <button
                        onClick={() => loadOrder(order.orderId)} // Charger la commande sur clic
                        className="inline-flex mt-4 items-center rounded-md bg-green-600 px-2.5 py-1.5 w-full justify-center text-sm font-semibold text-white hover:bg-green-500 transition-all duration-300"
                      >
                        Ouvrir
                      </button>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
