import axios from 'axios';
import { REGISTER_SUCCESS, REGISTER_FAIL, LOGIN_SUCCESS, LOGOUT, SET_USER, LOGIN_FAIL } from './types';
import { APP_API_URL } from '../utils';

export const registerSuccess = (message) => ({
  type: REGISTER_SUCCESS,
  payload: message,
});

export const registerFail = (error) => ({
  type: REGISTER_FAIL,
  payload: error,
});

export const register = (userData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${APP_API_URL}/register`, userData);
      const { token, status, message } = response.data;

      if (status) {
        localStorage.setItem('token', token);
        dispatch(registerSuccess(message));
        dispatch(loginSuccess(token));
        dispatch(fetchUser(token));
      } else {
        throw new Error(message || 'Échec de l\'inscription');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Échec de l\'inscription';
      dispatch(registerFail(errorMessage));
    }
  };
};

export const loginSuccess = (token) => ({
  type: LOGIN_SUCCESS,
  payload: token,
});

export const loginFail = (error) => ({
  type: LOGIN_FAIL,
  payload: error,
});

export const login = (credentials) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${APP_API_URL}/auth-login`, credentials);
      const { token, status, message } = response.data;

      if (status) {
        localStorage.setItem('token', token);
        dispatch(loginSuccess(token));
        // dispatch(fetchUser(token));       
      } else {
        throw new Error(message || 'Échec de la connexion !');
      }
    } catch (error) {
      console.error('Error during login:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Échec de la connexion !';
      dispatch(loginFail(errorMessage));
    }
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  return {
    type: LOGOUT,
  };
};

export const setUser = (userData) => {
  return {
    type: SET_USER,
    payload: userData,
  };
};

export const fetchUser = (token) => {
  return async (dispatch) => {
    console.log('Fetching user with token:', token);
    try {
      const response = await axios.get(`${APP_API_URL}/user-info`, {
        headers: { Authorization: `Bearer ${token}`, Accept: `application/json` }
      });
      console.log('User fetched:', response.data.data);
      dispatch(setUser(response.data.data));
      localStorage.setItem('user', JSON.stringify(response.data.data));
    } catch (error) {
      dispatch(logout());
    }
  };
};
