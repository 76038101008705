import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'

import { useCart } from '../../context/CartContext'
import { useDataContext } from '../../context/DataContext'
import { APP_API_URL } from '../../utils'
import OrderLayout from '../../layout/order-layout'
import UIDropdown from '../../components/ui/ui-dropdown'
import Add from '../customers/add'
import OrdersList from './orders-list'
import Shipping from './shipping'
import Loader from '../../components/loader'
import formatNumber from '../../utils/functions'
import UICustomerDropdown from '../../components/ui/ui-customer-dropdown'
import { useSelector } from 'react-redux'

const Orders = () => {
  const navigate = useNavigate()
  const { categories } = useDataContext()
  const {
    cart,
    setCart,
    selectedCustomer,
    setSelectedCustomer,
    remise,
    setRemise,
    fraisLivraison,
    setTotal,
    total,
    subTotal,
    setSubTotal
  } = useCart() // Utiliser le contexte
  const [categoriesData, setCategoriesData] = useState([])
  const [productsData, setProductsData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedPaiement, setSelectedPaiement] = useState({ label: 'Espèces', value: 4 })
  const [remisePercentage, setRemisePercentage] = useState(0)
  const [remiseFixed, setRemiseFixed] = useState(0)
  const [loading, setLoading] = useState(false)
  const [productLoading, setProductLoading] = useState(false)
  const [refreshOrder, setRefreshOrder] = useState(false)
  const [customers, setCustomers] = useState([])
  const user = useSelector(state => state.auth.user)
  const [showReferencePiece, setShowReferencePiece] = useState(false)
  const [referencePiece, setReferencePiece] = useState('')

  useEffect(() => {
    if (categories && categories.length > 0) {
      const filteredCategories = categories.filter(
        category => category.type_categorie === null
      )
      setCategoriesData(filteredCategories)
    }
  }, [categories])

  const fetchProductsData = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${APP_API_URL}/variants`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setProductsData(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des variants :', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchFilteredVariants = async categoryId => {
    setProductLoading(true)

    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${APP_API_URL}/search/variants`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { categorie_id: categoryId }
      })
      setProductsData(response.data.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des variants :', error)
    } finally {
      setProductLoading(false)
    }
  }

  const fetchCustomers = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${APP_API_URL}/customers`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setCustomers(response.data.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des clients :', error)
    }
  }

  useEffect(() => {
    fetchProductsData()
    fetchCustomers()
  }, [])

  const handleCategoryClick = categoryId => {
    setSelectedCategory(categoryId)
    fetchFilteredVariants(categoryId) // Récupérer les variantes filtrées par catégorie
  }

  const handleSearch = event => {
    setSearchQuery(event.target.value)
  }

  const filteredItem = productsData.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.sku.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleCustomersSelect = option => {
    setSelectedCustomer(option)
  }

  const handleAddCustomer = newCustomer => {
    // Ajouter le client à la liste existante
    setCustomers(prevCustomers => [...prevCustomers, newCustomer])
    // Sélectionner automatiquement le nouveau client
    setSelectedCustomer({
      label: newCustomer.name,
      value: newCustomer.id,
      customer: newCustomer
    })
  }

  const handlePaiementSelect = option => {
    setSelectedPaiement(option)

    const methodsRequiringReference = [3, 7] // Virement (3) et Chèques (7)
    setShowReferencePiece(methodsRequiringReference.includes(option.value))
  }

  const saveCartToLocalStorage = (
    cart,
    selectedCustomer,
    selectedPaiement,
    remisePercentage,
    remiseFixed,
    remise,
    setCart,
    setSelectedCustomer,
    setSelectedPaiement,
    setRemisePercentage,
    setRemiseFixed,
    setRemise,
    setTotal,
    total,
    subTotal,
    setSubTotal
  ) => {
    // Vérifier si un client est sélectionné avant d'enregistrer
    if (!selectedCustomer || !selectedCustomer.value) {
      Swal.fire({
        title: 'Erreur!',
        text: 'Veuillez sélectionner un client avant de continuer.',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#006633'
      })
      return
    }

    if (cart.length === 0) {
      Swal.fire({
        title: 'Erreur!',
        text: 'Veuillez sélectionner au moins un produit avant de continuer.',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#006633'
      })
      return
    }

    // Créer un objet avec toutes les informations du panier et de la commande
    const cartState = {
      cart, // Contenu du panier
      selectedCustomer, // Client sélectionné
      selectedPaiement, // Paiement sélectionné
      remisePercentage, // Pourcentage de remise
      remiseFixed, // Remise fixe
      remise, // Montant total de la remise
      total, // Total de la commande
      subTotal // Sous-total avant frais de livraison
    }

    // Récupérer la liste des commandes déjà enregistrées dans le localStorage
    const savedOrders = JSON.parse(localStorage.getItem('orders')) || []

    // Générer un identifiant unique pour cette commande
    const orderId = `order_${Date.now()}_${Math.floor(Math.random() * 1000)}`
    setRefreshOrder(true)
    // Ajouter cette commande à la liste des commandes
    savedOrders.push({
      orderId,
      ...cartState,
      dateCreated: new Date().toISOString() // Date de création de la commande
    })

    // Sauvegarder la liste mise à jour des commandes dans localStorage
    localStorage.setItem('orders', JSON.stringify(savedOrders))
    // refreshOrder
    // Afficher une alerte de confirmation
    Swal.fire({
      title: 'Succès!',
      text: 'La commande a été enregistrée avec succès!',
      icon: 'success',
      confirmButtonText: 'OK',
      confirmButtonColor: '#006633'
    }).then(() => {
      // Réinitialiser les états après l'enregistrement
      setCart([])
      setSelectedCustomer({ label: 'Sélectionner un produit', value: '' })
      setSelectedPaiement({ label: 'Sélectionner un produit', value: '' })
      setRemisePercentage('')
      setRemiseFixed('')
      setRemise(0)
      setSubTotal(0) // Réinitialiser le sous-total
      setTotal(0) // Réinitialiser le total
    })
  }

  // Charger une commande et mettre à jour les données du panier
  const loadOrderDetails = order => {
    setSelectedCustomer(order.selectedCustomer)
    setSelectedPaiement(order.selectedPaiement)
    setRemisePercentage(order.remisePercentage)
    setRemiseFixed(order.remiseFixed)
    setRemise(order.remise)
    setCart(order.cart) // Charger le panier
    setSubTotal(order.subTotal)
    setTotal(order.total)
  }

  const addToCart = product => {
    setCart(prevCart => {
      const productInCart = prevCart.find(item => item.id === product.id)
      let updatedCart

      if (productInCart) {
        updatedCart = prevCart.map(item =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      } else {
        updatedCart = [
          ...prevCart,
          {
            ...product,
            quantity: 1, // Quantité initiale
            initialQuantity: product.quantity
          }
        ]
      }

      return updatedCart
    })
  }

  // Mettre à jour la quantité
  const updateQuantity = (productId, quantity) => {
    setCart(prevCart => {
      const updatedCart = prevCart.map(item =>
        item.id === productId
          ? { ...item, quantity: Math.max(0, quantity) }
          : item
      )
      // saveCartToLocalStorage(updatedCart); // Sauvegarder après mise à jour
      return updatedCart
    })
  }

  const updateProductPrice = (productId, newPrice) => {
    const updatedCart = cart.map(product => {
      if (product.id === productId) {
        return { ...product, customPrice: newPrice } // Mise à jour du prix personnalisé
      }
      return product
    })
    setCart(updatedCart) // Mise à jour du panier avec le nouveau prix
  }

  // Supprimer du panier
  const removeFromCart = productId => {
    setCart(prevCart => {
      const updatedCart = prevCart.filter(item => item.id !== productId)
      // saveCartToLocalStorage(updatedCart); // Sauvegarder après suppression
      return updatedCart
    })
  }

  const isProductInCart = product => {
    return cart.some(item => item.id === product.id)
  }

  const calculateSubtotal = () => {
    return cart.reduce(
      (subtotal, product) =>
        subtotal + (product.customPrice || product.price) * product.quantity,
      0
    )
  }

  const handleWheel = (e) => {
    // Empêche complètement le scroll
    e.target.blur();
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    // Bloque les flèches haut/bas
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal()
    const totalRemisePercentage =
      remisePercentage > 0 ? (remisePercentage / 100) * subtotal : 0
    const totalRemiseFixed = remiseFixed > 0 ? remiseFixed : 0

    // Calculer la remise totale (peut être uniquement un type de remise)
    const totalRemise = totalRemisePercentage + totalRemiseFixed

    // Mettre à jour la remise dans le contexte
    setRemise(totalRemise)

    return subtotal - totalRemise + fraisLivraison
  }

  useEffect(() => {
    setTotal(calculateTotal()) // Met à jour le total
  }, [cart, remisePercentage, remiseFixed, fraisLivraison])

  useEffect(() => {
    const newSubtotal = calculateSubtotal()
    setSubTotal(newSubtotal)
  }, [cart])

  const handleRefresh = async () => {
    await fetchCustomers()
  }

  const resetFilters = () => {
    setSearchQuery('') // Réinitialiser la recherche
    setSelectedCategory(null) // Réinitialiser la catégorie sélectionnée
    fetchProductsData() // Recharger tous les produits
    setCart([])
    localStorage.removeItem('cart')
    setSelectedCustomer({ label: 'Sélectionner un produit', value: '' })
    setSelectedPaiement({ label: 'Sélectionner un produit', value: '' })
    setRemisePercentage('')
    setRemiseFixed('')
    setRemise(0)
  }

  const handleSaveProFormat = () => {
    setLoading(true) // Afficher le loader
    const productCount = cart.length; // Utiliser le nombre de produits dans le panier

    const cartState = {
      cart,
      selectedCustomer,
      selectedPaiement,
      remisePercentage,
      remiseFixed,
      subTotal,
      total,
      remise,
      id: `ASB-${Date.now()}_${productCount}`, // Remplacer Math.floor par productCount
      isProFormat: false
    }

    const tvaPourcentage = parseFloat(cartState.tva || '18')
    const aibPourcentage = parseFloat(cartState.aib || '1')

    const montantTTC = cartState.cart.reduce((total, produit) => {
      return total + (produit.customPrice || produit.price) * produit.quantity
    }, 0)

    // Recalculer les taxes sur le montant HT après remise
    const montantTVA = montantTTC * (tvaPourcentage / 100)
    const montantAIB = montantTTC * (aibPourcentage / 100)
    // Appliquer la remise sur le montant HT
    const montantHT = montantTTC - montantTVA
    const montantHTAvecRemise = montantHT - (cartState.remise || 0)

    // Le montant net est calculé sur la base du montant HT avec les taxes ajoutées
    const montantNet = montantHTAvecRemise + montantTVA

    const nouveauxProduits = cartState.cart.map(produit => ({
      variant_id: produit.id,
      quantite: produit.quantity,
      prix_unitaire_achat: produit.price,
      unite: produit.unit,
      produit_id: produit.product.id,
      name: produit.sku,
      p2: produit.customPrice || produit.price * (1 - tvaPourcentage / 100)
    }))

    const formData = {
      designation: cartState.id,
      telephone: cartState.selectedCustomer.customer.numero,
      remise: cartState.remise,
      prix_unitaire: 0,
      type_commande: 'customer',
      statut_commande: 'en cours',
      remarques: '',
      date_livraison: '',
      adresse_livraison: '',
      customer_id: cartState.selectedCustomer.value,
      mode_reglement_id: cartState?.selectedPaiement?.value,
      user_id: user?.id,
      store_id: user?.stores[0]?.id || user?.personnels[0]?.store_id,
      produits: nouveauxProduits,
      date_livraison_prevue: '',
      reference_piece: referencePiece,
      tva: `${montantTVA}`,
      aib: `${montantAIB}`,
      montant_ht: montantHT,
      montant_net: montantNet
    }

    // Simuler un délai de chargement avant la redirection
    setTimeout(() => {
      setLoading(false)

      // Afficher une alerte de confirmation avec SweetAlert
      Swal.fire({
        title: 'Commande Pro Format enregistrée !',
        text: 'Votre commande Pro Format a été enregistrée avec succès. Veuillez consulter votre commande pour confirmer votre choix de paiement.',
        icon: 'success',
        confirmButtonText: 'Voir la facture',
        confirmButtonColor: '#006633'
      }).then(result => {
        if (result.isConfirmed) {
          navigate('/commandes/pro-format', { state: formData })
        }
      })
    }, 2000) // 2 secondes de délai
  }

  const handleSaveStandardFormat = async () => {
    setLoading(true)
    const productCount = cart.length; // Utiliser le nombre de produits dans le panier

    const cartState = {
      cart,
      selectedCustomer,
      selectedPaiement,
      remisePercentage,
      remiseFixed,
      subTotal,
      total,
      remise,
      id: `ASB-${Date.now()}_${productCount}`, // Remplacer Math.floor par productCount
      isProFormat: false
    }

    localStorage.setItem('cartState', [])

    const tvaPourcentage = parseFloat(cartState.tva || '18')
    const aibPourcentage = parseFloat(cartState.aib || '1')

    const montantTTC = cartState.cart.reduce((total, produit) => {
      return total + (produit.customPrice || produit.price) * produit.quantity
    }, 0)

    // Recalculer les taxes sur le montant HT après remise
    const montantTVA = montantTTC * (tvaPourcentage / 100)
    const montantAIB = montantTTC * (aibPourcentage / 100)
    // Appliquer la remise sur le montant HT
    const montantHT = montantTTC - montantTVA
    const montantHTAvecRemise = montantHT - (cartState.remise || 0)

    // Le montant net est calculé sur la base du montant HT avec les taxes ajoutées
    const montantNet = montantHTAvecRemise + montantTVA

    const nouveauxProduits = cartState.cart.map(produit => ({
      variant_id: produit.id,
      quantite: produit.quantity,
      prix_unitaire_achat: produit.price,
      unite: produit.unit,
      produit_id: produit.product.id,
      name: produit.sku,
      p2: (produit.customPrice || produit.price) * (1 - tvaPourcentage / 100)
    }))

    const formData = {
      designation: cartState.id,
      telephone: cartState.selectedCustomer.customer.numero,
      remise: cartState.remise,
      prix_unitaire: 0,
      type_commande: 'customer',
      statut_commande: 'en cours',
      remarques: '',
      date_livraison: '',
      adresse_livraison: '',
      customer_id: cartState.selectedCustomer.value,
      mode_reglement_id: cartState?.selectedPaiement?.value,
      user_id: user?.id,
      store_id: user?.stores[0]?.id || user?.personnels[0]?.store_id,
      produits: nouveauxProduits,
      date_livraison_prevue: '',
      reference_piece: referencePiece,
      tva: `${montantTVA}`,
      aib: `${montantAIB}`,
      montant_ht: montantHT,
      montant_net: montantNet
    }

    const invalidProduct = cartState.cart.some(produit => produit.quantity <= 0)

    if (invalidProduct) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Un ou plusieurs produits ont une quantité inférieure ou égale à zéro.'
      })
      setLoading(false)
      return
    }

    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${APP_API_URL}/commandes`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Succès!',
          text: 'La commande a été enregistrée avec succès.',
          timer: 2000,
          showConfirmButton: false
        })
        navigate('/commandes/validation')
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur de validation',
          text: 'Certains champs sont invalides. Veuillez vérifier et réessayer.'
        })
      } else {
        console.error("Erreur lors de l'envoi du formulaire", error)
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: "Une erreur est survenue lors de l'envoi du formulaire. Veuillez réessayer plus tard."
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSaveWithPartialPayment = async () => {
    setLoading(true)
    const productCount = cart.length; // Utiliser le nombre de produits dans le panier

    const cartState = {
      cart,
      selectedCustomer,
      selectedPaiement,
      remisePercentage,
      remiseFixed,
      subTotal,
      total,
      remise,
      id: `ASB-${Date.now()}_${productCount}`, // Remplacer Math.floor par productCount
      isProFormat: false
    }

    localStorage.setItem('cartState', JSON.stringify(cartState))

    const tvaPourcentage = parseFloat(cartState.tva || '18')
    const aibPourcentage = parseFloat(cartState.aib || '1')

    const montantTTC = cartState.cart.reduce((total, produit) => {
      return total + (produit.customPrice || produit.price) * produit.quantity
    }, 0)

    // Recalculer le montant HT depuis le montant TTC
    const totalTaxPercentage = tvaPourcentage + aibPourcentage
    const montantHT = montantTTC / (1 + totalTaxPercentage / 100)

    // Appliquer la remise sur le montant HT
    const montantHTAvecRemise = montantHT - (cartState.remise || 0)

    // Recalculer les taxes sur le montant HT après remise
    const montantTVA = montantHTAvecRemise * (tvaPourcentage / 100)
    const montantAIB = montantHTAvecRemise * (aibPourcentage / 100)

    // Le montant net est calculé sur la base du montant HT avec les taxes ajoutées
    const montantNet = montantHTAvecRemise + montantTVA + montantAIB

    const nouveauxProduits = cartState.cart.map(produit => ({
      variant_id: produit.id,
      quantite: produit.quantity,
      prix_unitaire_achat: produit.price,
      unite: produit.unit,
      produit_id: produit.product.id,
      name: produit.name,
      p2: produit.customPrice || produit.price * (1 - tvaPourcentage / 100)
    }))

    const invalidProduct = cartState.cart.some(produit => produit.quantity <= 0)

    if (invalidProduct) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Un ou plusieurs produits ont une quantité inférieure ou égale à zéro.'
      })
      setLoading(false)
      return
    }

    // Demander l'avance payée à l'utilisateur
    Swal.fire({
      title: 'Paiement partiel',
      input: 'number',
      inputLabel:
        "Montant de l'avance " + formatNumber(montantHTAvecRemise) + ' FCFA',
      inputPlaceholder: 'Entrez le montant payé',
      inputAttributes: {
        min: 0,
        step: 0.01
      },
      showCancelButton: true,
      confirmButtonText: 'Enregistrer',
      cancelButtonText: 'Annuler',
      preConfirm: avancePayee => {
        const avance = parseFloat(avancePayee)
        if (isNaN(avance) || avance < 0 || avance > montantNet) {
          Swal.showValidationMessage(
            'Montant invalide ou supérieur au montant total.'
          )
        }
        return avance
      }
    }).then(async result => {
      if (result.isConfirmed) {
        const avance = result.value || 0
        const montantRestant = montantNet - avance

        const formData = {
          designation: cartState.id,
          telephone: cartState.selectedCustomer.customer.numero,
          remise: cartState.remise,
          prix_unitaire: 0,
          type_commande: 'customer',
          statut_commande: montantRestant > 0 ? 'en cours' : 'payée',
          remarques: '',
          date_livraison: '',
          adresse_livraison: '',
          customer_id: cartState.selectedCustomer.value,
          mode_reglement_id: cartState?.selectedPaiement?.value,
          user_id: user?.id,
          store_id: user?.stores[0]?.id || user?.personnels[0]?.store_id,
          produits: nouveauxProduits,
          date_livraison_prevue: '',
          reference_piece: referencePiece,
          tva: `${montantTVA}`,
          aib: `${montantAIB}`,
          montant_ht: montantHTAvecRemise,
          montant_net: montantNet,
          total_du: montantRestant,
          avance_payee: avance,
          paye_complet: false
        }

        try {
          const token = localStorage.getItem('token')
          const response = await axios.post(
            `${APP_API_URL}/commandes`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )

          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Succès!',
              text: 'La commande a été enregistrée avec succès.',
              timer: 2000,
              showConfirmButton: false
            })
            navigate('/commandes/validation')
          }
        } catch (error) {
          if (error.response && error.response.status === 422) {
            Swal.fire({
              icon: 'error',
              title: 'Erreur de validation',
              text: 'Certains champs sont invalides. Veuillez vérifier et réessayer.'
            })
          } else {
            console.error("Erreur lors de l'envoi du formulaire", error)
            Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: "Une erreur est survenue lors de l'envoi du formulaire. Veuillez réessayer plus tard."
            })
          }
        } finally {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    })
  }

  if (loading) {
    return <Loader />
  }

  return (
    <OrderLayout>
      <div className='row mb-8'>
        <div className='col-xl-8 contact'>
          <div className='flex flex-col lg:flex-row lg:items-center mb-6'>
            <div className='pagetitle gap-x-2 flex md:flex-row flex-col gap-y-2'>
              <button
                type='button'
                data-bs-toggle='modal'
                data-bs-target='#ordersListModal'
                className='inline-flex items-center rounded-md bg-gray-600 px-2.5 py-2 text-sm font-semibold text-white hover:bg-gray-500 transition-all duration-300'
              >
                <i className='bi bi-cart me-1' /> Voir les commandes
              </button>
              <OrdersList
                setOrderDetails={loadOrderDetails}
                refreshOrder={refreshOrder}
              />
              <input
                type='search'
                className='form-control form-control-sm w-full sm:w-auto'
                value={searchQuery}
                onChange={handleSearch}
                placeholder='Rechercher un produit'
              />
              <button
                title='Rafraichir'
                onClick={resetFilters}
                type='button'
                className='inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300'
              >
                <i className='bi bi-arrow-clockwise'></i>
              </button>
            </div>
          </div>

          <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center'>
            <div className='pagetitle'>
              <h1>Categories</h1>
              <nav>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item active'>
                    Sélectionnez parmi les catégories ci-dessous
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className='row row-cols-2 row-cols-md-2 row-cols-md-4 g-3'>
            {categoriesData.map((item, index) => (
              <div
                key={index}
                className={`cursor-pointer`}
                onClick={() => handleCategoryClick(item.id)}
              >
                <div
                  className={`info-box card text-center mr-4 ${selectedCategory === item.id &&
                    'bg-lightgreen/75 text-white'
                    }`}
                >
                  <h3>{item.nom}</h3>
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center'>
            <div className='pagetitle'>
              <h1>Produits</h1>
            </div>
          </div>

          {productLoading ? ( // Condition pour afficher le loader
            <div className='row row-cols-1 row-cols-md-2 row-cols-md-4 g-3'>
              {/* Générer plusieurs skeleton cards */}
              {[...Array(8)].map((_, index) => (
                <div className='col' key={`skeleton-${index}`}>
                  <div className='animate-pulse bg-white rounded-md shadow-sm border-gray-300 border-[1px] p-3'>
                    {/* Skeleton du titre */}
                    <div className='h-5 bg-gray-200 rounded w-2/3 mb-3'></div>

                    {/* Skeleton des informations */}
                    <div className='flex flex-row justify-between items-center'>
                      <div className='h-4 bg-gray-200 rounded w-1/4'></div>
                      <div className='h-4 bg-gray-200 rounded w-1/3'></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : filteredItem.length === 0 ? (
            // Afficher ce message si la liste de produits est vide
            <div className='h-96 flex flex-row justify-center items-center text-center'>
              <img
                src='/assets/img/no-product.png'
                width={300}
                height={300}
                alt='no-products'
              />
            </div>
          ) : (
            // Afficher les produits si la liste n'est pas vide
            <div className='row row-cols-1 row-cols-md-2 row-cols-md-4 g-3'>
              {filteredItem.map(product => (
                <div
                  className='col'
                  onClick={() => addToCart(product)}
                  key={'product-' + product.id}
                >
                  <div
                    className={`group relative bg-white rounded-md cursor-pointer shadow-sm ${isProductInCart(product)
                      ? 'border-lightgreen'
                      : 'border-gray-400'
                      } border-[1px] p-3 hover:border-lightgreen`}
                  >
                    <span
                      className={`bg-lightgreen group-hover:block absolute rounded-full right-3 top-3 text-white p-0.5 ${isProductInCart(product) ? 'block' : 'hidden'
                        }`}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={16}
                        height={16}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='feather feather-check feather-16'
                      >
                        <polyline points='20 6 9 17 4 12' />
                      </svg>
                    </span>

                    <div className='mb-3'>
                      <h1 className='font-semibold text-base text-darkgreen'>
                        {product.name}
                      </h1>
                      <h1 className='font-semibold text-xs text-darkgreen'>
                        {product.sku}
                      </h1>
                    </div>

                    <div className='flex flex-row justify-between items-center'>
                      <h1 className='text-blue-700 text-base'>
                        {product.quantity} {product.product?.unit?.symbol}
                      </h1>
                      <h1 className='text-lightgreen text-base'>
                        {formatNumber(product.price)} fcfa
                      </h1>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className='col-xl-4 bg-white p-3'>
          <div className='position-sticky top-8'>
            <div className='flex flex-col sm:flex-row sm:justify-between sm:items-start'>
              <div className='pagetitle'>
                <h1>Commande</h1>
              </div>
            </div>

            <div className='col-12 mb-3'>
              <div className='flex flex-row justify-between items-center mb-2'>
                <label htmlFor='variant' className='form-label mb-0'>
                  Informations sur les clients
                </label>
                <button
                  className='text-xs'
                  title='Ajouter un client'
                  data-bs-toggle='modal'
                  data-bs-target='#addCustomerModal'
                >
                  <i className={`bi bi-plus`}></i> Ajouter un client
                </button>
              </div>
              <UICustomerDropdown
                options={customers.map(item => ({
                  label: item.name,
                  value: item.id,
                  phone: item.numero,
                  client_code: item.client_code,
                  email: item.email,
                  customer: item
                }))}
                selectedOption={selectedCustomer}
                onSelect={handleCustomersSelect}
                enableSearch={true}
              />
            </div>

            <div className='pt-4 my-4 border-t border-gray-200'>
              <div className='flex flex-row justify-between items-center'>
                <h1 className='font-bold flex items-center'>
                  Produit ajouté{' '}
                  <span className='w-[15px] h-[15px] flex items-center justify-center bg-lightgreen rounded-full text-white text-[10px] font-semibold ml-2'>
                    {cart.length}
                  </span>
                </h1>

                <button
                  className='text-sm text-red-500'
                  onClick={() => {
                    setCart([])
                    localStorage.removeItem('cart')
                    setSelectedCustomer({
                      label: 'Sélectionner un produit',
                      value: ''
                    }) // Supprime le panier du localStorage
                  }}
                >
                  <i className={`bi bi-x`}></i> Nettoyer
                </button>
              </div>
            </div>

            <div className='max-h-60 overflow-y-scroll no-scrollbar'>
              {cart.length === 0 ? (
                <div className='h-full flex flex-row justify-center items-center text-center'>
                  <img
                    src='/assets/img/no-product.png'
                    width={200}
                    height={200}
                    alt='no-products'
                  />
                </div>
              ) : (
                <div className='table-responsive table-card'>
                  {cart.map(product => (
                    <div
                      className='mb-3 border rounded-xl p-3'
                      key={product.id}
                    >
                      <div className='flex flex-row items-start space-x-4 g-0'>
                        <div className='flex-1'>
                          <div className='flex justify-between items-start'>
                            <span className='badge bg-success'>
                              {product.name}
                            </span>
                            <div>
                              <button
                                title='Supprimer'
                                onClick={() => removeFromCart(product.id)}
                                type='button'
                              >
                                <i className='bi bi-trash text-red-600'></i>
                              </button>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6'>
                              <label
                                htmlFor='quantity'
                                className='form-label text-gray-950'
                              >
                                Quantité
                              </label>
                              <input
                                type='number'
                                className='form-control'
                                id='quantity'
                                name='quantity'
                                min={0}
                                value={product.quantity}
                                max={product.initialQuantity}
                                onWheel={handleWheel} // Désactive la molette
                                onKeyDown={handleKeyDown} // Désactive les flèches
                                onChange={e => {
                                  let enteredQuantity = e.target.value;
                                  const isIntegerOnly = product.product.unit_id === 5;

                                  // Validation : autoriser ou interdire les nombres à virgule
                                  if (isIntegerOnly) {
                                    if (!/^\d*$/.test(enteredQuantity)) {
                                      return; // Bloque l'entrée si ce n'est pas un entier
                                    }
                                    enteredQuantity = parseInt(enteredQuantity, 10);
                                  } else {
                                    if (!/^\d*\.?\d*$/.test(enteredQuantity)) {
                                      return; // Bloque l'entrée si ce n'est pas un nombre valide (float)
                                    }
                                    enteredQuantity = parseFloat(enteredQuantity);
                                  }

                                  let message = '';
                                  let error = '';

                                  // Vérification des limites
                                  if (enteredQuantity > product.initialQuantity || enteredQuantity < 0 || isNaN(enteredQuantity)) {
                                    if (enteredQuantity > product.initialQuantity) {
                                      error = `Quantité demandée (${enteredQuantity}) dépasse la quantité disponible (${product.initialQuantity}).`;
                                    }
                                    message = '';
                                  } else {
                                    // Logique de calcul de cartons/pièces en fonction des m²
                                    const m2PerCarton = product.nb_m2_par_carton || 0;
                                    const m2PerPiece = product.nb_m2_par_piece || 0;

                                    if (product.product.category_id === 17) {
                                      if (m2PerCarton > 0 && m2PerPiece > 0) {
                                        const totalCartons = Math.floor(enteredQuantity / m2PerCarton);
                                        const remainingM2 = enteredQuantity % m2PerCarton;
                                        const totalPieces = Math.floor(remainingM2 / m2PerPiece);

                                        message = `${totalCartons} carton(s)` + (remainingM2 > 0 && totalPieces > 0 ? ` et ${totalPieces} pièce(s)` : '');
                                      } else {
                                        message = `${enteredQuantity} pièce(s)`;
                                      }
                                    } else {
                                      message = `${enteredQuantity} pièce(s)`;
                                    }
                                    error = '';
                                  }

                                  // Mise à jour de la quantité si aucune erreur
                                  if (!error) {
                                    updateQuantity(product.id, enteredQuantity);
                                  }

                                  // Mise à jour des messages d'erreur et d'information
                                  const parent = e.target.parentElement;
                                  const errorEl = parent.querySelector('.error-message');
                                  const messageEl = parent.querySelector('.info-message');

                                  if (errorEl) errorEl.textContent = error;
                                  if (messageEl) messageEl.textContent = message;
                                }}
                              />
                              <p className='text-xs text-red-600 mt-1 error-message'></p>
                              <p className='text-xs text-gray-600 mt-1 info-message'></p>
                            </div>
                            <div className='col-md-6'>
                              <label
                                htmlFor='remiseFixed'
                                className='form-label text-gray-950'
                              >
                                Prix de vente
                              </label>
                              <input
                                type='number'
                                className='form-control'
                                min={0}
                                id='remiseFixed'
                                name='remiseFixed'
                                value={product.customPrice}
                                placeholder={product.price}
                                onWheel={handleWheel} // Désactive la molette
                                onKeyDown={handleKeyDown} // Désactive les flèches
                                onChange={e => {
                                  const newPrice = e.target.value
                                  if (!isNaN(newPrice) && newPrice >= 0) {
                                    // Mise à jour du prix personnalisé pour le produit
                                    updateProductPrice(product.id, newPrice)
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <p className='text-sm font-bold mt-2'>
                            {formatNumber(
                              (product.customPrice || product.price) *
                              (product.quantity || 0)
                            )}{' '}
                            FCFA
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className='mt-4 border-t pt-4'>
              <div className='row'>
                <div className='col-md-6'>
                  <label
                    htmlFor='remisePercentage'
                    className='form-label text-gray-950'
                  >
                    Remise (%)
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    min={0}
                    max={100}
                    id='remisePercentage'
                    name='remisePercentage'
                    value={remisePercentage}
                    onChange={e =>
                      setRemisePercentage(parseFloat(e.target.value))
                    }
                  />
                </div>
                <div className='col-md-6'>
                  <label
                    htmlFor='remiseFixed'
                    className='form-label text-gray-950'
                  >
                    Remise (montant fixe)
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    min={0}
                    id='remiseFixed'
                    name='remiseFixed'
                    value={remiseFixed}
                    onChange={e => setRemiseFixed(parseFloat(e.target.value))}
                  />
                </div>
              </div>
            </div>

            <div className='py-3 rounded-lg'>
              <div className='order-total table-responsive'>
                <table className='table table-responsive table-borderless text-sm'>
                  <tbody className=''>
                    <tr>
                      <td className='font-bold'>Sous-total</td>
                      <td className='text-end'>
                        {formatNumber(calculateSubtotal()) || 0.0} FCFA
                      </td>
                    </tr>
                    {remisePercentage > 0 && (
                      <tr>
                        <td className='danger'>Remise ({remisePercentage}%)</td>
                        <td className='danger text-end'>
                          {formatNumber(
                            (remisePercentage / 100) * calculateSubtotal()
                          )}{' '}
                          FCFA
                        </td>
                      </tr>
                    )}
                    {remiseFixed > 0 && (
                      <tr>
                        <td className='danger'>Remise (montant fixe)</td>
                        <td className='danger text-end'>
                          {formatNumber(remiseFixed)} FCFA
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className='danger'>Remise totale</td>
                      <td className='danger text-end'>
                        {formatNumber(remise)} FCFA
                      </td>
                    </tr>
                    <tr>
                      <td className='font-bold text-lg'>Total</td>
                      <td className='text-end text-lg'>
                        {formatNumber(calculateTotal()) || 0.0} FCFA
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className='border-t pt-4'>
              <div className='row'>
                <div className='col-12'>
                  <label htmlFor='name' className='form-label text-gray-950'>
                    Méthode de paiement
                  </label>
                  <UIDropdown
                    options={[
                      {
                        label: 'Mobile Money',
                        value: 1 // Remarquez la correspondance avec "MOBILE MONEY" dans le case PHP
                      },
                      {
                        label: 'Carte bancaire',
                        value: 2 // Correspond à "1" dans le case PHP pour 'CARTEBANCAIRE'
                      },
                      {
                        label: 'Virement',
                        value: 3 // Correspond à "VIREMENT" dans le case PHP pour 'CHEQUES'
                      },
                      {
                        label: 'Espèces',
                        value: 4 // Correspond à "4" dans le case PHP pour 'ESPECES'
                      },
                      {
                        label: 'Crédit',
                        value: 5 // Correspond à "CREDIT" dans le case PHP pour 'CREDIT'
                      },
                      {
                        label: 'Chèques',
                        value: 7 // Correspond à 'CHEQUES' dans le case PHP pour 'CHEQUES'
                      },
                      {
                        label: 'Autre',
                        value: 6 // Correspond à 'AUTRE' dans le case PHP pour 'AUTRE'
                      }
                    ]}
                    selectedOption={selectedPaiement}
                    onSelect={handlePaiementSelect}
                    enableSearch={true}
                  />
                </div>

                {showReferencePiece && (
                  <div className='col-12 mt-3'>
                    <label
                      htmlFor='referencePiece'
                      className='form-label text-gray-950'
                    >
                      Référence pièce
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='referencePiece'
                      name='reference_piece'
                      value={referencePiece}
                      onChange={e => setReferencePiece(e.target.value)}
                      placeholder='Saisissez la référence de la pièce'
                    />
                  </div>
                )}
              </div>
            </div>

            <div className='flex flex-row justify-between mt-4 gap-x-4'>
              <button
                onClick={() =>
                  saveCartToLocalStorage(
                    cart,
                    selectedCustomer,
                    selectedPaiement,
                    remisePercentage,
                    remiseFixed,
                    remise,
                    setCart,
                    setSelectedCustomer,
                    setSelectedPaiement,
                    setRemisePercentage,
                    setRemiseFixed,
                    setRemise,
                    setTotal,
                    total,
                    subTotal,
                    setSubTotal
                  )
                }
                className='inline-flex items-center justify-center w-1/2 rounded-md bg-orange-500 px-2.5 py-2 text-sm font-semibold text-white hover:bg-orange-400 transition-all duration-300'
              >
                Enregistrer pour plus tard
              </button>

              <button
                onClick={handleSaveProFormat}
                type='button'
                className={`inline-flex items-center w-full justify-center rounded-md px-3 py-2.5 text-base font-semibold text-white transition-all duration-300 
                ${calculateTotal() > 0
                    ? 'bg-green-600 hover:bg-green-500'
                    : 'bg-gray-400 cursor-not-allowed'
                  }`}
                disabled={calculateTotal() === 0 || loading}
              >
                Pro Format
              </button>

              <button
                onClick={() => {
                  // Afficher la boîte de dialogue de confirmation
                  Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: 'Cela effacera toutes les informations du panier et du client.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Oui, nettoyer!',
                    cancelButtonText: 'Annuler',
                    confirmButtonColor: '#006633',
                    cancelButtonColor: '#d33',
                    reverseButtons: true
                  }).then(result => {
                    if (result.isConfirmed) {
                      // Si l'utilisateur confirme, effectuer l'action de nettoyage
                      setCart([])
                      localStorage.removeItem('cart')
                      setSelectedCustomer({
                        label: 'Sélectionner un produit',
                        value: ''
                      })
                      setSelectedPaiement({
                        label: 'Sélectionner un produit',
                        value: ''
                      })
                      setRemisePercentage('')
                      setRemiseFixed('')
                      setRemise(0)

                      // Afficher une notification de succès
                      Swal.fire(
                        'Nettoyé!',
                        'Le panier et les informations ont été réinitialisés.',
                        'success'
                      )
                    }
                  })
                }}
                type='button'
                className='inline-flex items-center justify-center w-1/2 rounded-md bg-gray-500 px-2.5 py-2 text-sm font-semibold text-white hover:bg-gray-800 transition-all duration-300'
              >
                Nettoyer
              </button>
            </div>

            <div className='my-4 border-t pt-4'>
              <button
                type='button'
                className={`inline-flex items-center w-full justify-center rounded-md px-3 py-2.5 text-base font-semibold text-white transition-all duration-300 
                ${selectedPaiement.value &&
                    calculateTotal() > 0 &&
                    selectedCustomer?.value &&
                    (showReferencePiece ? referencePiece : true)
                    ? 'bg-green-600 hover:bg-green-500'
                    : 'bg-gray-400 cursor-not-allowed'
                  }`}
                onClick={handleSaveStandardFormat}
                disabled={
                  !selectedPaiement.value ||
                  calculateTotal() === '0' ||
                  !selectedCustomer?.value ||
                  (showReferencePiece && !referencePiece) ||
                  loading
                }
              >
                Valider la commande : {formatNumber(calculateTotal())} FCFA
              </button>
            </div>

            <div className='my-4 border-t pt-4'>
              <button
                type='button'
                className={`inline-flex items-center w-full justify-center rounded-md px-3 py-2.5 text-base font-semibold text-white transition-all duration-300 
                ${selectedPaiement.value &&
                    calculateTotal() > 0 &&
                    selectedCustomer?.value &&
                    (showReferencePiece ? referencePiece : true)
                    ? 'bg-indigo-600 hover:bg-indigo-500'
                    : 'bg-gray-400 cursor-not-allowed'
                  }`}
                onClick={handleSaveWithPartialPayment}
                disabled={
                  !selectedPaiement.value ||
                  calculateTotal() === '0' ||
                  !selectedCustomer?.value ||
                  (showReferencePiece && !referencePiece) ||
                  loading
                }
              >
                Payer une avance
              </button>
            </div>
          </div>
        </div>
      </div>
      <Shipping
        selectedPaiement={selectedPaiement}
        customer_id={selectedCustomer.value}
      />
      <Add refreshData={handleRefresh} onAddCustomer={handleAddCustomer} />
    </OrderLayout>
  )
}

export default Orders
