import React, { useState } from "react";
import axios from 'axios';
import { APP_API_URL } from "../../../utils";
import Swal from "sweetalert2";

export default function Delete({ brand, onElementAdded }) {
  const [isLoading, setLoading] = useState(false);

  const handleDelete = async () => {
    // Demander une confirmation avant de supprimer
    const result = await Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: "Cette action est irréversible.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Non, annuler',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    });

    // Si l'utilisateur confirme la suppression
    if (result.isConfirmed) {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.delete(`${APP_API_URL}/marques/${brand.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          console.log(response.data);

          // Afficher un message de succès
          Swal.fire({
            title: 'Supprimé!',
            text: 'La marque a été supprimée avec succès.',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          onElementAdded();
          // Fermer le modal si nécessaire
          const closeModalButton = document.getElementById(`closeDeleteBrandModal${brand.id}`);
          if (closeModalButton) {
            closeModalButton.click();
          }
        }
      } catch (error) {
        console.error("Erreur lors de la suppression de la catégorie", error);

        // Afficher un message d'erreur
        Swal.fire({
          title: 'Erreur!',
          text: 'Il y a eu un problème lors de la suppression. Veuillez réessayer.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div
      className="modal fade"
      id={`deleteBrandModal${brand.id}`}
      tabIndex={-1}
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-lg font-bold text-gray-600">Supprimer une marque</h5>
          </div>
          <div className="modal-body whitespace-normal">
            <div className="flex flex-col justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600" width="8em" height="8em" viewBox="0 0 24 24">
                <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                  <path d="M12 17v-5h-.5m0 5h1M12 9.5V9" />
                  <path d="M5.98 10.762C8.608 5.587 9.92 3 12 3c2.08 0 3.393 2.587 6.02 7.762l.327.644c2.182 4.3 3.274 6.45 2.287 8.022C19.648 21 17.208 21 12.327 21h-.654c-4.88 0-7.321 0-8.307-1.572c-.987-1.572.105-3.722 2.287-8.022z" />
                </g>
              </svg>
              <p>
                Voulez-vous vraiment supprimer la marque <span className="font-bold">{brand.nom}</span> ?
              </p>
            </div>
          </div>
          <div className="modal-footer">
            {isLoading ? (
              <button
                type="button"
                disabled
                className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-2 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Suppression...
              </button>
            ) : (
              <button
                onClick={handleDelete}
                className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                Supprimer
              </button>
            )}
            <button
              type="button"
              data-bs-dismiss="modal"
              id={`closeDeleteBrandModal${brand.id}`}
              className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};