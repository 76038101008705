import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../actions/auth-actions';
import { APP_API_URL, APP_NAME } from '../utils';
import axios from 'axios';
import Swal from 'sweetalert2'; // Importation de SweetAlert2

const MagasinierHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [data, setData] = useState([]);

  const handleLogout = () => {
    // Utilisation de SweetAlert pour la confirmation
    Swal.fire({
      title: 'Êtes-vous sûr de vouloir vous déconnecter ?',
      text: "Cette action vous déconnectera de votre compte.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, déconnectez-moi',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        // Si l'utilisateur confirme, déconnectez-le
        dispatch(logout());
        navigate('/auth/login');
        Swal.fire(
          'Déconnecté !',
          'Vous avez été déconnecté avec succès.',
          'success'
        );
      }
    });
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/completed/commandes`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setData(response.data.commandes);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes :', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <Link to={"/"} className="logo d-flex align-items-center">
          <img src="/assets/img/logo.png" alt={APP_NAME} />
        </Link>
      </div>
      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item dropdown d-none d-md-block">
            <Link className="nav-link nav-icon" to="/">
              <i className="bi bi-box-seam-fill" />
              <span className="badge bg-lightgreen badge-number">
                {data.length > 9 ? '9+' : data.length}
              </span>
            </Link>
          </li>

          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <span className="dropdown-toggle ps-2">
                {user?.name}
              </span>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>{user?.name}</h6>
                <span>{user?.email}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to="/edit-profile"
                >
                  <i className="bi bi-person" />
                  <span>Mon compte</span>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="/commandes/history"
                >
                  <i className="bi bi-clock-history" />
                  <span>Historique</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to="/summary"
                >
                  <i className="bi bi-question-circle" />
                  <span>Résumé des produits</span>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center cursor-pointer"
                  onClick={handleLogout}
                >
                  <i className="bi bi-box-arrow-right" />
                  <span>Déconnexion</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default MagasinierHeader;
