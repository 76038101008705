import React, { useEffect, useState } from 'react';
import MainLayout from '../../layout/main-layout';
import { useSelector } from 'react-redux';
import { APP_API_URL } from '../../utils';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function UpdateSupply() {
  const user = useSelector((state) => state.auth.user);
  const { itemId } = useParams();
  const [item, setItem] = useState(null); // Utilisation de null au lieu d'un tableau vide
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);

  const fetchItem = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/approvisionnements/${itemId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setItem(response.data); // Définir l'élément récupéré
    } catch (error) {
      console.error('Erreur lors de la récupération des approvisionnements :', error);
    }
  };

  useEffect(() => {
    fetchItem();
  }, []);

  useEffect(() => {
    if (item) {
      setFormData({
        date_reception: item.date_reception || '',
        commentaire_reception: item.commentaire_reception || '',
        prix_achat: item.prix_achat || '',
        prix_vente: item.prix_vente || '',
        nb_m2_par_carton: item.nb_m2_par_carton || '',
        nb_cartons_total: item.nb_cartons_total || '',
        nb_pieces_par_carton: item.nb_pieces_par_carton || '',
        produit_id: item.produit_id || '', // ID du produit sélectionné
        variant_id: item.variant_id || '', // ID de la variante sélectionnée
        warehouse_id: item.warehouse_id || null, // ID de l'entrepôt si sélectionné
        quantity: item.quantity || '', // Quantité commandée initiale
        apro_m2_total: parseFloat(item.apro_m2_total) || null, // Surface totale en m²
        prix_achat_unitaire: item.prix_achat_unitaire || 0.0, // Prix d'achat unitaire
        total_prix_achat: item.total_prix_achat || null, // Prix d'achat total
        total_prix_vente: item.total_prix_vente || null, // Prix de vente total
        fournisseur_id: item.fournisseur_id || null, // ID du fournisseur si sélectionné
        date_approvisionnement: item.date_approvisionnement || new Date().toISOString().split('T')[0], // Date d'approvisionnement
        status: 'terminé', // Statut de l'approvisionnement
        note: item.note || '', // Note ou commentaire
        code_barre: item.code_barre || '', // Code-barres
        lieu_stockage: item.lieu_stockage || '', // Lieu de stockage
        conditions_stockage: item.conditions_stockage || '', // Conditions de stockage
        numero_de_facture: item.numero_de_facture || '', // Numéro de facture
        user_id: user?.id || null, // ID de l'utilisateur
        moyen_approvisionnement: item.moyen_approvisionnement || '', // Moyen d'approvisionnement
      });
    }
  }, [item, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${APP_API_URL}/approvisionnements/${itemId}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: response.data.message || 'Approvisionnement mis à jour avec succès !',
          timer: 2000,
          showConfirmButton: false
        });

        // setTimeout(() => {
          const closeModal = document.getElementById('returnToAllapprovisionnements');
          if (closeModal) closeModal.click();
        // }, 1000);
      }

    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors);

        Swal.fire({
          icon: 'error',
          title: 'Erreur de validation',
          text: 'Veuillez vérifier les informations fournies et réessayer.',
          footer: '<strong>Assurez-vous que tous les champs obligatoires sont remplis correctement.</strong>'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la mise à jour. Veuillez réessayer.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData(item ? { ...item } : {}); // Réinitialiser en utilisant les données actuelles
    const closeModal = document.getElementById('returnToAllapprovisionnements');
    if (closeModal) closeModal.click();
  };


  return (
    <MainLayout>
      <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center mb-16'>
        <div className="pagetitle">
          <h1>Réception de la commande</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Gérer vos approvisionnements</li>
            </ol>
          </nav>
        </div>

        <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
          <div>
            <Link to="/approvisionnement" id="returnToAllapprovisionnements" className="inline-flex items-center rounded-md bg-darkgreen px-2.5 py-2 text-sm font-semibold text-white hover:bg-lightgreen transition-all duration-300">
              <i className="bi bi-arrow-left me-1" /> Retour aux approvisionnements
            </Link>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-body pt-4">
            <div className="accordion border-none" id="accordionPanelsStayOpenExample">
              <div className="accordion-item border-none p-0">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="inline-flex items-center text-lg font-semibold text-gray-900 py-3 accordion-button bg-white p-0 border-b"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="true"
                    aria-controls="flush-collapseOne"
                  >
                    <div>
                      <i className="bi bi-info-circle me-1 text-lightgreen" /> Réception de la commande
                    </div>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                >
                  <div className="accordion-body px-0">
                    <div className="row">
                      <div className="col-lg-3 col-sm-6 col-12 mb-3">
                        <label htmlFor="quantity" className="form-label">Quantité commandée</label>
                        <input
                          type="number"
                          min={1}
                          readOnly
                          id='quantity'
                          className="form-control"
                          value={formData.quantity}
                          onChange={(e) => setFormData({ ...formData, quantity: e.target.value })}
                        />
                      </div>
                      <div className="col-lg-3 col-sm-6 col-12 mb-3">
                        <label htmlFor="quantity" className="form-label text-gray-950">Quantité reçue</label>
                        <input
                          type="number"
                          className={`form-control ${errors.quantity ? 'is-invalid' : ''}`}
                          id="quantity"
                          name="quantity"
                          value={formData.quantity}
                          onChange={handleChange}
                        />
                        {errors.quantity && <div className="invalid-feedback">{errors.quantity[0]}</div>}
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-sm-6 col-12 mb-3">
                          <label htmlFor="unit_price" className="form-label">Prix unitaire</label>
                          <input
                            type="number"
                            min={1}
                            id='unit_price'
                            className="form-control"
                            value={formData.unit_price}
                            onChange={(e) => setFormData({ ...formData, unit_price: e.target.value })}
                          />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12 mb-3">
                          <label htmlFor="total_price" className="form-label">Prix total</label>
                          <input
                            type="number"
                            min={1}
                            id='total_price'
                            className="form-control"
                            value={formData.total_price}
                            onChange={(e) => setFormData({ ...formData, total_price: e.target.value })}
                          />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12 mb-3">
                          <label htmlFor="prix_achat" className="form-label text-gray-950">Prix d'achat</label>
                          <input
                            type="number"
                            className="form-control"
                            id="prix_achat"
                            name="prix_achat"
                            value={formData.prix_achat}
                            onChange={(e) => setFormData({ ...formData, prix_achat: e.target.value })}
                          />
                          {/* {errors.prix_achat && <div className="invalid-feedback">{errors.prix_achat[0]}</div>} */}
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12 mb-3">
                          <label htmlFor="prix_vente" className="form-label text-gray-950">Prix de vente</label>
                          <input
                            type="number"
                            className="form-control"
                            id="prix_vente"
                            name="prix_vente"
                            value={formData.prix_vente}
                            onChange={(e) => setFormData({ ...formData, prix_vente: e.target.value })}
                          />
                          {/* {errors.prix_vente && <div className="invalid-feedback">{errors.prix_vente[0]}</div>} */}
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-lg-3 col-sm-6 col-12 mb-3">
                          <label htmlFor="nb_pieces_par_carton" className="form-label text-gray-950">Nombre de pièces par carton</label>
                          <input
                            type="number"
                            className="form-control"
                            id="nb_pieces_par_carton"
                            name="nb_pieces_par_carton"
                            value={formData.nb_pieces_par_carton}
                            onChange={(e) => setFormData({ ...formData, nb_pieces_par_carton: e.target.value })}
                          />
                          {/* {errors.nb_pieces_par_carton && <div className="invalid-feedback">{errors.nb_pieces_par_carton[0]}</div>} */}
                        </div>

                        <div className="col-lg-3 col-sm-6 col-12 mb-3">
                          <label htmlFor="nb_cartons_total" className="form-label text-gray-950">Nombre total de cartons</label>
                          <input
                            type="number"
                            className="form-control"
                            id="nb_cartons_total"
                            name="nb_cartons_total"
                            value={formData.nb_cartons_total}
                            onChange={(e) => setFormData({ ...formData, nb_cartons_total: e.target.value })}
                          />
                          {/* {errors.nb_cartons_total && <div className="invalid-feedback">{errors.nb_cartons_total[0]}</div>} */}
                        </div>

                        {item?.produit?.category_id === 17 && (
                          <div className="col-lg-3 col-sm-6 col-12 mb-3">
                            <label htmlFor="nb_m2_par_carton" className="form-label text-gray-950">Nombre de m² par carton</label>
                            <input
                              type="number"
                              className="form-control"
                              id="nb_m2_par_carton"
                              name="nb_m2_par_carton"
                              value={formData.nb_m2_par_carton}
                              onChange={(e) => setFormData({ ...formData, nb_m2_par_carton: e.target.value })}
                            />
                            {/* {errors.nb_m2_par_carton && <div className="invalid-feedback">{errors.nb_m2_par_carton[0]}</div>} */}
                          </div>
                        )}

                        {item?.produit?.category_id === 17 && (
                          <div className="col-lg-3 col-sm-6 col-12 mb-3">
                            <label htmlFor="apro_m2_total" className="form-label text-gray-950">Nombre de m² total</label>
                            <input
                              type="number"
                              className="form-control"
                              id="apro_m2_total"
                              name="apro_m2_total"
                              value={formData.apro_m2_total}
                              onChange={(e) => setFormData({ ...formData, apro_m2_total: e.target.value })}
                            />
                            {/* {errors.apro_m2_total && <div className="invalid-feedback">{errors.apro_m2_total[0]}</div>} */}
                          </div>
                        )}

                      </div>

                      <div className="col-md-12">
                        <label htmlFor="commentaire_reception" className="form-label text-gray-950">Commentaire de réception</label>
                        <textarea
                          className={`form-control ${errors.commentaire_reception ? 'is-invalid' : ''}`}
                          id="commentaire_reception"
                          name="commentaire_reception"
                          value={formData.commentaire_reception}
                          onChange={handleChange}
                        />
                        {errors.commentaire_reception && <div className="invalid-feedback">{errors.commentaire_reception[0]}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end mt-4 gap-x-4">
              <button onClick={handleSubmit} className="inline-flex items-center rounded-md bg-darkgreen px-2.5 py-2 text-sm font-semibold text-white hover:bg-lightgreen transition-all duration-300">
                Confirmer la réception
              </button>
              <button type="button" onClick={resetForm} className="inline-flex items-center rounded-md bg-gray-500 px-2.5 py-2 text-sm font-semibold text-white hover:bg-gray-800 transition-all duration-300">
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
