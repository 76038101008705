import React, { useState, useEffect } from 'react';
import MainLayout from '../../layout/main-layout';
import { APP_API_URL } from '../../utils';
import Add from './add';
import Update from './update';
// import Delete from './delete';
import axios from 'axios';
import { formatDate } from '../../utils/functions';
import Loader from '../../components/loader';

export default function Warehouses() {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });

  const [isOpen, setIsOpen] = useState(false);

  // États pour les filtres
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  // États pour les données
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/warehouses`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setData(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des articles :', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const applyFilters = () => {
    const result = data.filter(item => {
      const matchesStatus = selectedStatus ? item.private === parseFloat(selectedStatus) : true;
      const matchesDate = selectedDate ? new Date(item.date_creation).toDateString() === new Date(selectedDate).toDateString() : true;

      return matchesStatus && matchesDate;
    });

    setFilteredData(result);
    setCurrentPage(1);
  };

  useEffect(() => {
    const result = data.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(result);
    setCurrentPage(1);
  }, [searchTerm, data]);

  const sortedItems = [...filteredData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key, subKey = null) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    // Modifier le tri pour les propriétés imbriquées
    const sortedData = [...filteredData].sort((a, b) => {
      const aValue = subKey ? a[key]?.[subKey] : a[key];
      const bValue = subKey ? b[key]?.[subKey] : b[key];

      if (aValue < bValue) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const handleRefresh = async () => {
    await fetchData();
    setCurrentPage(1);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <MainLayout>
      <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center mb-16'>
        <div className="pagetitle">
          <h1>Magasin</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Gérer vos magasins</li>
            </ol>
          </nav>
        </div>

        <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
          <div className='flex flex-row gap-x-2'>
            <button title='Rafraichir' onClick={handleRefresh} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
          <div>
            <button
              data-bs-toggle="modal"
              data-bs-target={`#addUnitModal`}
              className="inline-flex items-center rounded-md bg-green-600 px-2.5 py-2 text-sm font-semibold text-white hover:bg-green-500 transition-all duration-300"
            >
              <i className="bi bi-plus me-1" /> Ajouter un nouveau magasin
            </button>
            <Add refreshData={handleRefresh} />
          </div>
        </div>
      </div>

      <section className='section'>
        <div className="card mb-4">
          <div className="card-header pb-4">
            <div className="row justify-content-between">
              <div className="col-md-6">
                <div>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex items-center justify-end gap-x-2 col-lg-2 col-md-6 mt-3 mt-md-0">
                <button
                  type="button"
                  title='Options de filtrage'
                  onClick={toggleAccordion}
                  className={`inline-flex items-center border rounded-md px-3 py-2 text-sm font-semibold hover:bg-gray-300 transition-all duration-300 ${isOpen ? 'bg-red-500 text-white' : 'bg-gray-50 text-gray-950'}`}
                >
                  <i className={`bi ${isOpen ? 'bi-x-lg' : 'bi-funnel-fill'}`}></i>
                </button>
              </div>
            </div>

            <div className="accordion" id="accordionExample">
              <div className="accordaion-item">
                <div className={`transition-max-height duration-300 ease-in-out overflow-hidden ${isOpen ? 'md:max-h-20' : 'max-h-0'}`}>
                  <div className="accordion-body px-0 border-t my-4">
                    <form className="row g-3">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-3">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Choisissez une date"
                              value={selectedDate}
                              onChange={e => setSelectedDate(e.target.value)}
                            />
                          </div>
                          <div className="col-md-3">
                            <select className="form-select" value={selectedStatus} onChange={e => setSelectedStatus(e.target.value)}>
                              <option value="">Choisir le statut</option>
                              <option value="0">Active</option>
                              <option value="1">Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className='flex justify-end'>
                          <button type="button" onClick={applyFilters} className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 transition-all duration-300">
                            <i className="bi bi-search me-1" /> Recherche
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-0">
            <div className="table-responsive table-card">
              <table className="table text-nowrap mb-0 table-centered table-hover">
                <thead className="table-light">
                  <tr>
                    <th className="ps-3" onClick={() => requestSort('name')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Nom du magasin
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('location')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Localisation
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('manager')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Manager
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('capacity')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Capacité
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('created_at')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Créée le
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('is_active')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Statut
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th>
                      <button>Action</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((item, index) => (
                      <tr key={'Catégorie-' + index} className='align-middle'>
                        <td className="ps-3 font-semibold">
                          {item.name}
                        </td>
                        <td>
                          {item.location}
                        </td>
                        <td>
                          {item.manager}
                        </td>
                        <td>
                          {item.capacity}
                        </td>
                        <td>
                          {formatDate(item.created_at)}
                        </td>
                        <td>
                          {item.is_active === 1 ? (
                            <span className="badge bg-success">Active</span>
                          ) : (
                            <span className="badge bg-danger">Inactive</span>
                          )}
                        </td>
                        <td style={{ whiteSpace: 'normal' }}>
                          <button
                            href="#!"
                            title="Modifier"
                            className="btn btn-ghost btn-icon btn-xs rounded-xl texttooltip border mr-2"
                            data-bs-toggle="modal"
                            data-bs-target={`#updateWarehousesModal${item.id}`}
                          >
                            <i className="bi bi-pencil-square"></i>
                            <div id="editOne" className="d-none">
                              <span>Modifier</span>
                            </div>
                          </button>
                          <Update item={item} refreshData={handleRefresh} />
                          {/* <button
                            href="#!"
                            title="Supprimer"
                            className="btn btn-ghost btn-icon btn-xs rounded-xl texttooltip border"
                            data-bs-toggle="modal"
                            data-bs-target={`#deleteWarehousesModal${item.id}`}
                          >
                            <i className="bi bi-trash"></i>
                            <div id="trashOne" className="d-none">
                              <span>Supprimer</span>
                            </div>
                          </button>
                          <Delete item={item} refreshData={handleRefresh} /> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="datatable-empty" colSpan="10">Aucun item correspondant aux critères de recherche.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer d-md-flex justify-content-between align-items-center">
            <span>
              {currentItems.length > 0
                ? `Affichage de ${Math.max(indexOfFirstItem + 1, 0)} à ${Math.min(indexOfLastItem, currentItems.length)} sur ${sortedItems.length} entrée${sortedItems.length > 1 ? 's' : ''}`
                : `Aucune entrée à afficher`}
              {currentItems.length === 0 && data.length > 0 && ` (filtré à partir de ${data.length} entrée${data.length > 1 ? 's' : ''} totales)`}
            </span>

            {currentItems.length > 0 && (
              <nav className="mt-2 mt-md-0">
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <a className="page-link" href="#!" onClick={handlePrevPage} disabled={currentPage === 1}>
                      Précédent
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#!" onClick={handleNextPage} disabled={currentPage === totalPages}>
                      Suivant
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
