import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { APP_API_URL, APP_NAME } from '../../utils';
import Loader from '../../components/loader';
import formatNumber, { formatDateWithTime } from '../../utils/functions';
import ComptableHeader from '../../components/comptable-header';

export default function Comptable() {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'date_creation', direction: 'descending' });

  const [isOpen, setIsOpen] = useState(false);

  // États pour les filtres
  const [selectedDate, setSelectedDate] = useState('');
  const [dateRange, setDateRange] = useState('');
  // États pour les données
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/commandes`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const approvedOrders = response.data.data.filter(order => order.statut_commande === 'approuvé' || order.statut_commande === 'livré' && order.paye_complet);
      setData(approvedOrders);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes :', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const applyFilters = () => {
    const today = new Date();
    const startOfWeek = getStartOfWeek(today);
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfQuarter = getStartOfQuarter(today);
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1); // Hier

    const result = data.filter(item => {
      const itemDate = new Date(item.date_creation);
      const isToday = itemDate.toDateString() === today.toDateString();
      const isYesterday = itemDate.toDateString() === yesterday.toDateString();
      const isThisWeek = itemDate >= startOfWeek && itemDate <= today;
      const isThisMonth = itemDate >= startOfMonth && itemDate <= today;
      const isThisQuarter = itemDate >= startOfQuarter && itemDate <= today;
      const isThisYear = itemDate >= startOfYear && itemDate <= today;

      // Vérification des dates en fonction du filtre
      let matchesDate = false;
      switch (dateRange) {
        case 'today':
          matchesDate = isToday;
          break;
        case 'yesterday':
          matchesDate = isYesterday;
          break;
        case 'this-week':
          matchesDate = isThisWeek;
          break;
        case 'this-month':
          matchesDate = isThisMonth;
          break;
        case 'this-quarter':
          matchesDate = isThisQuarter;
          break;
        case 'this-year':
          matchesDate = isThisYear;
          break;
        default:
          matchesDate = true;
      }

      // Si un filtre de date spécifique est appliqué
      if (selectedDate && !dateRange) {
        matchesDate = itemDate.toDateString() === new Date(selectedDate).toDateString();
      }

      return matchesDate;
    });

    setFilteredData(result);
    setCurrentPage(1);
  };

  // Fonction pour obtenir le début de la semaine
  const getStartOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day; // Dimanche comme premier jour de la semaine
    const startOfWeek = new Date(date);
    startOfWeek.setDate(diff);
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
  };

  // Fonction pour obtenir le début du trimestre
  const getStartOfQuarter = (date) => {
    const quarterStartMonth = Math.floor(date.getMonth() / 3) * 3;
    const startOfQuarter = new Date(date.getFullYear(), quarterStartMonth, 1);
    startOfQuarter.setHours(0, 0, 0, 0);
    return startOfQuarter;
  };

  useEffect(() => {
    const result = data.filter(item =>
      item.designation.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(result);
    setCurrentPage(1);
  }, [searchTerm, data]);

  const sortedItems = [...filteredData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key, subKey = null) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...filteredData].sort((a, b) => {
      // Récupérer les valeurs à comparer
      let aValue = subKey ? a[key]?.[subKey] : a[key];
      let bValue = subKey ? b[key]?.[subKey] : b[key];

      // Si la colonne est une chaîne qui doit être convertie en float
      if (['montant_net', 'benefice'].includes(key)) {
        aValue = parseFloat(aValue) || 0;
        console.log('aValue:', typeof (aValue));
        bValue = parseFloat(bValue) || 0;
      }

      // Comparer les valeurs
      if (aValue < bValue) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const handleRefresh = async () => {
    await fetchData();
    setCurrentPage(1);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const calculateTotalFilteredAmount = () => {
    return filteredData.reduce((total, item) => total + parseFloat(item.montant_net), 0);
  };

  const calculateTotalFilteredTVA = () => {
    return filteredData.reduce((total, item) => total + parseFloat(item.tva), 0);
  };

  const calculateTotalFilteredAmountHT = () => {
    return filteredData.reduce((total, item) => total + parseFloat(item.montant_ht), 0);
  };

  const calculateTotalFilteredAIB = () => {
    return filteredData.reduce((total, item) => total + parseFloat(item.aib), 0);
  };

  const calculateTotalFilteredBenefice = () => {
    return filteredData.reduce((total, item) => total + parseFloat(item.benefice || 0), 0);
  };

  const exportToPDF = () => {
    const dataToExport = filteredData.length > 0 ? filteredData : currentItems;

    // Création d'un document PDF
    const doc = new jsPDF();
    doc.setFont("Arial", "normal");

    // Titre du PDF
    doc.text("Liste des Ventes", 20, 20);

    // Entête de la table
    const headers = ["Référence", "Montant Net", "Date", "Statut"];

    // Données à imprimer
    const rows = dataToExport.map(item => [
      item.designation,
      `${formatNumber(item.montant_net)} FCFA`,
      formatDateWithTime(item.date_creation),
      item.statut_commande === "approuvé" ? "Approuvé" :
        item.statut_commande === "en cours" ? "En cours" :
          item.statut_commande === "livré" ? "Livré" : "Annulé"
    ]);

    // Ajouter la table au document PDF
    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 30, // Position de départ pour le tableau
      theme: 'striped', // Le thème de la table
      headStyles: { fillColor: [22, 160, 133] }, // Couleur de fond de l'entête
      styles: { fontSize: 10 }
    });

    // Téléchargement du fichier PDF
    doc.save(`Commandes ${APP_NAME}.pdf`);
  };

  const exportToExcel = () => {
    const dataToExport = filteredData.length > 0 ? filteredData : currentItems;

    // Préparation des données sous forme de tableau
    const worksheetData = dataToExport.map(item => ({
      "Référence": item.designation,
      "Montant Net": `${formatNumber(item.montant_net)} FCFA`,
      "Date": formatDateWithTime(item.date_creation),
      "Statut": item.statut_commande === "approuvé" ? "Approuvé" :
        item.statut_commande === "en cours" ? "En cours" :
          item.statut_commande === "livré" ? "Livré" : "Annulé"
    }));

    // Création d'un worksheet
    const ws = XLSX.utils.json_to_sheet(worksheetData);

    // Création d'un workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Commandes');

    // Téléchargement du fichier Excel
    XLSX.writeFile(wb, `Commandes ${APP_NAME}.xlsx`);
  };

  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=800,width=1200');

    // Si des données filtrées sont présentes, on les utilise, sinon on prend toutes les données disponibles
    const dataToPrint = filteredData.length > 0 ? filteredData : currentItems;

    // Construction des lignes de la table pour l'impression
    const tableRows = dataToPrint.map((item, index) => {
      return `
      <tr>
        <td class="ps-3 font-semibold">${item.designation}</td>
        <td>${formatNumber(item.montant_net)} FCFA</td>
        <td>${formatDateWithTime(item.date_creation)}</td>
        <td>
          ${item.statut_commande === "approuvé" ?
          `<span class="badge bg-info">Approuvé</span>` :
          item.statut_commande === "en cours" ?
            `<span class="badge bg-warning text-dark">En cours</span>` :
            item.statut_commande === "livré" ?
              `<span class="badge bg-success text-dark">Livré</span>` :
              `<span class="badge bg-danger">Annulé</span>`
        }
        </td>
      </tr>
    `;
    }).join('');

    // Création du HTML complet à imprimer
    const tableHTML = `
    <html>
      <head>
        <title>Commandes</title>
        <style>
          body { font-family: Arial, sans-serif; }
          table { width: 100%; border-collapse: collapse; }
          table, th, td { border: 1px solid black; }
          th, td { padding: 8px; text-align: left; }
          .badge { font-size: 12px; padding: 5px; color: #000; }
          .bg-info { background-color: #17a2b8; }
          .bg-warning { background-color: #ffc107; }
          .bg-success { background-color: #28a745; }
          .bg-danger { background-color: #dc3545; }
        </style>
      </head>
      <body>
        <h1>Liste des Commandes</h1>
        <table>
          <thead>
            <tr>
              <th>Référence</th>
              <th>Montant Net</th>
              <th>Date</th>
              <th>Statut</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>
      </body>
    </html>
  `;

    // Ouvrir une nouvelle fenêtre et y ajouter le contenu HTML
    printWindow.document.write(tableHTML);
    printWindow.document.close();
    printWindow.print();
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <ComptableHeader />
      <section className="px-3 mt-20">
        <div className="mx-auto w-full min-h-full max-w-6xl bg-white p-4 sm:p-6 lg:p-8">
          <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center mb-16'>
            <div className="pagetitle">
              <h1>Ventes</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">Gérer vos ventes</li>
                </ol>
              </nav>
            </div>

            <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
              <div className='flex flex-row gap-x-2'>
                <button title='Exporter en PDF' onClick={exportToPDF} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                  <i className="bi bi-filetype-pdf"></i>
                </button>
                <button title='Exporter en Excel' onClick={exportToExcel} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                  <i className="bi bi-filetype-xls"></i>
                </button>
                <button title='Imprimer' onClick={handlePrint} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                  <i className="bi bi-printer"></i>
                </button>
                <button title='Rafraichir' onClick={handleRefresh} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
              </div>
            </div>
          </div>

          <section className='section'>
            <div className="card mb-4">
              <div className="card-header pb-4">
                <div className="flex flex-row justify-content-between">
                  <div className="flex flex-row gap-x-4">
                    <div className=''>
                      <label htmlFor="searchInput" className="form-label">
                        Rechercher
                      </label>
                      <input
                        id="searchInput"
                        type="search"
                        className="form-control"
                        placeholder="Rechercher..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <div className=''>
                      <label htmlFor="ht" className="form-label">
                        Total HT
                      </label>
                      <input
                        id="ht"
                        type="search"
                        className="form-control"
                        placeholder={`${formatNumber(calculateTotalFilteredAmountHT())} FCFA`}
                        disabled
                      />
                    </div>
                    <div className=''>
                      <label htmlFor="tva" className="form-label">
                        Total TVA
                      </label>
                      <input
                        id="tva"
                        type="search"
                        className="form-control"
                        placeholder={`${formatNumber(calculateTotalFilteredTVA())} FCFA`}
                        disabled
                      />
                    </div>
                    <div className=''>
                      <label htmlFor="aib" className="form-label">
                        Total AIB
                      </label>
                      <input
                        id="aib"
                        type="search"
                        className="form-control"
                        placeholder={`${formatNumber(calculateTotalFilteredAIB())} FCFA`}
                        disabled
                      />
                    </div>
                    <div className=''>
                      <label htmlFor="montantTtc" className="form-label">
                        Total TTC
                      </label>
                      <input
                        id="montantTtc"
                        type="search"
                        className="form-control"
                        placeholder={`${formatNumber(calculateTotalFilteredAmount())} FCFA`}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-end gap-x-2 col-lg-2 col-md-6 mt-3 mt-md-0">
                    <button
                      type="button"
                      title='Options de filtrage'
                      onClick={toggleAccordion}
                      className={`inline-flex items-center border rounded-md px-3 py-2 text-sm font-semibold hover:bg-gray-300 transition-all duration-300 ${isOpen ? 'bg-red-500 text-white' : 'bg-gray-50 text-gray-950'}`}
                    >
                      <i className={`bi ${isOpen ? 'bi-x-lg' : 'bi-funnel-fill'}`}></i>
                    </button>
                  </div>
                </div>

                <div className="accordion" id="accordionExample">
                  <div className="accordaion-item">
                    <div className={`transition-max-height duration-300 ease-in-out overflow-hidden ${isOpen ? 'md:max-h-20' : 'max-h-0'}`}>
                      <div className="accordion-body px-0 border-t my-4">
                        <form className="row g-3">
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-3">
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Choisissez une date"
                                  value={selectedDate}
                                  onChange={e => setSelectedDate(e.target.value)}
                                  disabled={dateRange !== ""}
                                />
                              </div>
                              <div className="col-md-3">
                                <select
                                  className="form-select"
                                  value={dateRange}
                                  onChange={e => setDateRange(e.target.value)}>
                                  <option value="">Plage de dates</option>
                                  <option value="today">Aujourd'hui</option>
                                  <option value="yesterday">Hier</option>
                                  <option value="this-week">Cette semaine</option>
                                  <option value="this-month">Ce mois</option>
                                  <option value="this-quarter">Ce trimestre</option>
                                  <option value="this-year">Cette année</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                onClick={applyFilters}
                                className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 transition-all duration-300">
                                <i className="bi bi-search me-1" /> Recherche
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body p-0 printable print:w-full">
                <div className="table-responsive table-card">
                  <table className="table text-nowrap mb-0 table-centered table-hover">
                    <thead className="table-light">
                      <tr>
                        <th className="ps-3" onClick={() => requestSort('designation')}>
                          <button className='inline-flex items-center justify-between w-full'>
                            Référence
                            <i className="bi bi-arrow-down-up text-gray-400"></i>
                          </button>
                        </th>
                        <th onClick={() => requestSort('montant_net')}>
                          <button className='inline-flex items-center justify-between w-full'>
                            Montant TTC
                            <i className="bi bi-arrow-down-up text-gray-400"></i>
                          </button>
                        </th>
                        <th onClick={() => requestSort('benefice')}>
                          <button className='inline-flex items-center justify-between w-full'>
                            Bénéfice
                            <i className="bi bi-arrow-down-up text-gray-400"></i>
                          </button>
                        </th>
                        <th onClick={() => requestSort('customer_id')}>
                          <button className='inline-flex items-center justify-between w-full'>
                            Client
                            <i className="bi bi-arrow-down-up text-gray-400"></i>
                          </button>
                        </th>
                        <th onClick={() => requestSort('date_creation')}>
                          <button className='inline-flex items-center justify-between w-full'>
                            Date
                            <i className="bi bi-arrow-down-up text-gray-400"></i>
                          </button>
                        </th>
                        <th onClick={() => requestSort('statut_commande')}>
                          <button className='inline-flex items-center justify-between w-full'>
                            Statut
                            <i className="bi bi-arrow-down-up text-gray-400"></i>
                          </button>
                        </th>
                        <th>
                          <button>Action</button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                          <tr key={'Catégorie-' + index} className='align-middle'>
                            <td className="ps-3 font-semibold">
                              {item.designation}
                            </td>
                            <td>
                              {formatNumber(item.montant_net)} FCFA
                            </td>
                            <td>
                              {formatNumber(item.benefice)} FCFA
                            </td>
                            <td className="font-semibold">
                              {item.customer.name}
                            </td>
                            <td>
                              {formatDateWithTime(item.date_creation)}
                            </td>
                            <td>
                              {item.statut_commande === "approuvé" && (
                                <span className="badge bg-info">Approuvé</span>
                              )}
                              {item.statut_commande === "en cours" && (
                                <span className="badge bg-warning text-dark">En cours</span>
                              )}
                              {item.statut_commande === "livré" && (
                                <span className="badge bg-success text-dark">Livré</span>
                              )}
                              {item.statut_commande === "annulé" && (
                                <span className="badge bg-danger">Annulé</span>
                              )}
                            </td>
                            <td style={{ whiteSpace: 'normal' }}>
                              <Link to={`/commande/${item.designation}`}
                                title="Modifier"
                                className="btn btn-ghost btn-icon btn-xs rounded-xl texttooltip border mr-2"
                              >
                                <i className="bi bi-pencil-square"></i>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="datatable-empty" colSpan="10">Aucune commande correspondant aux critères de recherche.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card-footer d-md-flex justify-content-between align-items-center">
                <span>
                  {currentItems.length > 0
                    ? `Affichage de ${Math.max(indexOfFirstItem + 1, 0)} à ${Math.min(indexOfLastItem, currentItems.length)} sur ${sortedItems.length} entrée${sortedItems.length > 1 ? 's' : ''}`
                    : `Aucune entrée à afficher`}
                  {currentItems.length === 0 && data.length > 0 && ` (filtré à partir de ${data.length} commande${data.length > 1 ? 's' : ''} totales)`}
                </span>

                {currentItems.length > 0 && (
                  <nav className="mt-2 mt-md-0">
                    <ul className="pagination mb-0">
                      <li className="page-item">
                        <a className="page-link" href="#!" onClick={handlePrevPage} disabled={currentPage === 1}>
                          Précédent
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#!" onClick={handleNextPage} disabled={currentPage === totalPages}>
                          Suivant
                        </a>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};
