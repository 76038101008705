import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APP_API_URL } from "../../utils";
import axios from "axios";
import { useCart } from "../../context/CartContext";
import { APP_NAME } from '../../utils';
import Swal from "sweetalert2";

export default function Shipping({ selectedPaiement, customer_id }) {
  const user = useSelector((state) => state.auth.user);
  const { cart, total, selectedCustomer, remise, fraisLivraison } = useCart();
  // { console.log(selectedCustomer.value) }
  const [formData, setFormData] = useState({
    designation: '',
    telephone: '',
    remise: '',
    prix_unitaire: 0,
    type_commande: 'customer', // ou 'customer'
    statut_commande: 'en cours',
    adresse_livraison: '',
    remarques: '',
    date_livraison_prevue: '', // aa
    date_livraison: '',
    customer_id: '', // aa
    mode_reglement_id: selectedPaiement?.value, // aa
    user_id: user?.id, // Vous pouvez récupérer l'ID de l'utilisateur connecté
    store_id: 1, // ID du magasin
    produits: [], // aa
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      customer_id: customer_id, // Met à jour customer_id si cela change
    }));
  }, [customer_id]);


  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isInValid, setIsInValid] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // Créer un tableau de nouveaux produits à partir du cart
    const nouveauxProduits = cart.map((produit) => ({
      variant_id: produit.id, // L'ID de la variante sélectionnée
      quantite: produit.quantity,
      // unite: produit.unite,
      prix_unitaire_achat: produit.price,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      produits: [...prevFormData.produits, ...nouveauxProduits], // Ajout des nouveaux produits au tableau existant
    }));
  }, [cart]); // Assurez-vous d'ajouter cart comme dépendance

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${APP_API_URL}/commandes`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        setIsValid(response.data.message);

        // Utilisation de SweetAlert pour afficher un message de succès
        Swal.fire({
          icon: 'success',
          title: 'Succès!',
          text: 'La commande a été enregistrée avec succès.',
          timer: 2000,
          showConfirmButton: false
        });

        setTimeout(() => {
          // window.location.href = '/commandes';
        }, 2000);  // Attend 2 secondes avant de rediriger
      }

    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors);

        // Affiche une alerte d'erreur pour chaque champ non valide
        Swal.fire({
          icon: 'error',
          title: 'Erreur de validation',
          text: 'Certains champs sont invalides. Veuillez vérifier et réessayer.',
        });

      } else {
        console.error('Erreur lors de l\'envoi du formulaire', error);

        // Affiche une alerte d'erreur pour toute autre erreur
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors de l\'envoi du formulaire. Veuillez réessayer plus tard.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="shippingModal"
      tabIndex={-1}
      style={{ display: "none", whiteSpace: 'normal' }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <form className="modal-content" onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title">Informations de paiement</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {!isValid && !isInValid &&
              <div
                className="max-w-3xl mx-auto p-6 bg-white rounded shadow-sm my-6"
                id="invoice"
              >
                <div className="grid grid-cols-2 items-center">
                  <div>
                    <img src="/assets/img/logo.png" height={100} width={100} alt="" />
                  </div>
                  <div className="text-right">
                    <p>{APP_NAME}</p>
                    <p className="text-gray-500 text-sm">sales@asebat.com</p>
                    <p className="text-gray-500 text-sm mt-1">+41-442341232</p>
                    <p className="text-gray-500 text-sm mt-1">TVA : 8657671212</p>
                  </div>
                </div>

                {/* Informations sur le client */}
                <div className="grid grid-cols-2 items-center mt-8">
                  <div>
                    <p className="font-bold text-gray-800">Facturer à :</p>
                    <p className="text-gray-500">
                      {selectedCustomer.label || 'Inconnu'}
                      <br />
                      Laravel LLC.
                      <br />
                      102, San-Fransico, CA, USA
                    </p>
                    <p className="text-gray-500">info@laravel.com</p>
                  </div>
                  <div className="text-right">
                    <p>
                      Numéro de facture:
                      <span className="text-gray-500">INV-2023786123</span>
                    </p>
                    <p>
                      Date de la facture: <span className="text-gray-500">03/07/2023</span>
                      <br />
                      Date d'échéance : <span className="text-gray-500">31/07/2023</span>
                    </p>
                  </div>
                </div>

                {/* Tableau des articles de la facture */}
                <div className="-mx-4 mt-8 flow-root sm:mx-0">
                  <table className="min-w-full">
                    <colgroup>
                      <col className="w-full sm:w-1/2" />
                      <col className="sm:w-1/6" />
                      <col className="sm:w-1/6" />
                      <col className="sm:w-1/6" />
                    </colgroup>
                    <thead className="border-b border-gray-300 text-gray-900">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Articles
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Quantité
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Prix
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0"
                        >
                          Montant
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.length === 0 ? (
                        <tr>
                          <td colSpan={4} className="py-5 text-center text-gray-500">
                            Aucun produit dans le panier.
                          </td>
                        </tr>
                      ) : (
                        cart.map(product => (
                          <tr className="border-b border-gray-200" key={product.id}>
                            <td className="max-w-0 py-2 pl-4 pr-3 text-sm sm:pl-0">
                              <div className="font-medium text-gray-900">{product.name}</div>
                            </td>
                            <td className="hidden px-3 py-2 text-right text-sm text-gray-500 sm:table-cell">
                              {product.quantity}
                            </td>
                            <td className="hidden px-3 py-2 text-right text-sm text-gray-500 sm:table-cell">
                              {product.price} FCFA
                            </td>
                            <td className="py-2 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                              {(product.price * product.quantity)} FCFA
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th
                          scope="row"
                          colSpan={3}
                          className="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                        >
                          Sous-total
                        </th>
                        <th
                          scope="row"
                          className="pl-6 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
                        >
                          Sous-total
                        </th>
                        <td className="pl-3 pr-6 pt-6 text-right text-sm text-gray-500 sm:pr-0">
                          {(cart.reduce((total, product) => total + product.price * product.quantity, 0))} FCFA
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          colSpan={3}
                          className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                        >
                          TVA
                        </th>
                        <th
                          scope="row"
                          className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                        >
                          TVA
                        </th>
                        <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                          {/* Calculer la TVA ici, par exemple 10% */}
                          {((cart.reduce((total, product) => total + product.price * product.quantity, 0)) * 0.1)} FCFA
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          colSpan={3}
                          className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                        >
                          Remise
                        </th>
                        <th
                          scope="row"
                          className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                        >
                          Remise
                        </th>
                        <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                          - {remise}%
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          colSpan={3}
                          className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                        >
                          Total
                        </th>
                        <th
                          scope="row"
                          className="pl-6 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                        >
                          Total
                        </th>
                        <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                          {(
                            (cart.reduce((total, product) => total + product.price * product.quantity, 0)) +
                            (cart.reduce((total, product) => total + product.price * product.quantity, 0) * 0.1) - // TVA
                            ((cart.reduce((total, product) => total + product.price * product.quantity, 0) * (remise / 100)))
                          )} FCFA
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                {/*  Footer  */}
                <div className="border-t-2 pt-4 text-xs text-gray-500 text-center mt-16">
                  Veuillez payer la facture avant la date d'échéance. Vous pouvez payer la facture en vous connectant à votre compte sur notre portail client.
                </div>
              </div>
            }

            {isValid && (
              <div className="flex flex-col justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600" width="8em" height="8em" viewBox="0 0 24 24">
                  <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                    <path d="M12 17v-5h-.5m0 5h1M12 9.5V9" />
                    <path d="M5.98 10.762C8.608 5.587 9.92 3 12 3c2.08 0 3.393 2.587 6.02 7.762l.327.644c2.182 4.3 3.274 6.45 2.287 8.022C19.648 21 17.208 21 12.327 21h-.654c-4.88 0-7.321 0-8.307-1.572c-.987-1.572.105-3.722 2.287-8.022z" />
                  </g>
                </svg>
                <p className="text-gray-950">
                  {isValid}
                </p>
              </div>
            )}
          </div>
          <div className="modal-footer">
            {isLoading ? (
              <button
                type="button"
                disabled
                className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-2 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Enregistrement...
              </button>
            ) : (
              <button
                type="submit"
                className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                Enregistrer
              </button>
            )}
            <button
              type="button"
              id="closeModal"
              data-bs-dismiss="modal"
              className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
            >
              Fermer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
