// LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';

// Enregistrer les composants nécessaires pour Chart.js
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

const LineChart = ({ ordersData }) => {
  // Filtrer les commandes pour n'afficher que celles ayant le statut "terminé"
  const completedOrders = ordersData.filter(order => order.statut_commande === 'approuvé');

  // Extraire les labels (Mois-Année) et les données de ventes pour les commandes terminées
  const labels = completedOrders.map(order => {
    const date = new Date(order.date_creation);
    return `${date.getMonth() + 1}-${date.getFullYear()}`; // Format Mois-Anneé (ex: "12-2024")
  });

  // Données pour l'axe Y (montant net de chaque commande terminée)
  const data = completedOrders.map(order => parseFloat(order.montant_net) || 0); // On prend le montant net de chaque commande terminée

  // Construire la structure de données pour Chart.js
  const chartData = {
    labels: labels, // Mois et année comme labels
    datasets: [
      {
        label: 'Total des Ventes Terminées', // Titre de la courbe
        data: data, // Données des ventes terminées
        fill: false, // Pas de remplissage sous la ligne
        borderColor: 'rgba(75,192,192,1)', // Couleur de la ligne
        tension: 0.1, // Légère courbure de la ligne
      },
    ],
  };

  // Options pour le graphique
  const options = {
    responsive: true, // Le graphique est réactif
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Mois',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total des Ventes (FCFA)',
        },
      },
    },
  };

  return (
    <div>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineChart;
