import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../../../layout/main-layout';
import { APP_API_URL } from '../../../utils';
import axios from 'axios';
import Loader from '../../../components/loader';
import Add from './add';
import Update from './update';
import Delete from './delete';

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'nom', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);

  const fetchBrands = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/marques`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setBrands(response.data.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des marques :', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedBrands = [...brands].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredBrands = sortedBrands.filter((brand) => {
    const matchesSearchTerm = brand.nom.toLowerCase().includes(searchTerm.toLowerCase());

    return matchesSearchTerm ;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBrands = filteredBrands.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredBrands.length / itemsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber < 1) {
      pageNumber = 1;
    } else if (pageNumber > totalPages) {
      pageNumber = totalPages;
    }
    setCurrentPage(pageNumber);
  };

  if (isLoading) {
    return (
      <Loader />
    )
  }

  return (
    <MainLayout>
      <div className="pagetitle">
        <h1>Marques</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Accueil</Link>
            </li>
            <li className="breadcrumb-item active">Marques</li>
          </ol>
        </nav>
      </div>

      <section className='section'>
        <div className="card mb-4">
          <div className="card-header">
            <div className="row justify-content-between">
              <div className="col-md-6 mb-3">
                <a
                  href="#!"
                  className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                  data-bs-toggle="modal"
                  data-bs-target="#addBrandModal"
                >
                  + Ajouter une marque
                </a>
                <Add onElementAdded={fetchBrands} />
              </div>

              <div className="col-lg-4 col-md-6">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Rechercher une marque"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive table-card">
              <table className="table text-nowrap mb-0 table-centered table-hover">
                <thead className="table-light">
                  <tr>
                    <th className="ps-3" onClick={() => handleSort('nom')}>
                      <button>Marque</button>
                    </th>
                    <th>
                      <button>Ajouté par</button>
                    </th>
                    <th>
                      <button>Date de création</button>
                    </th>
                    <th>
                      <button className='ps-[0.75rem]'>Action</button>
                    </th>
                  </tr>
                </thead>
                <tbody className='dashboard'>
                  {currentBrands.map((brand) => (
                    <tr key={brand.id}>
                      <td className="ps-3">
                        <div className='flex flex-row items-center top-selling'>
                          {/* {brand.logo ? (
                            <img src={brand.logo} alt={`Logo ${brand.nom}`} className="brand-logo rounded-md max-w-xs" />
                          ) : (
                            <p>Aucun logo</p>
                          )} */}
                          <div className='flex flex-col'>
                            <span className='font-semibold'>{brand.nom}</span>
                            <span className='text-xs'>{brand.reference}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        {brand.ajouter_par.name}
                      </td>
                      <td>
                        {new Date(brand.date_creation).toLocaleDateString()}
                      </td>
                      <td>
                        <button
                          href="#!"
                          className="btn btn-ghost btn-icon btn-xs rounded-circle texttooltip"
                          data-bs-toggle="modal"
                          data-bs-target={`#updateBrandModal${brand.id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-edit icon-xs"
                          >
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                          </svg>
                          <div id="editOne" className="d-none">
                            <span>Modifier</span>
                          </div>
                        </button>
                        <Update brand={brand} onElementAdded={fetchBrands} />
                        <button
                          href="#!"
                          className="btn btn-ghost btn-icon btn-xs rounded-circle texttooltip"
                          data-bs-toggle="modal"
                          data-bs-target={`#deleteBrandModal${brand.id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-trash-2 icon-xs"
                          >
                            <polyline points="3 6 5 6 21 6" />
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                            <line x1={10} y1={11} x2={10} y2={17} />
                            <line x1={14} y1={11} x2={14} y2={17} />
                          </svg>
                          <div id="trashOne" className="d-none">
                            <span>Supprimer</span>
                          </div>
                        </button>
                        <Delete brand={brand} onElementAdded={fetchBrands} />
                      </td>
                    </tr>
                  ))}

                  {filteredBrands.length === 0 && (
                    <tr>
                      <td className="datatable-empty" colSpan="5">Aucune entrée trouvée</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer d-md-flex justify-content-between align-items-center">
            {filteredBrands.length > 0 && (
              <>
                <span>
                  Affichage de {Math.max(indexOfFirstItem + 1, 0)} à {Math.min(indexOfLastItem, filteredBrands.length)} sur {filteredBrands.length} entrées
                </span>
                {filteredBrands.length > itemsPerPage &&
                  <nav className="mt-2 mt-md-0">
                    <ul className="pagination mb-0">
                      <li className="page-item">
                        <a className="page-link" href="#!" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                          Précédent
                        </a>
                      </li>
                      {[...Array(totalPages).keys()].map((number) => (
                        <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                          <a className="page-link" href="#!" onClick={() => paginate(number + 1)}>
                            {number + 1}
                          </a>
                        </li>
                      ))}
                      <li className="page-item">
                        <a className="page-link" href="#!" onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                          Suivant
                        </a>
                      </li>
                    </ul>
                  </nav>
                }
              </>
            )}
          </div>
        </div>
      </section>
    </MainLayout >
  );
};

export default Brands;
