import React from 'react';
import { useCart } from '../../context/CartContext';
import MainLayout from '../../layout/main-layout';
import OrderLayout from '../../layout/order-layout';
import { APP_NAME } from '../../utils';
// import { useCart } from './CartContext'; // Importez le contexte

const OrderDetails = () => {
  const { cart, total, selectedCustomer, remise, fraisLivraison } = useCart();

  return (
    <OrderLayout>

      <div
        className="max-w-3xl mx-auto p-6 bg-white rounded shadow-sm my-6"
        id="invoice"
      >
        <div className="grid grid-cols-2 items-center">
          <div>
            <img src="/assets/img/logo.png" height={100}
              width={100} alt="" />

          </div>
          <div className="text-right">
            <p>{APP_NAME}</p>
            <p className="text-gray-500 text-sm">sales@asebat.com</p>
            <p className="text-gray-500 text-sm mt-1">+41-442341232</p>
            <p className="text-gray-500 text-sm mt-1">TVA : 8657671212</p>
          </div>
        </div>

        {/* Informations sur le client */}
        <div className="grid grid-cols-2 items-center mt-8">
          <div>
            <p className="font-bold text-gray-800">Facturer à :</p>
            <p className="text-gray-500">
              {selectedCustomer.label || 'Inconnu'}
              <br />
              Laravel LLC.
              <br />
              102, San-Fransico, CA, USA
            </p>
            <p className="text-gray-500">info@laravel.com</p>
          </div>
          <div className="text-right">
            <p>
              Numéro de facture:
              <span className="text-gray-500">INV-2023786123</span>
            </p>
            <p>
              Date de la facture: <span className="text-gray-500">03/07/2023</span>
              <br />
              Date d'échéance : <span className="text-gray-500">31/07/2023</span>
            </p>
          </div>
        </div>

        {/* Tableau des articles de la facture */}
        <div className="-mx-4 mt-8 flow-root sm:mx-0">
          <table className="min-w-full">
            <colgroup>
              <col className="w-full sm:w-1/2" />
              <col className="sm:w-1/6" />
              <col className="sm:w-1/6" />
              <col className="sm:w-1/6" />
            </colgroup>
            <thead className="border-b border-gray-300 text-gray-900">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Articles
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Quantité
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Prix
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Montant
                </th>
              </tr>
            </thead>
            <tbody>
              {cart.length === 0 ? (
                <tr>
                  <td colSpan={4} className="py-5 text-center text-gray-500">
                    Aucun produit dans le panier.
                  </td>
                </tr>
              ) : (
                cart.map(product => (
                  <tr className="border-b border-gray-200" key={product.id}>
                    <td className="max-w-0 py-2 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="font-medium text-gray-900">{product.name}</div>
                    </td>
                    <td className="hidden px-3 py-2 text-right text-sm text-gray-500 sm:table-cell">
                      {product.quantity}
                    </td>
                    <td className="hidden px-3 py-2 text-right text-sm text-gray-500 sm:table-cell">
                      {product.price} FCFA
                    </td>
                    <td className="py-2 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                      {(product.price * product.quantity)} FCFA
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            <tfoot>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                >
                  Sous-total
                </th>
                <th
                  scope="row"
                  className="pl-6 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
                >
                  Sous-total
                </th>
                <td className="pl-3 pr-6 pt-6 text-right text-sm text-gray-500 sm:pr-0">
                  {(cart.reduce((total, product) => total + product.price * product.quantity, 0))} FCFA
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                >
                  TVA
                </th>
                <th
                  scope="row"
                  className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                >
                  TVA
                </th>
                <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                  {/* Calculer la TVA ici, par exemple 10% */}
                  {((cart.reduce((total, product) => total + product.price * product.quantity, 0)) * 0.1)} FCFA
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                >
                  Remise
                </th>
                <th
                  scope="row"
                  className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                >
                  Remise
                </th>
                <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                  - {remise}%
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                >
                  Total
                </th>
                <th
                  scope="row"
                  className="pl-6 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                >
                  Total
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                  {(
                    (cart.reduce((total, product) => total + product.price * product.quantity, 0)) +
                    (cart.reduce((total, product) => total + product.price * product.quantity, 0) * 0.1) - // TVA
                    ((cart.reduce((total, product) => total + product.price * product.quantity, 0) * (remise / 100)))
                  )} FCFA
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        {/*  Footer  */}
        <div className="border-t-2 pt-4 text-xs text-gray-500 text-center mt-16">
          Veuillez payer la facture avant la date d'échéance. Vous pouvez payer la facture en vous connectant à votre compte sur notre portail client.
        </div>
      </div>
    </OrderLayout>
  );
};

export default OrderDetails;
