import React, { useState } from "react";
import axios from 'axios';
import { APP_API_URL } from "../../utils";
import { useSelector } from "react-redux";
import Spin from "../../components/Spin";
import UIDropdown from "../../components/ui/ui-dropdown";
import Swal from "sweetalert2";

export default function Add({ refreshData, storeData, posteData }) {
  const user = useSelector((state) => state.auth.user);
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    poste_id: '',
    telephone: '',
    date_embauche: getCurrentDate(),
    statut: 'actif', // par défaut à actif
    store_id: '', // À remplir si nécessaire
    description: '',
    user_id: user?.id,
    password: '', // À ajouter pour l'utilisateur personnel
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState({ label: "Sélectionner", value: "" });
  const [selectedPoste, setSelectedPoste] = useState({ label: "Sélectionner", value: "" });

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStoreSelect = (option) => {
    setSelectedStore(option);
    setFormData(prevState => ({
      ...prevState,
      store_id: option.value
    }));
  };

  const handlePosteSelect = (option) => {
    setSelectedPoste(option);
    setFormData(prevState => ({
      ...prevState,
      poste_id: option.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${APP_API_URL}/personnels`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Afficher un message de succès avec SweetAlert
        Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: response.data.message || 'Personnel ajouté avec succès !',
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });

        const closeModal = document.getElementById(`close-add-personnel-modal`);

        setTimeout(() => {
          setFormData({
            name: '',
            email: '',
            poste: '',
            telephone: '',
            statut: 'actif',
            store_id: '',
            description: '',
            user_id: user?.id,
            password: '',
          });
          closeModal.click();
          refreshData();
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Afficher les erreurs de validation avec SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Erreur de validation',
          text: 'Certains champs contiennent des erreurs. Veuillez les corriger.',
        });
        setErrors(error.response.data.errors);
      } else {
        console.error('Erreur lors de l\'envoi du formulaire', error);
        // Afficher un message générique d'erreur avec SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors de l\'ajout du personnel. Veuillez réessayer.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="addPersonnelModal"
      tabIndex={-1}
      style={{ display: "none", whiteSpace: 'normal' }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <form className="modal-content" onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title text-lg font-bold text-gray-600">Ajouter un personnel</h5>
          </div>
          <div className="modal-body">
            <div className="row g-3">
              {/* Nom */}
              <div className="col-md-6">
                <label htmlFor="name" className="form-label text-gray-950">Nom du personnel</label>
                <input
                  type="text"
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <div className="invalid-feedback">{errors.name[0]}</div>}
              </div>

              {/* Email */}
              <div className="col-md-6">
                <label htmlFor="email" className="form-label text-gray-950">Email</label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <div className="invalid-feedback">{errors.email[0]}</div>}
              </div>

              {/* Téléphone */}
              <div className="col-md-6">
                <label htmlFor="telephone" className="form-label text-gray-950">Téléphone</label>
                <input
                  type="text"
                  className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                  id="telephone"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleChange}
                />
                {errors.telephone && <div className="invalid-feedback">{errors.telephone[0]}</div>}
              </div>

              <div className="col-md-6">
                <label htmlFor="store" className="form-label text-gray-950">Choisir une poste</label>
                <UIDropdown
                  options={posteData.map(item => ({
                    label: item.titre,
                    value: item.id,
                  }))}
                  selectedOption={selectedPoste}
                  onSelect={handlePosteSelect}
                  enableSearch={true}
                />
                {errors.poste_id && <div className="text-sm text-red-600">{errors.poste_id[0]}</div>}
              </div>

              <div className="col-md-6">
                <label htmlFor="store" className="form-label text-gray-950">Choisir une boutique</label>
                <UIDropdown
                  options={storeData.map(item => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  selectedOption={selectedStore}
                  onSelect={handleStoreSelect}
                  enableSearch={true}
                />
                {errors.store_id && <div className="text-sm text-red-600">{errors.store_id[0]}</div>}
              </div>

              {/* Mot de passe */}
              <div className="col-md-6">
                <label htmlFor="password" className="form-label text-gray-950">Mot de passe</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? "Masquer" : "Afficher"}
                  </button>
                </div>
                {errors.password && <div className="invalid-feedback">{errors.password[0]}</div>}
              </div>

              {/* Description */}
              <div className="col-12">
                <label htmlFor="description" className="form-label text-gray-950">Description</label>
                <textarea
                  className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
                {errors.description && <div className="invalid-feedback">{errors.description[0]}</div>}
              </div>

              <div className="col-md-12">
                <input
                  type="checkbox"
                  className={`form-check-input ${errors.statut ? 'is-invalid' : ''}`}
                  id="statut"
                  name="statut"
                  checked={formData.statut === 'actif'}
                  onChange={(e) => handleChange({ target: { name: 'statut', value: e.target.checked ? 'actif' : 'inactif' } })}
                />
                <label htmlFor="statut" className="form-label ml-2 text-gray-950">Statut</label>
                <span className="text-xs block text-gray-900">(Décochez cette case si vous souhaitez que ce personnel soit inactif.)</span>
                {errors.statut && <div className="invalid-feedback">{errors.statut[0]}</div>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {isLoading ? (
              <button
                type="button"
                disabled
                className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                <Spin />
                Enregistrement...
              </button>
            ) : (
              <button
                type="submit"
                className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                Enregistrer
              </button>
            )}
            <button
              type="button"
              id="close-add-personnel-modal"
              data-bs-dismiss="modal"
              className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
            >
              Fermer
            </button>
          </div>
        </form>
      </div>
    </div>
  )
};
