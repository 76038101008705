// BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// Enregistrer les composants nécessaires pour Chart.js
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const BarChart = ({ ordersData }) => {
  // Comptabiliser les commandes par statut
  const statusCount = {
    "en cours": 0,
    "approuvé": 0,
    "livré": 0,
    "annulé": 0,
  };

  // Parcourir les commandes pour compter les statuts
  ordersData.forEach(order => {
    if (statusCount[order.statut_commande] !== undefined) {
      statusCount[order.statut_commande]++;
    }
  });

  // Préparer les données pour le graphique
  const chartData = {
    labels: ['En Cours', 'Approuvé', 'Livré', 'Annulé'], // Statuts
    datasets: [
      {
        label: 'Nombre de Ventes', // Légende du graphique
        data: [statusCount['en cours'], statusCount['approuvé'], statusCount['livré'], statusCount['annulé']], // Nombre de commandes pour chaque statut
        backgroundColor: [
          'rgba(255, 193, 7, 0.6)', // Couleur pour "en cours"
          'rgba(13, 202, 240, 0.6)', // Couleur pour "approuvé"
          'rgba(25, 135, 84, 0.6)', // Couleur pour "livré"
          'rgba(220, 53, 69, 0.6)', // Couleur pour "annulé"
        ],
        borderColor: [
          'rgba(255, 193, 7, 1)',
          'rgba(13, 202, 240, 1)',
          'rgba(25, 135, 84, 1)',
          'rgba(220, 53, 69, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // Options pour personnaliser le graphique
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Statut des Ventes',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Nombre de Ventes',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
