import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { APP_API_URL } from '../utils';

// Création des contextes
const DataContext = createContext();

// Fournisseur de contexte
export const DataProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);

  // Fonction pour récupérer les catégories
  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/categories`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCategories(response.data.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories :', error);
    }
  };

  // Fonction pour récupérer les produits
  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/products`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProducts(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des articles :', error);
    }
  };

  // Fonction pour récupérer les clients
  const fetchCustomers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/customers`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCustomers(response.data.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des clients :', error);
    }
  };

  // Utilisation de useEffect pour charger les données initiales
  useEffect(() => {
    fetchCategories();
    fetchProducts();
    fetchCustomers();
  }, []);

  // Fournir les valeurs du contexte, incluant fetchCategories
  return (
    <DataContext.Provider value={{ categories, products, customers, fetchCategories, fetchProducts, fetchCustomers }}>
      {children}
    </DataContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useDataContext = () => useContext(DataContext);
