import React, { useEffect, useState } from 'react';
import MainLayout from '../../layout/main-layout';
import { useSelector } from 'react-redux';
import UIDropdown from '../../components/ui/ui-dropdown';
import { APP_API_URL } from '../../utils';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/functions';

// Liste des périodes disponibles
const periods = [
  { label: "Aujourd'hui", value: "aujourd'hui" },
  { label: "Hier", value: "hier" },
  { label: "Derniers 7 jours", value: "dernier_7_jours" },
  { label: "Derniers 30 jours", value: "dernier_30_jours" },
  { label: "Ce mois", value: "ce_mois" },
  { label: "Le mois dernier", value: "dernier_mois" },
];

const Welcome = () => {
  const user = useSelector((state) => state.auth.user);
  const [selectedPeriod, setSelectedPeriod] = useState({ label: "Aujourd'hui", value: "aujourd'hui" });
  const [lastData, setLastData] = useState([]);
  const [salesData, setSalesData] = useState(null);
  const [weeklyRevenue, setWeeklyRevenue] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fonction appelée lorsque l'utilisateur sélectionne une période dans le Dropdown
  const handlePeriodSelect = (option) => {
    setSelectedPeriod(option);
  };

  const fetchLastData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/orders/last-delivered`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setLastData(response.data.commandes);
    } catch (error) {
      console.error('Erreur lors de la récupération des variants :', error);
    }
  };

  const fetchSalesStats = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${APP_API_URL}/sales/stats`,
        { periode: selectedPeriod.value, user_id: user?.id },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setSalesData(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques :', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWeeklyRevenue = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${APP_API_URL}/sales/weekly-revenue`,
        { periode: selectedPeriod.value, user_id: user?.id },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setWeeklyRevenue(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques :', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLastData();
  }, []);

  useEffect(() => {
    fetchWeeklyRevenue();
    fetchSalesStats();
  }, [selectedPeriod]);

  return (
    <MainLayout>
      <section className="section dashboard">
        <div className="col-12">
          <div className='p-3 mb-10 card'>
            <div className='lg:flex justify-between items-center space-y-4 lg:space-y-0'>
              <h1 className='text-gray-600 text-lg'>👋 Salut <span className="fw-bold fs-5 text-gray-950">{user?.name}</span>, voici ce qui se passe avec votre magasin {selectedPeriod.label}.</h1>
              <div className='flex gap-x-4 items-center'>
                <UIDropdown
                  options={periods}
                  selectedOption={selectedPeriod}
                  onSelect={handlePeriodSelect}
                />
                {/* <button
                  onClick={() => console.log(`Rafraîchir les données pour la période : ${selectedPeriod.label}`)}
                  className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                >
                  <RotateCcw />
                </button> */}
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          {/* Sales Card */}
          <div className="col-xxl-6 col-md-6">
            <div className="card info-card sales-card">
              <div className="card-body">
                <h5 className="card-title">
                  Total des ventes <span className='lowercase'>{selectedPeriod.label}</span>
                </h5>
                <div>
                  <h6 className='text-2xl text-darkgreen font-bold'>{weeklyRevenue?.revenu}</h6>
                  {weeklyRevenue?.changement ? (
                    <>
                      <span className="text-success small pt-1 fw-bold">{weeklyRevenue?.changement}</span>{" "}
                      <span className="text-muted small pt-2 ps-1">par rapport à la semaine dernière</span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* End Sales Card */}
          {/* Revenue Card */}
          <div className="col-xxl-3 col-md-6">
            <div className="card info-card sales-card bg-darkgreen">
              <div className="card-body">
                <div className='flex items-center justify-between'>
                  <h5 className="card-title text-white">Nombre de ventes totales</h5>
                  <div className="filter">
                    <a className="icon" href="#"><i
                      className="bi bi-arrow-clockwise text-white"></i></a>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-cart text-white"></i>
                  </div>
                  <div className="ps-3 text-white">
                    <h6 className='text-2xl text-white font-bold'>
                      {salesData ? salesData.total_ventes : 'Chargement...'}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Revenue Card */}
          {/* Customers Card */}
          <div className="col-xxl-3 col-xl-12">
            <div className="card info-card sales-card bg-lightgreen">
              <div className="card-body">
                <div className='flex items-center justify-between'>
                  <h5 className="card-title text-white">Nombre de commandes en cours</h5>
                  <div className="filter">
                    <a className="icon" href="#"><i
                      className="bi bi-arrow-clockwise text-white"></i></a>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-cart text-white"></i>
                  </div>
                  <div className="ps-3 text-white">
                    <h6 className='text-2xl text-white font-bold'>
                      {salesData ? salesData.ventes_en_cours : 'Chargement...'}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Customers Card */}
        </div>

        <div className="row">
          {/* Colonnes à gauche */}
          <div className="col-lg-12">
            <div className="row">
              {/* Meilleures Ventes */}
              <div className="col-12">
                <div className="card top-selling overflow-auto">
                  <div className="card-body pb-0">
                    <h5 className="card-title">
                      Transactions récentes
                    </h5>
                    <table className="table text-nowrap mb-0 table-centered table-hover">
                      <thead className="table-light">
                        <tr>
                          <th className="ps-3">
                            <button className='inline-flex items-center justify-between w-full'>
                              Référence
                              <i className="bi bi-arrow-down-up text-gray-400"></i>
                            </button>
                          </th>
                          <th className="ps-3">
                            <button className='inline-flex items-center justify-between w-full'>
                              Client
                              <i className="bi bi-arrow-down-up text-gray-400"></i>
                            </button>
                          </th>
                          <th className="ps-3">
                            <button
                              className='inline-flex items-center justify-between w-full'
                            >
                              Date de création
                              <i className="bi bi-arrow-down-up text-gray-400 ml-2"></i>
                            </button>
                          </th>
                          <th>
                            <button>Status</button>
                          </th>
                          <th>
                            <button>Action</button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {lastData.length > 0 ? (
                          lastData.map((item, index) => (
                            <tr key={'Commande-' + index} className='align-middle'>
                              <td className="ps-3 font-semibold">
                                {item.commande.designation}
                              </td>
                              <td>
                                {item?.commande.customer?.name}
                              </td>
                              <td>
                                {formatDate(item?.commande.created_at)}
                              </td>
                              <td>
                                {item.commande.statut_commande === "en cours" && (<span className="badge bg-warning">En cours</span>)}
                                {item.commande.statut_commande === "approuvé" && (<span className="badge bg-info">Approuvé</span>)}
                                {item.commande.statut_commande === "livré" && (<span className="badge bg-success">Livré</span>)}
                              </td>
                              <td style={{ whiteSpace: 'normal' }}>
                                <Link to={`/commande/${item.commande.designation}`}
                                  className="btn btn-ghost btn-icon bg-lightgreen hover:bg-lime-800 text-white btn-xs rounded-xl texttooltip border"
                                  title="Voir"
                                >
                                  <div className='flex flex-row'>
                                    <i className="bi bi-eye mr-2"></i>
                                    <div id="editOne" className="d-nonea">
                                      <span>Voir</span>
                                    </div>
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="datatable-empty" colSpan="10">Aucun item correspondant aux critères de recherche.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Fin Meilleures Ventes */}
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Welcome;
