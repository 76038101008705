import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { APP_API_URL } from '../../utils';
import Loader from '../../components/loader';
import axios from 'axios';
import ComptableHeader from '../../components/comptable-header';

const ComptableEditProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    name: user?.name || '',
    telephone: user?.personnels[0]?.telephone || '',
  });
  const [formDataPassword, setFormDataPassword] = useState({
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
  });

  const [errors, setErrors] = useState({});

  // Mettre à jour le formulaire à chaque changement
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChangePassword = (e) => {
    const { name, value } = e.target;
    setFormDataPassword({ ...formDataPassword, [name]: value });
  };

  // Soumettre le formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${APP_API_URL}/user/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Success SweetAlert
      Swal.fire({
        title: 'Success!',
        text: 'Profil mis à jour avec succès.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      setErrors({});
    } catch (error) {
      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
      } else {
        // Error SweetAlert
        Swal.fire({
          title: 'Oops...',
          text: 'Erreur lors de la mise à jour du profil.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();

    try {
      // Validation des mots de passe
      if (formDataPassword.new_password !== formDataPassword.new_password_confirmation) {
        return Swal.fire({
          title: 'Erreur',
          text: 'Les mots de passe ne correspondent pas.',
          icon: 'error',
        });
      }

      // Envoi des données de changement de mot de passe
      const token = localStorage.getItem('token'); // On prend le token du localStorage
      const response = await axios.put(`${APP_API_URL}/user/password`, formDataPassword, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Afficher un message de succès avec SweetAlert2
      Swal.fire({
        title: 'Succès',
        text: response.data.message,
        icon: 'success',
        confirmButtonText: 'OK',
      });

      setErrors({});
      setFormDataPassword({
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
      });
    } catch (error) {
      if (error.response?.data?.error) {
        // Afficher l'erreur avec SweetAlert2
        Swal.fire({
          title: 'Erreur',
          text: error.response.data.error,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Erreur',
          text: 'Erreur inconnue.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }

      if (error.response?.data?.errors) {
        setErrors(error.response.data.errors);
      }
    }
  };

  // Si l'utilisateur n'est pas encore chargé (par exemple, il est dans un état de chargement)
  if (!user) {
    return <Loader />;
  }

  return (
    <div>
      <ComptableHeader />
      <div className="mx-auto w-full min-h-full max-w-6xl bg-white p-4 sm:p-6 lg:p-8">
        <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center mb-16'>
          <div className="pagetitle">
            <h1>Profil</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">Modifier mon profil</li>
              </ol>
            </nav>
          </div>
        </div>

        <section className='section'>
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Modifier mon profil</h5>
              <form className='row g-3' onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-gray-700">Nom</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="mt-1 p-2 w-full border border-gray-300 rounded"
                    required
                  />
                  {errors.name && <p className="text-red-500 text-sm">{errors.name[0]}</p>}
                </div>

                <div className="mb-4">
                  <label htmlFor="telephone" className="block text-gray-700">Téléphone</label>
                  <input
                    type="number"
                    id="telephone"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleInputChange}
                    className="mt-1 p-2 w-full border border-gray-300 rounded"
                    required
                  />
                  {errors.telephone && <p className="text-red-500 text-sm">{errors.telephone[0]}</p>}
                </div>

                <div className="mt-4">
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Mettre à jour le profil
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>

        <section className='section'>
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title">Changer le mot de passe</h5>
              <form className='row g-3' onSubmit={handleSubmitPassword}>
                <div className="mb-4">
                  <label htmlFor="old_password" className="block text-gray-700">Ancien mot de passe</label>
                  <input
                    type="password"
                    id="old_password"
                    name="old_password"
                    value={formDataPassword.old_password}
                    onChange={handleInputChangePassword}
                    className="mt-1 p-2 w-full border border-gray-300 rounded"
                    required
                  />
                  {errors.old_password && <p className="text-red-500 text-sm">{errors.old_password[0]}</p>}
                </div>

                <div className="mb-4">
                  <label htmlFor="new_password" className="block text-gray-700">Nouveau mot de passe</label>
                  <input
                    type="password"
                    id="new_password"
                    name="new_password"
                    value={formDataPassword.new_password}
                    onChange={handleInputChangePassword}
                    className="mt-1 p-2 w-full border border-gray-300 rounded"
                    required
                  />
                  {errors.new_password && <p className="text-red-500 text-sm">{errors.new_password[0]}</p>}
                </div>

                <div className="mb-4">
                  <label htmlFor="new_password_confirmation" className="block text-gray-700">Confirmer le mot de passe</label>
                  <input
                    type="password"
                    id="new_password_confirmation"
                    name="new_password_confirmation"
                    value={formDataPassword.new_password_confirmation}
                    onChange={handleInputChangePassword}
                    className="mt-1 p-2 w-full border border-gray-300 rounded"
                    required
                  />
                  {errors.new_password_confirmation && <p className="text-red-500 text-sm">{errors.new_password_confirmation[0]}</p>}
                </div>

                <div className="mt-4">
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Mettre à jour le mot de passe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ComptableEditProfile;
