import React from "react";
import { formatDateWithTime } from "../utils/functions";

export default function InvoiceComponent({ invoiceData, isCopy = true }) {
  console.log(invoiceData);
  return (
    <div className={isCopy ? 'invoice-copy second-copy' : 'invoice-copy'}>
      <div className="flex flex-row items-start justify-between gap-6 mt-10">
        <div>
          <img src="/assets/img/logo.png" height={100} width={100} alt="Logo entreprise" />

          <p className="text-sm font-medium tracking-widest mt-3">
            ASEBAT SARL
          </p>
          <p className="text-sm font-medium">C/1775 C, QUARTIER GBEDJEKOME - OUEDO</p>
          <p className="text-sm font-medium">BP 952  ABOMEY - CALAVI,BENIN</p>
          <p className="text-sm font-medium">IFU 3202434985932 - RCCMRB/ABC/24 B 7993</p>
          <p className="text-sm font-medium">+229 01 61 89 89 00</p>
        </div>
        <div>
          <p className="text-2xl font-medium mt-2">
            <span className="font-bold">Bon de livraison</span> : {invoiceData.designation?.replace("ORDER", "ASB").replace(/(\d{5})\d+/, "$1")}
          </p>
        </div>
      </div>
      {/* <p className="text-sm font-medium mt-2">
        <span className="font-bold">Bon à livrer</span> : {invoiceData.designation?.replace("ORDER", "ASB").replace(/(\d{5})\d+/, "$1")}
      </p> */}
      <p className="text-sm font-medium">
        <span className="font-bold">Référence de la commande</span> : {invoiceData.designation}
      </p>
      <p className="text-sm font-medium">
        <span className="font-bold">Magasin</span> : {invoiceData.store?.name || "Non spécifié"}
      </p>
      <p className="text-sm font-medium">
        <span className="font-bold">Date</span> : {formatDateWithTime(invoiceData?.date_creation)}
      </p>
      <div className="overflow-x-auto">
        <table className="border-collapse table-auto w-full text-sm mt-2 whitespace-pre">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 pe-7 border font-semibold">Code client</th>
              <th className="p-2 border font-semibold text-start">Client</th>
              <th className="p-2 border font-semibold">Téléphone</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr>
              <td className="p-2 font-medium border">{invoiceData?.customer?.client_code}</td>
              <td className="p-2 font-medium border">{invoiceData?.customer?.name}</td>
              <td className="p-2 font-medium border">{invoiceData?.customer?.numero}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto">
        <table className="border-collapse table-auto w-full text-sm whitespace-pre">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 border text-sm font-semibold text-start">
                Produit
              </th>
              <th className="p-2 pe-7 border text-sm font-semibold">
                Quantité
              </th>
              <th className="p-2 border text-sm font-semibold">Unité</th>
              <th className="p-2 border text-sm font-semibold">
                Observation
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {invoiceData?.products?.length > 0 ? (
              invoiceData.products.map((item, index) => (
                <tr key={index}>
                  <td className="p-2 text-sm font-medium border">
                    {item.name}
                  </td>
                  <td className="p-2 text-sm font-medium border">
                    {item.quantite}
                  </td>
                  <td className="p-2 text-sm font-medium border">
                    {item.unite}
                  </td>
                  <td className="p-2 text-sm font-medium border">
                    {item.qte_magasinier}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="p-2 text-center text-base font-medium">
                  Aucun produit dans le panier.
                </td>
              </tr>
            )}
            <tr className="bg-gray-100">
              <td colSpan={4} className="p-2 text-sm font-semibold border">
                Commentaires
              </td>
            </tr>
            <tr>
              <td
                colSpan={4}
                className="p-2 text-sm font-medium border border-e-0"
              >
                Les marchandises vendues ne sont ni reprises ni échangées. <br />La disponibilité de votre achat n'est plus garantie 14 jours <br />après la date de facturation.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex flex-row justify-between items-center mt-4 w-full">
        <div>
          <h1 className="font-semibold">Le Magasinier</h1>
        </div>
        <div>
          <h1 className="font-semibold">Le Réceptionnaire</h1>
        </div>
      </div>
    </div>
  );
}