import { Link } from "react-router-dom";
import MainLayout from "../../layout/main-layout";

const AdminDashboard = () => {
  return (
    <MainLayout>
      <div className="pagetitle">
        <h1>Administrateur</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Accueil</Link>
            </li>
            <li className="breadcrumb-item active">Administrateur</li>
          </ol>
        </nav>
      </div>

      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-8">
            <div className="row">
              {/* Revenue Card */}
              <div className="col-xxl-4 col-md-6">
                <Link to={'/admin/categories'} className="card info-card revenue-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Catégorie
                    </h5>
                  </div>
                </Link>
              </div>
              {/* End Revenue Card */}

              {/* Revenue Card */}
              <div className="col-xxl-4 col-md-6">
                <Link to={'/admin/brands'} className="card info-card revenue-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Marques
                    </h5>
                  </div>
                </Link>
              </div>
              {/* End Revenue Card */}
            </div>
          </div>
          {/* End Left side columns */}
        </div>
      </section>
    </MainLayout>
  );
}

export default AdminDashboard;