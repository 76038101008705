import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MainLayout from '../../../layout/main-layout';
// import Add from '../add';
// import Update from '../update';
// import Delete from '../delete';

const SupplierArticles = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const supplierName = searchParams.get('fournisseur');

  const [suppliers, setSuppliers] = useState([
    {
      id: 1,
      nom: 'Nouveau Fournisseur',
      adresse: 'Adresse du Nouveau Fournisseur',
      telephone: '5555555555',
      description: 'Description du Nouveau Fournisseur',
      statut: 'Actif',
      user_id: 4,
      products: [
        {
          product_id: 1,
          prix_unitaire: 15.0,
        },
        {
          product_id: 2,
          prix_unitaire: 20.0,
        },
      ],
    },
    // Ajoutez d'autres fournisseurs ici
  ]);

  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'nom', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    // Ici vous pouvez charger les fournisseurs depuis une API si nécessaire
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const sortedSuppliers = [...suppliers].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredSuppliers = sortedSuppliers.filter((supplier) => {
    const matchesSearchTerm = supplier.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
      supplier.adresse.toLowerCase().includes(searchTerm.toLowerCase()) ||
      supplier.telephone.includes(searchTerm) ||
      supplier.statut.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesStatus = selectedStatus === '' || supplier.statut === selectedStatus;

    return matchesSearchTerm && matchesStatus;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSuppliers = filteredSuppliers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredSuppliers.length / itemsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber < 1) {
      pageNumber = 1;
    } else if (pageNumber > totalPages) {
      pageNumber = totalPages;
    }
    setCurrentPage(pageNumber);
  };

  return (
    <MainLayout>
      <div className="pagetitle">
        <h1>Articles du fournisseur {supplierName}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Accueil</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/fournisseurs">Fournisseurs</Link>
            </li>
            <li className="breadcrumb-item active">Articles</li>
          </ol>
        </nav>
      </div>

      <section className='section'>
        <div className="card mb-4">
          <div className="card-header">
            <div className="row justify-content-between">
              <div className="col-md-6 mb-3">
                <a
                  href="#!"
                  className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                  data-bs-toggle="modal"
                  data-bs-target="#addSupplierModal"
                >
                  + Ajouter un fournisseur
                </a>
                {/* <Add /> */}
              </div>

              <div className="col-lg-4 col-md-6">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Rechercher un fournisseur, une adresse, un téléphone, un statut ou autre chose"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <div className="col-lg-2 col-md-6 mt-3 mt-md-0">
                <select className="form-select" aria-label="Default select example" value={selectedStatus} onChange={handleStatusChange}>
                  <option value="">Statut</option>
                  <option value="Actif">Actif</option>
                  <option value="Inactif">Inactif</option>
                </select>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive table-card">
              <table className="table text-nowrap mb-0 table-centered table-hover">
                <thead className="table-light">
                  <tr>
                    <th className="ps-3" onClick={() => handleSort('nom')}>
                      <button>Nom</button>
                    </th>
                    <th onClick={() => handleSort('adresse')}>
                      <button>Adresse</button>
                    </th>
                    <th onClick={() => handleSort('telephone')}>
                      <button>Téléphone</button>
                    </th>
                    <th onClick={() => handleSort('description')}>
                      <button>Description</button>
                    </th>
                    <th onClick={() => handleSort('statut')}>
                      <button>Statut</button>
                    </th>
                    <th>
                      <button>Action</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentSuppliers.map((supplier) => (
                    <tr key={supplier.id}>
                      <td className="ps-3">
                        {supplier.nom}
                      </td>
                      <td>{supplier.adresse}</td>
                      <td>{supplier.telephone}</td>
                      <td>{supplier.description}</td>
                      <td>
                        <span className={`badge ${supplier.statut === 'Actif' ? 'badge-success-soft text-success' : 'badge-danger-soft text-danger'}`}>
                          {supplier.statut}
                        </span>
                      </td>
                      <td>
                        <button
                          href="#!"
                          className="btn btn-ghost btn-icon btn-xs rounded-circle texttooltip"
                          data-bs-toggle="modal"
                          data-bs-target="#updateSupplierModal"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-edit icon-xs"
                          >
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                          </svg>
                          <div id="editOne" className="d-none">
                            <span>Modifier</span>
                          </div>
                        </button>
                        {/* <Update supplier={supplier} /> */}
                        <button
                          href="#!"
                          className="btn btn-ghost btn-icon btn-xs rounded-circle texttooltip"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteSupplierModal"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-trash-2 icon-xs"
                          >
                            <polyline points="3 6 5 6 21 6" />
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                            <line x1={10} y1={11} x2={10} y2={17} />
                            <line x1={14} y1={11} x2={14} y2={17} />
                          </svg>
                          <div id="trashOne" className="d-none">
                            <span>Supprimer</span>
                          </div>
                        </button>
                        {/* <Delete supplier={supplier} /> */}
                      </td>
                    </tr>
                  ))}

                  {filteredSuppliers.length === 0 && (
                    <tr>
                      <td className="datatable-empty" colSpan="7">Aucune entrée trouvée</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer d-md-flex justify-content-between align-items-center">
            {filteredSuppliers.length > 0 && (
              <>
                <span>
                  Affichage de {Math.max(indexOfFirstItem + 1, 0)} à {Math.min(indexOfLastItem, filteredSuppliers.length)} sur {filteredSuppliers.length} entrées
                </span>
                <nav className="mt-2 mt-md-0">
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a className="page-link" href="#!" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                        Précédent
                      </a>
                    </li>
                    {[...Array(totalPages).keys()].map((number) => (
                      <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                        <a className="page-link" href="#!" onClick={() => paginate(number + 1)}>
                          {number + 1}
                        </a>
                      </li>
                    ))}
                    <li className="page-item">
                      <a className="page-link" href="#!" onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
                        Suivant
                      </a>
                    </li>
                  </ul>
                </nav>
              </>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default SupplierArticles;
