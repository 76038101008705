import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { utils, writeFile } from 'xlsx';
import MainLayout from '../../layout/main-layout';
import { APP_NAME } from '../../utils';
import { useDataContext } from '../../context/DataContext';
import { formatDate } from '../../utils/functions';
import Add from './add';
import Update from './update';
import Delete from './delete';
import UpdateSubCategories from '../sub-categories/update';
import Loader from '../../components/loader';

const Categories = () => {
  const { categories, fetchCategories } = useDataContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'category', direction: 'ascending' });

  const [isOpen, setIsOpen] = useState(false);

  // États pour les filtres
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  // États pour les données
  const [categoriesData, setCategoriesData] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (categories && categories.length > 0) {
      setIsLoading(true);
      const filteredCategories = categories.filter(category => category.type_categorie === null);
      setCategoriesData(filteredCategories);
      setIsLoading(false);
    }
  }, [categories]);  // Ajout de 'categories' comme dépendance

  const applyFilters = () => {
    const result = categoriesData.filter(item => {
      // Ajout du filtre par statut
      const matchesStatus = selectedStatus ? item.private === parseFloat(selectedStatus) : true;
      const matchesDate = selectedDate ? new Date(item.date_creation).toDateString() === new Date(selectedDate).toDateString() : true;

      return matchesStatus && matchesDate;
    });

    setFilteredCategory(result);
    setCurrentPage(1);
  };

  useEffect(() => {
    const result = categoriesData.filter(item =>
      item.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredCategory(result);
    setCurrentPage(1);
  }, [searchTerm, categoriesData]);

  const sortedCategories = [...filteredCategory].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = sortedCategories.slice(indexOfFirstCategory, indexOfLastCategory);

  const totalPages = Math.ceil(sortedCategories.length / categoriesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key, subKey = null) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    // Modifier le tri pour les propriétés imbriquées
    const sortedData = [...filteredCategory].sort((a, b) => {
      const aValue = subKey ? a[key]?.[subKey] : a[key];
      const bValue = subKey ? b[key]?.[subKey] : b[key];

      if (aValue < bValue) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setFilteredCategory(sortedData);
  };

  const handleRefresh = async () => {
    await fetchCategories(); // Appel de la fonction pour récupérer à nouveau les catégories
    setCurrentPage(1); // Optionnel : réinitialiser la page actuelle si nécessaire
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Catégories', 14, 16);
    const tableColumn = ["Catégorie", "Catégorie slug", "Sous-catégories", "Créée le", "Créé par", "Statut"];
    const tableRows = [];

    currentCategories.forEach(item => {
      // Initialisation de la ligne de données
      const data = [
        item.nom,
        item.nom.replace(/\s/g, "_").toLowerCase(),
      ];

      // Vérification s'il y a des sous-catégories
      if (item.sous_category && item.sous_category.length > 0) {
        item.sous_category.forEach(sousCategorie => {
          // Ajout des sous-catégories au tableau de données
          data.push(sousCategorie.nom);
        });
      } else {
        // S'il n'y a pas de sous-catégories, on peut ajouter une valeur par défaut
        data.push('Pas de sous-catégories');
      }

      // Ajout des autres informations après les sous-catégories
      data.push(
        formatDate(item.date_creation),
        item.createur_categorie.name,
        item.private === 0 ? 'Active' : 'Inactive'
      );

      // Ajout des données finales dans tableRows (par exemple)
      tableRows.push(data);
    });


    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save(`Catégories ${APP_NAME}.pdf`);
  };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(currentCategories);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Catégories");
    writeFile(workbook, `Catégories ${APP_NAME}.xlsx`);
  };

  // Fonction pour imprimer le tableau
  const handlePrint = () => {
    window.print();
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <MainLayout>
      <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center mb-16'>
        <div className="pagetitle">
          <h1>Catégories</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Gérer vos catégories</li>
            </ol>
          </nav>
        </div>

        <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
          <div className='flex flex-row gap-x-2'>
            <button title='Exporter en PDF' onClick={exportToPDF} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-filetype-pdf"></i>
            </button>
            <button title='Exporter en Exel' onClick={exportToExcel} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-filetype-xls"></i>
            </button>
            <button title='Imprimer' onClick={handlePrint} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-printer"></i>
            </button>
            <button title='Rafraichir' onClick={handleRefresh} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
          <div>
            <button
              data-bs-toggle="modal"
              data-bs-target={`#addCategoryModal`}
              className="inline-flex items-center rounded-md bg-green-600 px-2.5 py-2 text-sm font-semibold text-white hover:bg-green-500 transition-all duration-300"
            >
              <i className="bi bi-plus me-1" /> Ajouter une nouvelle catégorie
            </button>
            <Add refreshData={handleRefresh} />
          </div>
        </div>
      </div>

      <section className='section'>
        <div className="card mb-4">
          <div className="card-header pb-4">
            <div className="row justify-content-between">
              <div className="col-md-6">
                <div>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex items-center justify-end gap-x-2 col-lg-2 col-md-6 mt-3 mt-md-0">
                <button
                  type="button"
                  title='Options de filtrage'
                  onClick={toggleAccordion}
                  className={`inline-flex items-center border rounded-md px-3 py-2 text-sm font-semibold hover:bg-gray-300 transition-all duration-300 ${isOpen ? 'bg-red-500 text-white' : 'bg-gray-50 text-gray-950'}`}
                >
                  <i className={`bi ${isOpen ? 'bi-x-lg' : 'bi-funnel-fill'}`}></i>
                </button>
              </div>
            </div>

            <div className="accordion" id="accordionExample">
              <div className="accordaion-item">
                <div className={`transition-max-height duration-300 ease-in-out overflow-hidden ${isOpen ? 'md:max-h-20' : 'max-h-0'}`}>
                  <div className="accordion-body px-0 border-t my-4">
                    <form className="row g-3">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-3">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Choisissez une date"
                              value={selectedDate}
                              onChange={e => setSelectedDate(e.target.value)}
                            />
                          </div>
                          <div className="col-md-3">
                            <select className="form-select" value={selectedStatus} onChange={e => setSelectedStatus(e.target.value)}>
                              <option value="">Choisir le statut</option>
                              <option value="0">Active</option>
                              <option value="1">Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className='flex justify-end'>
                          <button type="button" onClick={applyFilters} className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 transition-all duration-300">
                            <i className="bi bi-search me-1" /> Recherche
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-0">
            <div className="table-responsive table-card">
              <table className="table text-nowrap mb-0 table-centered table-hover">
                <thead className="table-light">
                  <tr>
                    <th className="ps-3" onClick={() => requestSort('nom')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Catégorie
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('nom')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Catégorie slug
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th>
                      <button>Sous-catégories</button>
                    </th>
                    <th onClick={() => requestSort('date_creation')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Créée le
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('createur_categorie', 'name')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Créé par
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th onClick={() => requestSort('private')}>
                      <button className='inline-flex items-center justify-between w-full'>
                        Statut
                        <i className="bi bi-arrow-down-up text-gray-400"></i>
                      </button>
                    </th>
                    <th>
                      <button>Action</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentCategories.length > 0 ? (
                    currentCategories.map((item, index) => (
                      <tr key={'Catégorie-' + index} className='align-middle'>
                        <td className="ps-3 font-semibold">
                          {item.nom}
                        </td>
                        <td>
                          {item.nom.replace(/\s/g, "_").toLowerCase()}
                        </td>
                        <td>
                          <button
                            title="Voir les sous-catégories"
                            data-bs-toggle="modal"
                            data-bs-target={`#subcategoriesModal${item.id}`}
                          >
                            <span className="badge bg-info">Voir les sous-catégories</span>
                          </button>
                          <div
                            className="modal fade"
                            id={`subcategoriesModal${item.id}`}
                            tabIndex={-1}
                            aria-modal="true"
                            role="dialog"
                            style={{ display: "none", whiteSpace: 'normal' }}
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">Liste des sous-catégories <span className='font-bold'>{item.nom}</span></h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  />
                                </div>
                                <div className="modal-body">
                                  <ul className="list-group">
                                    {item.sous_category.length > 0 ? (
                                      item.sous_category.map((item, subIndex) => (
                                        <div key={'subItem-' + subIndex}>
                                          <li className="list-group-item flex justify-between items-center">
                                            {item.nom}
                                            <button
                                              title="Modifier cette catégorie"
                                              data-bs-toggle="modal"
                                              data-bs-target={`#updateSubCategoryModal${item.id}`}
                                            >
                                              <i className="bi bi-pencil-square me-1 text-success"></i>
                                            </button>
                                          </li>
                                          <UpdateSubCategories categories={categories} category={item} refreshData={handleRefresh} />
                                        </div>
                                      ))
                                    ) : (
                                      <li className="list-group-item">Aucun élément trouvé.</li>
                                    )}
                                  </ul>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                                  >
                                    Fermer
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {formatDate(item.date_creation)}
                        </td>
                        <td>
                          {item.createur_categorie.name}
                        </td>
                        <td>
                          {item.private === 0 ? (
                            <span className="badge bg-success">Active</span>
                          ) : (
                            <span className="badge bg-danger">Inactive</span>
                          )}
                        </td>
                        <td style={{ whiteSpace: 'normal' }}>
                          <button
                            href="#!"
                            title="Modifier cette catégorie"
                            className="btn btn-ghost btn-icon btn-xs rounded-xl texttooltip border mr-2"
                            data-bs-toggle="modal"
                            data-bs-target={`#updateCategoryModal${item.id}`}
                          >
                            <i className="bi bi-pencil-square"></i>
                            <div id="editOne" className="d-none">
                              <span>Modifier</span>
                            </div>
                          </button>
                          <Update categories={categories} category={item} refreshData={handleRefresh} />
                          {/* <button
                            href="#!"
                            title="Supprimer cette catégorie"
                            className="btn btn-ghost btn-icon btn-xs rounded-xl texttooltip border"
                            data-bs-toggle="modal"
                            data-bs-target={`#deleteCategoryModal${item.id}`}
                          >
                            <i className="bi bi-trash"></i>
                            <div id="trashOne" className="d-none">
                              <span>Supprimer</span>
                            </div>
                          </button> */}
                          <Delete category={item} refreshData={handleRefresh} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="datatable-empty" colSpan="10">Aucun item correspondant aux critères de recherche.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer d-md-flex justify-content-between align-items-center">
            <span>
              {currentCategories.length > 0
                ? `Affichage de ${Math.max(indexOfFirstCategory + 1, 0)} à ${Math.min(indexOfLastCategory, currentCategories.length)} sur ${sortedCategories.length} entrée${sortedCategories.length > 1 ? 's' : ''}`
                : `Aucune entrée à afficher`}
              {currentCategories.length === 0 && categoriesData.length > 0 && ` (filtré à partir de ${categoriesData.length} entrée${categoriesData.length > 1 ? 's' : ''} totales)`}
            </span>

            {currentCategories.length > 0 && (
              <nav className="mt-2 mt-md-0">
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <a className="page-link" href="#!" onClick={handlePrevPage} disabled={currentPage === 1}>
                      Précédent
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#!" onClick={handleNextPage} disabled={currentPage === totalPages}>
                      Suivant
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default Categories;
