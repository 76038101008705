import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import App from './App';
import './styles/app.css';
import { DataProvider } from './context/DataContext';
import { CartProvider } from './context/CartContext';

const store = createStore(rootReducer, applyMiddleware(thunk));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <DataProvider>
        <CartProvider>
          <Router>
            <App />
          </Router>
        </CartProvider>
      </DataProvider>
    </Provider>
  </React.StrictMode>
);
