import React, { useState } from "react";
import axios from 'axios';
import { APP_API_URL } from "../../utils";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function Add({ refreshData }) {
  const user = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    nom: '',
    adresse: '',
    telephone: '',
    description: '',
    statut: 'actif',
    user_id: user?.id,
    pays: '',        // Ajout du champ pays
    ville: '',       // Ajout du champ ville
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${APP_API_URL}/fournisseurs`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      console.log(response.data);
      // Affichage de SweetAlert en cas de succès
      Swal.fire({
        title: 'Succès',
        text: 'Le fournisseur a été ajouté avec succès.',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        // Redirection après succès

        const closeModal = document.getElementById(`close-add-suppliers-modal`);
        if (closeModal) {
          closeModal.click();
        }
        refreshData();
      });

    } catch (error) {
      // Affichage de SweetAlert en cas d'erreur
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors);
        Swal.fire({
          title: 'Erreur',
          text: 'Des erreurs de validation sont survenues.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      } else {
        console.error('Erreur lors de l\'envoi du formulaire', error);
        Swal.fire({
          title: 'Erreur',
          text: 'Une erreur s\'est produite. Veuillez réessayer.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="addSupplierModal"
      tabIndex={-1}
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <form className="modal-content" onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title text-lg font-bold text-gray-600">Ajouter un fournisseur</h5>
          </div>
          <div className="modal-body">
            <div className="row g-3">
              <div className="col-md-12">
                <label htmlFor="nom" className="form-label text-gray-950">Nom</label>
                <input
                  type="text"
                  className={`form-control ${errors.nom ? 'is-invalid' : ''}`}
                  id="nom"
                  name="nom"
                  value={formData.nom}
                  onChange={handleChange}
                />
                {errors.nom && <div className="invalid-feedback">{errors.nom[0]}</div>}
              </div>
              <div className="col-md-6">
                <label htmlFor="adresse" className="form-label text-gray-950">Adresse</label>
                <input
                  type="text"
                  className={`form-control ${errors.adresse ? 'is-invalid' : ''}`}
                  id="adresse"
                  name="adresse"
                  value={formData.adresse}
                  onChange={handleChange}
                />
                {errors.adresse && <div className="invalid-feedback">{errors.adresse[0]}</div>}
              </div>
              <div className="col-md-6">
                <label htmlFor="telephone" className="form-label text-gray-950">Téléphone</label>
                <input
                  type="text"
                  className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                  id="telephone"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleChange}
                />
                {errors.telephone && <div className="invalid-feedback">{errors.telephone[0]}</div>}
              </div>
              <div className="col-md-12">
                <label htmlFor="description" className="form-label text-gray-950">Description</label>
                <textarea
                  className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
                {errors.description && <div className="invalid-feedback">{errors.description[0]}</div>}
              </div>

              {/* Nouveau champ pour Pays */}
              <div className="col-md-6">
                <label htmlFor="pays" className="form-label text-gray-950">Pays</label>
                <input
                  type="text"
                  className={`form-control ${errors.pays ? 'is-invalid' : ''}`}
                  id="pays"
                  name="pays"
                  value={formData.pays}
                  onChange={handleChange}
                />
                {errors.pays && <div className="invalid-feedback">{errors.pays[0]}</div>}
              </div>

              {/* Nouveau champ pour Ville */}
              <div className="col-md-6">
                <label htmlFor="ville" className="form-label text-gray-950">Ville</label>
                <input
                  type="text"
                  className={`form-control ${errors.ville ? 'is-invalid' : ''}`}
                  id="ville"
                  name="ville"
                  value={formData.ville}
                  onChange={handleChange}
                />
                {errors.ville && <div className="invalid-feedback">{errors.ville[0]}</div>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {isLoading ? (
              <button
                type="button"
                disabled
                className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-2 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Enregistrement...
              </button>
            ) : (
              <button
                type="submit"
                className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                Enregistrer
              </button>
            )}
            <button
              type="button"
              id="close-add-suppliers-modal"
              data-bs-dismiss="modal"
              className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
            >
              Fermer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
