import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';

import { APP_API_URL } from "../../utils";
import { useDataContext } from "../../context/DataContext";
import MainLayout from "../../layout/main-layout";
import UIDropdown from "../../components/ui/ui-dropdown";
import Loader from "../../components/loader";

export default function AddProduct() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { categories, fetchProducts } = useDataContext([]);
  const initialFormData = {
    name: '',                          // Requis, chaîne de caractères, max 255
    description: '',                   // Requis, chaîne de caractères
    category_id: '',                 // Requis, entier, doit exister dans categories
    sub_category_id: '',             // Requis, entier, doit exister dans categories
    reference: '',                   // Optionnel, chaîne de caractères
    image: [],                         // Optionnel, tableau d'images (max 4)
    brand: '',                       // Optionnel, chaîne de caractères
    unit_id: '',                     // Requis, doit exister dans units
    is_active: 1,                   // Optionnel, booléen
    format: '',                      // Optionnel, chaîne de caractères
    user_id: user?.id,                     // Optionnel, doit exister dans users
    marque_id: '',                   // Optionnel, doit exister dans marques
  };
  const [formData, setFormData] = useState(initialFormData);
  const [brands, setBrands] = useState([]);
  const [formats, setFormats] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);

  // États pour les dropdowns
  const [selectedCategory, setSelectedCategory] = useState({ label: "Sélectionner une catégorie", value: "" });
  const [selectedSubCategory, setSelectedSubCategory] = useState({ label: "Sélectionner une sous-catégorie", value: "" });
  const [selectedBrand, setSelectedBrand] = useState({ label: "Sélectionner une marque", value: "" });
  const [selectedFormat, setSelectedFormat] = useState({ label: "Sélectionner un format", value: "" });

  useEffect(() => {
    const fetchBrands = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${APP_API_URL}/marques`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setBrands(response.data.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des marques :', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchFormats = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${APP_API_URL}/formats`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setFormats(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des formats :', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
    fetchFormats();
  }, []);

  useEffect(() => {
    if (categories && categories.length > 0) {
      const filteredCategories = categories.filter(category => category.type_categorie === null);
      setCategoriesData(filteredCategories);
    }

    if (categories && categories.length > 0) {
      const filteredCategories = categories.filter(category => category.type_categorie !== null);
      setSubCategoriesData(filteredCategories);
    }
  }, [categories]);

  // Fonction pour vérifier si la référence existe déjà
  const checkReferenceExists = async (reference) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/products/check-reference/${reference}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.exists) {
        setErrors(prevErrors => ({
          ...prevErrors,
          reference: "Cette référence existe déjà"
        }));
        return false;
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          reference: undefined // Effacer l'erreur si la référence est unique
        }));
        return true;
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de la référence", error);
      return false;
    }
  };

  // Fonction pour générer une référence unique
  const generateReference = () => {
    const generatedReference = `ASB-${Math.random().toString(36).substring(2, 8).toUpperCase()}`;
    setFormData({ ...formData, reference: generatedReference });

    // Réinitialiser l'erreur de la référence lorsqu'elle est générée
    setErrors((prevErrors) => ({
      ...prevErrors,
      reference: undefined, // Effacer l'erreur de la référence
    }));

    // validateForm(); // Valider le formulaire après la génération de la référence
  };

  // Appel à chaque modification de la référence
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Vérifier la référence si elle a été modifiée
    if (name === 'reference') {
      checkReferenceExists(value);
    }

    // validateForm();
  };

  const handleCategorySelect = (option) => {
    // Mettez à jour la catégorie sélectionnée
    setSelectedCategory(option);

    setFormData(prevState => ({
      ...prevState,
      category_id: option.value
    }));

    // Vérifiez si l'erreur pour le champ catégorie doit être effacée
    if (option) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        category_id: undefined // Supprime l'erreur si une option est sélectionnée
      }));
    }

    // Vérifiez si la catégorie est égale à 17
    if (option.value === 17) {
      // Si oui, définissez unitId à 20
      setFormData(prevState => ({
        ...prevState,
        unit_id: 6
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        unit_id: 5
      }));
    }
  };

  const handleSubCategorySelect = (option) => {
    setSelectedSubCategory(option);
    setFormData(prevState => ({
      ...prevState,
      sub_category_id: option.value
    }));
  };

  const handleBrandSelect = (option) => {
    setSelectedBrand(option);
    setFormData(prevState => ({
      ...prevState,
      marque_id: option.value
    }));
  };

  const handleFormatSelect = (option) => {
    setSelectedFormat(option);
    setFormData(prevState => ({
      ...prevState,
      format: option.label
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + formData.image.length > 4) {
      alert("Vous ne pouvez télécharger que 4 images au total.");
      return;
    }

    const newImages = files.map(file => ({
      file: file,  // Keep the file reference
      url: URL.createObjectURL(file) // Preview URL
    }));

    setFormData(prevState => ({
      ...prevState,
      image: [...prevState.image, ...newImages]
    }));
  };

  const removeImage = (index) => {
    const updatedImages = formData.image.filter((_, i) => i !== index);
    setFormData(prevState => ({
      ...prevState,
      image: updatedImages
    }));
  };

  const validateForm = () => {
    const requiredFields = ['reference', 'category_id'];
    let errors = {};

    requiredFields.forEach((field) => {
      if (!formData[field] || formData[field] === "") {
        errors[field] = "Ce champ est obligatoire";
      }
    });

    // Vérifie si la description dépasse 60 caractères
    if (formData.description.length > 60) {
      errors.description = "La description ne doit pas dépasser 60 caractères";
    }

    // Vérifiez si une catégorie est sélectionnée
    if (!selectedCategory) {
      errors.category_id = "Veuillez sélectionner une catégorie";
    }

    setErrors(errors);
    console.log(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const formDataToSend = new FormData();

      Object.keys(formData).forEach(key => {
        if (key === "image") {
          formData.image.forEach(i => {
            formDataToSend.append('image[]', i.file);
          });
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });

      const response = await axios.post(`${APP_API_URL}/products`, formDataToSend, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 201) {
        resetForm();

        Swal.fire({
          title: 'Produit ajouté !',
          text: 'Le produit a été ajouté avec succès.',
          icon: 'success',
        });
        fetchProducts();
      }

    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors);
      } else {
        console.error('Erreur lors de l\'envoi du formulaire', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setSelectedCategory({ label: "Sélectionner une catégorie", value: "" });
    setSelectedSubCategory({ label: "Sélectionner une sous-catégorie", value: "" });
    setSelectedBrand({ label: "Sélectionner une marque", value: "" });
    setErrors({});

    navigate('/articles');
  };

  if (isLoading) {
    <Loader />
  }

  return (
    <MainLayout>
      <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center mb-16'>
        <div className="pagetitle">
          <h1>Nouveau produit</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Créer un nouveau produit</li>
            </ol>
          </nav>
        </div>

        <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
          <div>
            <Link to="/articles" id="returnToAllProduct" className="inline-flex items-center rounded-md bg-darkgreen px-2.5 py-2 text-sm font-semibold text-white hover:bg-lightgreen transition-all duration-300">
              <i className="bi bi-arrow-left me-1" /> Retour au produit
            </Link>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-body pt-4">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="inline-flex items-center text-lg font-semibold text-gray-900 py-3 accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="true"
                    aria-controls="flush-collapseOne"
                  >
                    <div>
                      <i className="bi bi-info-circle me-1 text-lightgreen" /> Informations sur le produit
                    </div>
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 col-12 mb-3">
                        <label htmlFor="inputReference" className="form-label">Référence</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="reference"
                            className={`form-control ${errors.reference ? 'is-invalid' : ''}`}
                            value={formData.reference}
                            onChange={handleChange}
                          />
                          <button
                            className={`btn ${errors.reference ? 'btn-outline-danger' : 'btn-outline-secondary'}`}
                            type="button"
                            onClick={generateReference}
                          >
                            Générer
                          </button>
                        </div>
                        {errors.reference && <span className="mt-[.25rem] text-[.875rem] text-[#dc3545]">{errors.reference}</span>}
                      </div>

                      <div className="col-lg-4 col-sm-6 col-12 mb-3">
                        <label htmlFor="inputName5" className="form-label">Nom du produit</label>
                        <input
                          type="text"
                          name="name"
                          className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                      </div>

                      <div className="col-lg-4 col-sm-6 col-12 mb-3">
                        <label htmlFor="inputFormat" className="form-label">Format</label>
                        <UIDropdown
                          options={formats.map(format => ({
                            label: format.name,
                            value: format.id
                          }))}
                          selectedOption={selectedFormat}
                          onSelect={handleFormatSelect}
                          enableSearch={true}
                        />
                      </div>

                      <div className="col-lg-4 col-sm-6 col-12 mb-3">
                        <label htmlFor="category" className="form-label">Catégorie</label>
                        <UIDropdown
                          options={categoriesData.map(category => ({
                            label: category.nom,
                            value: category.id
                          }))}
                          selectedOption={selectedCategory}
                          onSelect={handleCategorySelect}
                          enableSearch={true}
                        />
                        {errors.category_id && <span className="mt-[.25rem] text-[.875rem] text-[#dc3545]">{errors.category_id}</span>}
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12 mb-3">
                        <label htmlFor="subCategory" className="form-label">Sous-catégorie</label>
                        <UIDropdown
                          options={subCategoriesData.filter(
                            subcategory =>
                              subcategory.type_categorie &&
                              subcategory.type_categorie.id === selectedCategory.value
                          ).map(subcategory => ({
                            label: subcategory.nom,
                            value: subcategory.id
                          }))}
                          selectedOption={selectedSubCategory}
                          onSelect={handleSubCategorySelect}
                          enableSearch={true}
                        />
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12 mb-3">
                        <label htmlFor="marque" className="form-label">Marque</label>
                        <UIDropdown
                          options={brands.filter(
                            brand =>
                              brand.category_id &&
                              brand.category_id === selectedCategory.value
                          ).map(brand => ({
                            label: brand.nom,
                            value: brand.id
                          }))}
                          selectedOption={selectedBrand}
                          onSelect={handleBrandSelect}
                          enableSearch={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks summer-description-box transfer mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                          className={`form-control h-100 ${errors.description ? 'is-invalid' : ''}`}
                          rows={5}
                          value={formData.description}
                          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        />
                        <p className="mt-1 text-sm">Maximum 60 caractères</p>
                        {errors.description && <span className="mt-[.25rem] text-[.875rem] text-[#dc3545]">{errors.description}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Section d'ajout d'image */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="inline-flex items-center text-lg font-semibold text-gray-900 py-3 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    <div>
                      <i className="bi bi-images me-1 text-lightgreen" /> Images
                    </div>
                  </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <div className="col-lg-12">
                      <div className="flex">
                        <div className="group hover:bg-gray-100 relative mb-[25px]">
                          <div className="image-upload mb-0 w-[137px] h-[123px] border-[1px] border-dashed flex justify-center items-center rounded-lg">
                            <input type="file" multiple accept="image/*" onChange={handleImageChange} className="w-full opacity-0 h-[100px] relative z-[9] cursor-pointer" />
                            <div className="flex flex-col justify-center items-center text-center w-full h-full rounded-md absolute top-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-plus-circle plus-down-add me-0"
                              >
                                <circle cx={12} cy={12} r={10} />
                                <line x1={12} y1={8} x2={12} y2={16} />
                                <line x1={8} y1={12} x2={16} y2={12} />
                              </svg>
                              <h4 className="text-sm">Ajouter des images</h4>
                            </div>
                          </div>
                        </div>
                        {formData.image.map((i, index) => (
                          <div key={'product-image-' + index} className="phone-img relative w-[137px] h-[123px] rounded-lg border border-opacity-30 text-center ml-5">
                            <img src={i.url} alt="image" />
                            <button onClick={() => removeImage(index)} className="absolute right-1 top-1 bg-red-500 rounded w-[16px] h-[16px] text-white flex items-center justify-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x x-square-add remove-product"
                              >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end mt-4 gap-x-4">
              {isLoading ? (
                <button
                  type="button"
                  disabled
                  className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-2 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Enregistrement du produit...
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                >
                  Enregistrer le produit
                </button>
              )}
              <button type="button" onClick={resetForm} className="inline-flex items-center rounded-md bg-gray-500 px-2.5 py-2 text-sm font-semibold text-white hover:bg-gray-800 transition-all duration-300">
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
