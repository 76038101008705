import React, { useState } from "react";
import axios from 'axios';
import { APP_API_URL } from "../../utils";
import Swal from "sweetalert2";

export default function Add({ refreshData, productId, reference }) {
  const initialFormData = {
    product_id: productId, // Requis, ID du produit, doit exister dans la table des produits
    name: '', // Requis, nom de la variante (chaîne de caractères)
    quantity: 0, // Requis, quantité de la variante
    sku: '', // Requis, SKU (référence) de la variante
    price: 0.0, // Requis, prix de la variante
    images: [], // Optionnel, tableau d'images (jusqu'à 4)
    color: '', // Optionnel, couleur de la variante (chaîne de caractères)
    size: '', // Optionnel, taille de la variante (chaîne de caractères)
    is_active: 1, // Optionnel, statut actif de la variante (booléen)
    description: '', // Optionnel, description de la variante (chaîne de caractères)
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 4) {
      alert('Vous ne pouvez sélectionner que 4 images au maximum.');
      return;
    }

    setFormData({
      ...formData,
      images: files // Mettez à jour les fichiers dans le formData
    });

    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const checkSKUExists = async (sku) => {
    try {
      const response = await axios.get(`${APP_API_URL}/variants/check-sku/${sku}`);
      return response.data.exists;
    } catch (error) {
      console.error("Erreur lors de la vérification du SKU", error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const skuExists = await checkSKUExists(formData.sku);
    if (skuExists) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'La référence existe déjà. Veuillez en choisir une autre.',
      });
      setLoading(false);
      return;
    }

    const submitData = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'images') {
        value.forEach((file, i) => submitData.append(`images[${i}]`, file));
      } else {
        submitData.append(key, value);
      }
    });

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${APP_API_URL}/variants`, submitData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: response.data.message || 'Variante créée avec succès !',
          timer: 2000,
          showConfirmButton: false
        });

        const closeModal = document.getElementById('closeModalAddVariant');
        setTimeout(() => {
          setFormData(initialFormData);
          closeModal.click();
          refreshData();
        }, 1000);
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors);
        Swal.fire({
          icon: 'error',
          title: 'Erreur de validation',
          text: 'Veuillez corriger les erreurs dans le formulaire.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors de l\'envoi du formulaire. Veuillez réessayer.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="addVariantModal"
      tabIndex={-1}
      style={{ display: "none", whiteSpace: 'normal' }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <form className="modal-content" onSubmit={handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title text-lg font-bold text-gray-600">Ajouter une variante</h5>
          </div>
          <div className="modal-body">
            <div className="row g-3">
              <div className="col-md-12">
                <label htmlFor="name" className="form-label text-gray-950">Nom de la variante</label>
                <input
                  type="text"
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <div className="invalid-feedback">{errors.name[0]}</div>}
              </div>
              <div className="col-md-12">
                <label htmlFor="inputReference" className="form-label">Référence</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={formData.sku}
                    onChange={(e) => setFormData({ ...formData, sku: e.target.value })}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => {
                      const generatedReference = `${reference}-V-${Math.random().toString(36).substring(2, 8).toUpperCase()}`;
                      setFormData({ ...formData, sku: generatedReference });
                    }}
                  >
                    Générer
                  </button>
                </div>
                {errors.sku && <div className="invalid-feedback">{errors.sku[0]}</div>}
              </div>
              <div className="col-md-6 hidden">
                <label htmlFor="price" className="form-label text-gray-950">Prix unitaire</label>
                <input
                  type="number"
                  className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                  id="price"
                  name="price"
                  min={0}
                  value={formData.price}
                  onChange={handleChange}
                />
                {errors.price && <div className="invalid-feedback">{errors.price[0]}</div>}
              </div>
              <div className="col-md-6 hidden">
                <label htmlFor="quantity" className="form-label text-gray-950">Quantité</label>
                <input
                  type="number"
                  className={`form-control ${errors.quantity ? 'is-invalid' : ''}`}
                  id="quantity"
                  name="quantity"
                  min={0}
                  value={formData.quantity}
                  onChange={handleChange}
                />
                {errors.quantity && <div className="invalid-feedback">{errors.quantity[0]}</div>}
              </div>
              <div className="col-md-12">
                <label htmlFor="images" className="form-label">Images (max 4)</label>
                <input type="file" className="form-control" id="images" name="images" accept="image/*" multiple onChange={handleImageChange} />
                {errors.images && <div className="invalid-feedback">{errors.images[0]}</div>}

                {/* Prévisualisation des images */}
                <div className="image-previews flex flex-row gap-x-2 mt-2">
                  {imagePreviews.map((preview, index) => (
                    <img key={index} src={preview} alt={`Preview ${index}`} className="img-thumbnail" style={{ maxWidth: '100px' }} />
                  ))}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-blocks summer-description-box transfer mb-3">
                  <label htmlFor="description" className="form-label">Description</label>
                  <textarea
                    className="form-control h-100"
                    rows={5}
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  />
                  <p className="mt-1 text-sm">Maximum 60 caractères</p>
                </div>
              </div>
              <div className="col-md-12">
                <input
                  type="checkbox"
                  className={`form-check-input ${errors.is_active ? 'is-invalid' : ''}`}
                  id="is_active"
                  name="is_active"
                  checked={formData.is_active}
                  onChange={handleChange}
                />
                <label htmlFor="is_active" className="form-label ml-2 text-gray-950">Statut</label> <span className="text-xs block text-gray-900">(Décochez cette case si vous souhaitez que cette unité soit inactive.)</span>
                {errors.is_active && <div className="invalid-feedback">{errors.is_active[0]}</div>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {isLoading ? (
              <button
                type="button"
                disabled
                className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-2 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Enregistrement...
              </button>
            ) : (
              <button
                type="submit"
                className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                Enregistrer
              </button>
            )}
            <button
              type="button"
              id="closeModalAddVariant"
              data-bs-dismiss="modal"
              className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
            >
              Fermer
            </button>
          </div>
        </form>
      </div>
    </div>
  )
};
