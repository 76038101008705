import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, login } from '../../actions/auth-actions';
import Loader from '../../components/loader';

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const loginError = useSelector((state) => state.auth.loginError);
  const dispatch = useDispatch();
  const { email, password } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleLogin = async () => {
    try {
      // Dispatch login action
      await dispatch(login({ email, password }));

      // Fetch user information after login
      const token = localStorage.getItem('token');
      await dispatch(fetchUser(token));

    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  // Handle redirection based on user role after fetching user data
  useEffect(() => {
    const handleRedirection = () => {
      // if (user && user.personnels.length > 0) {
      //   const { poste_id } = user.personnels[0];

      //   if (poste_id === 1) {
      //     window.location.href = '/commandes'; // Redirect to commandes
      //   } else if (poste_id === 2) {
      //     window.location.href = '/'; // Redirect to homepage
      //   } else if (poste_id === 4) {
      //     window.location.href = '/'; // Redirect to homepage
      //   }
      // }

      // if (user && user.role === 'Administrateur') {
      window.location.href = '/'; // Redirect admin to homepage
      // }
    };

    // If user data is available, trigger redirection
    if (user) {
      handleRedirection();
    }
  }, [user]); // Redirection happens when user data is updated

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Perform login
    await handleLogin();

    setIsLoading(false); // Stop the loader after login and user fetch
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <button className="logo d-flex align-items-center w-auto">
                    <img src="/assets/img/logo.png" alt="" />
                  </button>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4 text-green-600">Connexion</h5>
                      <p className="text-center small">
                        Entrez votre identifiant et mot de passe pour vous connecter
                      </p>
                      {loginError && <p className="text-center small py-2 text-red-600"> {loginError} </p>}
                    </div>
                    <form className="row g-3 needs-validation" noValidate="" onSubmit={onSubmit}>
                      <div className="col-12">
                        <label htmlFor="yourEmail" className="form-label">Identifiant</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          id="yourEmail"
                          required=""
                          value={email}
                          onChange={onChange}
                        />
                        <div className="invalid-feedback">Veuillez entrer votre email !</div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">Mot de passe</label>
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control"
                            id="yourPassword"
                            required=""
                            value={password}
                            onChange={onChange}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? "Masquer" : "Voir"}
                          </button>
                        </div>
                        <div className="invalid-feedback">Veuillez entrer votre mot de passe !</div>
                      </div>
                      <div className="col-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="remember"
                            defaultValue="true"
                            id="rememberMe"
                          />
                          <label className="form-check-label" htmlFor="rememberMe">
                            Se souvenir de moi
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="inline-flex w-full justify-center items-center rounded-md bg-green-600 px-3 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                          type="submit"
                        >
                          Connexion
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default LoginPage;
