import React from 'react';
import MagasinierHeader from '../components/magasinier-header';

const MagasinierLayout = ({ children }) => {
  return (
    <div>
      <MagasinierHeader />

      <section className="px-3 mt-20">
        {children}
      </section>
    </div>
  );
};

export default MagasinierLayout;
