import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import { logout } from '../../actions/auth-actions';
import OrderLayout from "../../layout/order-layout";

export default function GuestProfile (){
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  const handleLogout = () => {
    dispatch(logout());
  };

  console.log(user)

  return (
    <OrderLayout>
      <div className='mb-16'>
        <div className="pagetitle">
          <h1>Profil</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Profil d'Utilisateur</li>
            </ol>
          </nav>
        </div>
      </div>

      <section className="section profile">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="card">
              <div className="card-body pt-3">
                {/* Onglets avec bordure */}
                <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-overview"
                      aria-selected="false"
                      role="tab"
                      tabIndex={-1}
                    >
                      Aperçu
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-edit"
                      aria-selected="true"
                      role="tab"
                    >
                      Modifier le profil
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-settings"
                      aria-selected="false"
                      role="tab"
                      tabIndex={-1}
                    >
                      Paramètres
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-change-password"
                      aria-selected="false"
                      role="tab"
                      tabIndex={-1}
                    >
                      Changer le mot de passe
                    </button>
                  </li>
                </ul>
                <div className="tab-content pt-2">
                  <div
                    className="tab-pane fade profile-overview active show"
                    id="profile-overview"
                    role="tabpanel"
                  >
                    <h5 className="card-title">Détails du profil</h5>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label ">Nom complet</div>
                      <div className="col-lg-9 col-md-8">{user.name}</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Entreprise</div>
                      <div className="col-lg-9 col-md-8">
                        Lueilwitz, Wisoky et Leuschke
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Emploi</div>
                      <div className="col-lg-9 col-md-8">Web Designer</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Pays</div>
                      <div className="col-lg-9 col-md-8">USA</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Adresse</div>
                      <div className="col-lg-9 col-md-8">
                        A108 Adam Street, New York, NY 535022
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Téléphone</div>
                      <div className="col-lg-9 col-md-8">{user.personnels[0].telephone}</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Email</div>
                      <div className="col-lg-9 col-md-8">{user.email}</div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade profile-edit pt-3"
                    id="profile-edit"
                    role="tabpanel"
                  >
                    {/* Formulaire de modification du profil */}
                    <form>
                      <div className="row mb-3">
                        <label
                          htmlFor="profileImage"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Image de profil
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <img src="assets/img/profile-img.jpg" alt="Profil" />
                          <div className="pt-2">
                            <a
                              href="#"
                              className="btn btn-primary btn-sm"
                              title="Télécharger une nouvelle image de profil"
                            >
                              <i className="bi bi-upload" />
                            </a>
                            <a
                              href="#"
                              className="btn btn-danger btn-sm"
                              title="Supprimer mon image de profil"
                            >
                              <i className="bi bi-trash" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="fullName"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Nom complet
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="fullName"
                            type="text"
                            className="form-control"
                            id="fullName"
                            defaultValue="Kevin Anderson"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="about"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          À propos
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="about"
                            className="form-control"
                            id="about"
                            style={{ height: 100 }}
                            defaultValue={
                              "Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde."
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="company"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Entreprise
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="company"
                            type="text"
                            className="form-control"
                            id="company"
                            defaultValue="Lueilwitz, Wisoky et Leuschke"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="Job"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Emploi
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="job"
                            type="text"
                            className="form-control"
                            id="Job"
                            defaultValue="Web Designer"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="Country"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Pays
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="country"
                            type="text"
                            className="form-control"
                            id="Country"
                            defaultValue="USA"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="Address"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Adresse
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="address"
                            type="text"
                            className="form-control"
                            id="Address"
                            defaultValue="A108 Adam Street, New York, NY 535022"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="Phone"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Téléphone
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="phone"
                            type="text"
                            className="form-control"
                            id="Phone"
                            defaultValue="(436) 486-3538 x29071"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="Email"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            id="Email"
                            defaultValue="k.anderson@example.com"
                          />
                        </div>
                      </div>

                      <div className="text-center">
                        <button type="submit" className="btn btn-primary">
                          Sauvegarder les modifications
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade profile-settings pt-3"
                    id="profile-settings"
                    role="tabpanel"
                  >
                    <h5 className="card-title">Paramètres</h5>
                    <form>
                      <div className="row mb-3">
                        <label
                          htmlFor="notifications"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Notifications
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="notifications"
                              defaultChecked
                            />
                            <label className="form-check-label" htmlFor="notifications">
                              Activer les notifications
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="privacy"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Confidentialité
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <select id="privacy" className="form-select">
                            <option value="public">Public</option>
                            <option value="private">Privé</option>
                            <option value="custom">Personnalisé</option>
                          </select>
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary">
                          Sauvegarder les paramètres
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade profile-change-password pt-3"
                    id="profile-change-password"
                    role="tabpanel"
                  >
                    <h5 className="card-title">Changer le mot de passe</h5>
                    <form>
                      <div className="row mb-3">
                        <label
                          htmlFor="currentPassword"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Mot de passe actuel
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="currentPassword"
                            type="password"
                            className="form-control"
                            id="currentPassword"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="newPassword"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Nouveau mot de passe
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="newPassword"
                            type="password"
                            className="form-control"
                            id="newPassword"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="confirmPassword"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Confirmer le nouveau mot de passe
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="confirmPassword"
                            type="password"
                            className="form-control"
                            id="confirmPassword"
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary">
                          Changer le mot de passe
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </OrderLayout>
  );
}