import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SIDEBAR_LINKS } from '../utils';


const Sidebar = () => {
  const currentPage = window.location.pathname;
  const user = useSelector((state) => state.auth.user);

  // Fonction pour filtrer les liens du menu en fonction du rôle et du poste
  const getSidebarLinks = (posteId, role) => {
    // Si l'utilisateur est un administrateur, on retourne tous les liens
    if (role === 'Administrateur') {
      return SIDEBAR_LINKS;
    }

    let filteredLinks = SIDEBAR_LINKS;

    // Conditions basées sur les rôles
    switch (posteId) {
      case 1: // Vendeur
        filteredLinks = filteredLinks.filter(link =>
          link.category !== "Gestion des commandes" &&
          link.category !== "Gestion des produits" &&
          link.category !== "Analytique" &&
          link.category !== "Ressources humaines" &&
          link.category !== "Administration"
        );
        break;

      case 2: // Caissier
        filteredLinks = filteredLinks.filter(link =>
          link.category !== "Gestion des produits" &&
          link.category !== "Ressources humaines" &&
          link.category !== "Administration"
        );
        break;

      case 4: // Magasinier
        filteredLinks = filteredLinks.filter(link =>
          link.category !== "Gestion des ventes" &&
          link.category !== "Administration"
        );
        break;

      case 6: // Responsable des achats
        filteredLinks = filteredLinks.filter(link =>
          link.category !== "Gestion des produits" &&
          link.category !== "Gestion des ventes"
        );
        break;

      // Autres postes peuvent être ajoutés ici avec des conditions supplémentaires
      default:
        break;
    }

    return filteredLinks;
  };

  // Obtenez les liens filtrés
  const sidebarLinksToDisplay = getSidebarLinks(user?.personnels[0]?.poste_id, user?.role);

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {Object.entries(
          sidebarLinksToDisplay.reduce((acc, link) => {
            if (!acc[link.category]) acc[link.category] = [];
            acc[link.category].push(link);
            return acc;
          }, {})
        ).map(([category, links]) => (
          <div key={category} className="border-b pb-2 mb-4">
            <li className="text-sm text-gray-500 font-semibold mb-2">{category}</li>
            {links.map(link => (
              <li key={link.id} className="nav-item">
                <Link className={`nav-link ${currentPage === link.url ? '' : 'collapsed'}`} to={link.url}>
                  <i className={link.icon} />
                  <span>{link.text}</span>
                </Link>
              </li>
            ))}
          </div>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
