import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import { APP_API_URL } from "../../utils";
import Loader from "../../components/loader";
import FactureComponent from "../../components/facture";
import ComptableHeader from "../../components/comptable-header";

export default function ComptableUpdateInvoice() {
  const { reference } = useParams();
  const user = useSelector((state) => state.auth.user);
  const [invoiceData, setInvoiceData] = useState({});
  const [isLoading, setLoading] = useState(false);

  // Fonction pour récupérer les données de la commande
  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/commandes/reference/${reference}`, {
        params: {
          user_id: user.id, // Passer l'ID de l'utilisateur comme paramètre
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setInvoiceData(response.data.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes :', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${APP_API_URL}/validate/commandes`, { commande_id: invoiceData.id, validated_by_id: user.id }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        fetchData();

        setTimeout(() => {
          // Afficher une alerte de succès avec SweetAlert
          Swal.fire({
            icon: 'success',
            title: 'Commande validée !',
            text: response.data.message,
            showConfirmButton: false,
            timer: 1500
          });
        }, 1000);
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur de validation',
          text: 'Des erreurs ont été détectées dans le formulaire.',
          footer: 'Vérifiez les champs et réessayez.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: error.error || 'Rupture de stock sur l\'un des produits',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    window.print(); // Cette fonction utilise la fonctionnalité native du navigateur pour imprimer
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ComptableHeader />
      <div className="mx-auto w-full max-w-6xl bg-white p-4 sm:p-6 lg:p-8">
        <div className="printable w-full">

          <FactureComponent invoiceData={invoiceData} isCopy={false} />
          <FactureComponent invoiceData={invoiceData} />

          <div className="modal-footer py-4 space-x-4 no-print">
            <button
              type="button"
              onClick={handlePrint} // Appel de la fonction handlePrint pour imprimer
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
            >
              Imprimer
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
