import React from 'react';
import AdminHeader from '../components/admin-header';

const AdminOrderLayout = ({ children }) => {
  return (
    <div>
      <AdminHeader />

      <section className="px-3 mt-20">
        {children}
      </section>
    </div>
  );
};

export default AdminOrderLayout;
