import React, { useEffect, useState } from "react";
import axios from 'axios';
import { APP_API_URL } from "../../utils";
import { useDataContext } from "../../context/DataContext";
import UIDropdown from "../../components/ui/ui-dropdown";
import Loader from "../../components/loader";
import Swal from "sweetalert2";

export default function Update({ product }) {
  const { categories } = useDataContext([]);
  const initialFormData = {
    name: product?.name || '',                          // Requis, chaîne de caractères, max 255
    description: product?.description || '',            // Requis, chaîne de caractères
    category_id: product?.category_id || '',          // Requis, entier, doit exister dans categories
    sub_category_id: product?.sub_category_id || '',  // Requis, entier, doit exister dans categories
    reference: product?.reference || '',              // Optionnel, chaîne de caractères
    unit_id: product?.unit_id || '',                  // Requis, doit exister dans units
    is_active: product?.is_active || '',              // Optionnel, booléen
    format: product?.format || '',                    // Optionnel, chaîne de caractères
    user_id: product?.user_id || '',                  // Optionnel, doit exister dans users
    marque_id: product?.marque_id || '',                  // Optionnel, doit exister dans users
  };
  const [formData, setFormData] = useState(initialFormData);
  const [brands, setBrands] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [units, setUnits] = useState([]);
  const [formats, setFormats] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // États pour les dropdowns
  const [selectedCategory, setSelectedCategory] = useState({ label: "Sélectionner une catégorie", value: formData.category_id });
  const [selectedSubCategory, setSelectedSubCategory] = useState({ label: "Sélectionner une sous-catégorie", value: formData.sub_category_id });
  const [selectedBrand, setSelectedBrand] = useState({ label: "Sélectionner une marque", value: formData.marque_id });
  const [selectedUnit, setSelectedUnit] = useState({ label: "Sélectionner une unité", value: formData.unit_id });
  const [selectedFormat, setSelectedFormat] = useState({ label: "Sélectionner un format", value: formData.format });

  useEffect(() => {
    const fetchBrands = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${APP_API_URL}/marques`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setBrands(response.data.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des marques :', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUnits = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${APP_API_URL}/units`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUnits(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des unités :', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchFormats = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${APP_API_URL}/formats`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setFormats(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des formats :', error);
      } finally {
        setLoading(false);
      }
    };


    fetchBrands();
    fetchUnits();
    fetchFormats();
  }, []);

  useEffect(() => {
    if (categories && categories.length > 0) {
      const filteredCategories = categories.filter(category => category.type_categorie === null);
      setCategoriesData(filteredCategories);
    }

    if (categories && categories.length > 0) {
      const filteredCategories = categories.filter(category => category.type_categorie !== null);
      setSubCategoriesData(filteredCategories);
    }
  }, [categories]);

  const handleCategorySelect = (option) => {
    setSelectedCategory(option);
    setFormData(prevState => ({
      ...prevState,
      category_id: option.value
    }));
  };

  const handleSubCategorySelect = (option) => {
    setSelectedSubCategory(option);
    setFormData(prevState => ({
      ...prevState,
      sub_category_id: option.value
    }));
  };

  const handleBrandSelect = (option) => {
    setSelectedBrand(option);
    setFormData(prevState => ({
      ...prevState,
      marque_id: option.value
    }));
  };

  const handleUnitSelect = (option) => {
    setSelectedUnit(option);
    setFormData(prevState => ({
      ...prevState,
      unit_id: option.value
    }));
  };

  const handleFormatSelect = (option) => {
    setSelectedFormat(option);
    setFormData(prevState => ({
      ...prevState,
      format: option.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      console.log('aaaaaa',formData);

      const response = await axios.post(`${APP_API_URL}/update/product/${product.id}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      console.log(response.data);
      if (response.status === 200) {
        // Succès
        Swal.fire({
          icon: 'success',
          title: 'Produit modifié avec succès',
          text: 'Les modifications ont été enregistrées dans la base de données.',
          confirmButtonText: 'OK'
        });

        // resetForm();
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Swal.fire({
          icon: 'warning',
          title: 'Validation échouée',
          text: error.response.data.errors || 'Veuillez vérifier les informations saisies et réessayer.',
          confirmButtonText: 'OK'
        });
        console.error('Erreur lors de la mise à jour du produit', error);
      } else {
        console.error('Erreur lors de la mise à jour du produit', error);

        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la mise à jour. Veuillez réessayer.',
          confirmButtonText: 'OK'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <form>
      <div className="row">
        <div className="col-lg-4 col-sm-6 col-12 mb-3">
          <label htmlFor="inputName5" className="form-label">Nom du produit</label>
          <input
            type="text"
            className="form-control"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </div>
        <div className="col-lg-4 col-sm-6 col-12 mb-3">
          <label htmlFor="inputFormat" className="form-label">Format</label>
          <UIDropdown
            options={formats.map(item => ({
              label: item.name,
              value: item.name
            }))}
            selectedOption={selectedFormat}
            onSelect={handleFormatSelect}
            enableSearch={true}
          />
        </div>
        <div className="col-lg-4 col-sm-6 col-12 mb-3">
          <label htmlFor="inputReference" className="form-label">Référence</label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              value={formData.reference}
              onChange={(e) => setFormData({ ...formData, reference: e.target.value })}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => {
                const generatedReference = `ASB-${Math.random().toString(36).substring(2, 8).toUpperCase()}`;
                setFormData({ ...formData, reference: generatedReference });
              }}
            >
              Générer
            </button>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mb-3">
          <label htmlFor="category" className="form-label">Catégorie</label>
          <UIDropdown
            options={categoriesData.map(category => ({
              label: category.nom,
              value: category.id
            }))}
            selectedOption={selectedCategory}
            onSelect={handleCategorySelect}
            enableSearch={true}
          />
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mb-3">
          <label htmlFor="subCategory" className="form-label">Sous-catégorie</label>
          <UIDropdown
            options={subCategoriesData.map(subcategory => ({
              label: subcategory.nom,
              value: subcategory.id
            }))}
            selectedOption={selectedSubCategory}
            onSelect={handleSubCategorySelect}
            enableSearch={true}
          />
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mb-3">
          <label htmlFor="marque" className="form-label">Marque</label>
          <UIDropdown
            options={brands.map(brand => ({
              label: brand.nom,
              value: brand.id
            }))}
            selectedOption={selectedBrand}
            onSelect={handleBrandSelect}
            enableSearch={true}
          />
        </div>
        <div className="col-lg-3 col-sm-6 col-12 mb-3">
          <label htmlFor="unit" className="form-label">Unité</label>
          <UIDropdown
            options={units.map(unit => ({
              label: unit.name,
              value: unit.id
            }))}
            selectedOption={selectedUnit}
            onSelect={handleUnitSelect}
            enableSearch={true}
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="input-blocks summer-description-box transfer mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            className="form-control h-100"
            rows={5}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
          <p className="mt-1 text-sm">Maximum 60 caractères</p>
        </div>
      </div>
      <div className="flex flex-row justify-end mt-4 gap-x-4">
        <div className="modal-footer">
          {isLoading ? (
            <button
              type="button"
              disabled
              className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
            >
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-4 h-4 me-2 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Modification en cours...
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
            >
              Modifier
            </button>
          )}
        </div>
      </div>
    </form>
  )
};