import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState({ label: 'Choisissez un client', value: null, customer: [] });
  const [remise, setRemise] = useState(0);
  const [fraisLivraison, setFraisLivraison] = useState(0);

  return (
    <CartContext.Provider value={{
      cart,
      setCart,
      total,
      setTotal,
      subTotal,
      setSubTotal,
      selectedCustomer,
      setSelectedCustomer,
      remise,
      setRemise,
      fraisLivraison,
      setFraisLivraison,
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
