import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { utils, writeFile } from 'xlsx';
import axios from 'axios';

import { APP_API_URL, APP_NAME } from '../../utils';
import MagasinierLayout from '../../layout/magasinier-layout';
import Loader from '../../components/loader';
import { generateQuantityDescription } from '../../utils/functions';

export default function MagasinierSummary() {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'product_name', direction: 'ascending' });

  const [isOpen, setIsOpen] = useState(false);

  // États pour les filtres
  const [selectedDate, setSelectedDate] = useState('');

  // États pour les données
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/products-summary`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setData(response.data.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des produits :', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const applyFilters = () => {
    const result = data.filter(item => {
      const matchesDate = selectedDate ? new Date(item.commande.created_at).toDateString() === new Date(selectedDate).toDateString() : true;

      return matchesDate;
    });

    setFilteredData(result);
    setCurrentPage(1);
  };

  useEffect(() => {
    const result = data.filter(item =>
      item.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(result);
    setCurrentPage(1);
  }, [searchTerm, data]);

  const sortedItems = [...filteredData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const handleRefresh = async () => {
    await fetchData();
    setCurrentPage(1);
  };
  
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Produits', 14, 16);
    const tableColumn = ['Nom du produit', 'Quantité en stock', 'Quantité validée'];
    const tableRows = currentItems.map(item => [
      item.product_name,
      item.quantity_in_stock,
      item.quantity_validated,
    ]);
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });
    doc.save(`Produits_${APP_NAME}.pdf`);
  };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(currentItems);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Produits');
    writeFile(workbook, `Produits_${APP_NAME}.xlsx`);
  };

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <MagasinierLayout>
      <div className="mx-auto w-full min-h-full max-w-6xl bg-white p-4 sm:p-6 lg:p-8">
        <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center mb-16'>
          <div className="pagetitle">
            <h1>Produits</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">Résumé des produits</li>
              </ol>
            </nav>
          </div>

          <div className='flex flex-col lg:flex-row gap-x-2 gap-y-4'>
            <div className='flex flex-row gap-x-2'>
              <button title='Exporter en PDF' onClick={exportToPDF} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                <i className="bi bi-filetype-pdf"></i>
              </button>
              <button title='Exporter en Exel' onClick={exportToExcel} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                <i className="bi bi-filetype-xls"></i>
              </button>
              <button title='Imprimer' onClick={handlePrint} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                <i className="bi bi-printer"></i>
              </button>
              <button title='Rafraichir' onClick={handleRefresh} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                <i className="bi bi-arrow-clockwise"></i>
              </button>
            </div>
          </div>
        </div>

        <section className='section'>
          <div className="card mb-4">
            <div className="card-header pb-4">
              <div className="row justify-content-between">
                <div className="col-md-6">
                  <div>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Rechercher..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                {/* 
                <div className="flex items-center justify-end gap-x-2 col-lg-2 col-md-6 mt-3 mt-md-0">
                  <button
                    type="button"
                    title='Options de filtrage'
                    onClick={toggleAccordion}
                    className={`inline-flex items-center border rounded-md px-3 py-2 text-sm font-semibold hover:bg-gray-300 transition-all duration-300 ${isOpen ? 'bg-red-500 text-white' : 'bg-gray-50 text-gray-950'}`}
                  >
                    <i className={`bi ${isOpen ? 'bi-x-lg' : 'bi-funnel-fill'}`}></i>
                  </button>
                </div> */}
              </div>

              <div className="accordion" id="accordionExample">
                <div className="accordaion-item">
                  <div className={`transition-max-height duration-300 ease-in-out overflow-hidden ${isOpen ? 'md:max-h-20' : 'max-h-0'}`}>
                    <div className="accordion-body px-0 border-t my-4">
                      <form className="row g-3">
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-3">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Choisissez une date"
                                value={selectedDate}
                                onChange={e => setSelectedDate(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className='flex justify-end'>
                            <button type="button" onClick={applyFilters} className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 transition-all duration-300">
                              <i className="bi bi-search me-1" /> Recherche
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body p-0">
              <div className="table-responsive table-card">
                <table className="table text-nowrap mb-0 table-centered table-hover">
                  <thead className="table-light">
                    <tr>
                      <th className="ps-3" onClick={() => requestSort('product_name')}>
                        <button className="inline-flex items-center justify-between w-full">
                          Référence
                          <i className="bi bi-arrow-down-up text-gray-400"></i>
                        </button>
                      </th>
                      <th className="" onClick={() => requestSort('unit_symbol')}>
                        <button className="inline-flex items-center justify-between w-full">
                          Unité
                          <i className="bi bi-arrow-down-up text-gray-400"></i>
                        </button>
                      </th>
                      <th className="" onClick={() => requestSort('quantity_in_stock')}>
                        <button className="inline-flex items-center justify-between w-full">
                          Quantité en stock
                          <i className="bi bi-arrow-down-up text-gray-400"></i>
                        </button>
                      </th>
                      <th className="" onClick={() => requestSort('quantity_validated')}>
                        <button className="inline-flex items-center justify-between w-full">
                          Quantité validée
                          <i className="bi bi-arrow-down-up text-gray-400"></i>
                        </button>
                      </th>
                      <th className="">
                        <button>Quantité réelle en stock</button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0 ? (
                      currentItems.map((product, index) => {
                        const quantityInStock = parseFloat(product.quantity_in_stock || 0);
                        const quantityValidated = parseFloat(product.quantity_validated || 0);
                        const quantityReal = quantityInStock + quantityValidated;

                        return (
                          <tr key={'product-' + index} className="align-middle">
                            <td className="ps-3 font-semibold">{product.product_name}</td>
                            <td>{product.unit_symbol}</td>
                            <td>{generateQuantityDescription(quantityInStock, product)}</td>
                            <td>{generateQuantityDescription(quantityValidated, product)}</td>
                            <td>{generateQuantityDescription(quantityReal, product)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="datatable-empty" colSpan="5">
                          Aucun produit correspondant aux critères de recherche.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </div>
            </div>

            <div className="card-footer d-md-flex justify-content-between align-items-center">
              <span>
                {currentItems.length > 0
                  ? `Affichage de ${Math.max(indexOfFirstItem + 1, 0)} à ${Math.min(indexOfLastItem, currentItems.length)} sur ${sortedItems.length} entrée${sortedItems.length > 1 ? 's' : ''}`
                  : `Aucune entrée à afficher`}
                {currentItems.length === 0 && data.length > 0 && ` (filtré à partir de ${data.length} entrée${data.length > 1 ? 's' : ''} totales)`}
              </span>

              {currentItems.length > 0 && (
                <nav className="mt-2 mt-md-0">
                  <ul className="pagination mb-0">
                    <li className="page-item">
                      <a className="page-link" href="#!" onClick={handlePrevPage} disabled={currentPage === 1}>
                        Précédent
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#!" onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Suivant
                      </a>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </section>
      </div>
    </MagasinierLayout>
  );
};
