import React, { useState } from 'react';
import Header from '../components/header';
import Sidebar from '../components/sidebar';

const MainLayout = ({ children }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <div className={sidebarVisible ? 'toggle-sidebar' : ''}>
      <Header toggleSidebar={toggleSidebar} />

      <Sidebar />

      <main id="main" className="main">
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
