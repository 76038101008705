import React, { useState } from "react";
import axios from "axios";
import { APP_API_URL } from "../../utils";
import Swal from "sweetalert2";
import { formatDateWithTime } from "../../utils/functions";

export default function ConfirmReception({ provision, refresh }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmReception = async () => {
    if (provision.status === "terminé") {
      Swal.fire({
        icon: "info",
        title: "Action impossible",
        text: "Cet approvisionnement est déjà terminé. Vous ne pouvez pas confirmer la réception.",
      });
      return;
    }

    // Confirmation avec SweetAlert2
    Swal.fire({
      title: "Confirmer la réception ?",
      text: "Voulez-vous confirmer la réception de cet approvisionnement ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui, confirmer",
      cancelButtonText: "Annuler",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        try {
          const token = localStorage.getItem("token");
          const response = await axios.put(
            `${APP_API_URL}/approvisionnement/${provision.id}/completer`,
            {}, // Ajoutez des données si nécessaire
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Réception confirmée",
              text: "La réception de l'approvisionnement a été confirmée avec succès.",
              confirmButtonText: "OK",
            }).then(() => {
              const close_modal = document.getElementById(`confirm_reception_close_modal_${provision.id}`);
              if (close_modal) close_modal.click();
              refresh(); // Met à jour la liste ou les données
            });
          }
        } catch (error) {
          console.error("Erreur lors de la confirmation de la réception", error);

          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Une erreur est survenue lors de la confirmation. Veuillez réessayer.",
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const handleDeleteProvision = async () => {
    Swal.fire({
      title: "Supprimer cet approvisionnement ?",
      text: "Cette action est irréversible. Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const token = localStorage.getItem("token");
          const response = await axios.put(
            `${APP_API_URL}/approvisionnement/${provision.id}/delete`,
            {}, // Ajoutez des données si nécessaire
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Suppression réussie",
              text: "L'approvisionnement a été supprimé avec succès.",
              confirmButtonText: "OK",
            }).then(() => {
              const close_modal = document.getElementById(`confirm_reception_close_modal_${provision.id}`);
              if (close_modal) close_modal.click();
              refresh(); 
            });
          }
        } catch (error) {
          console.error("Erreur lors de la suppression de l'approvisionnement", error);

          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Une erreur est survenue lors de la suppression. Veuillez réessayer.",
          });
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  return (
    <div
      className="modal fade"
      id={`confirmReceptionModal${provision.id}`}
      tabIndex={-1}
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        {provision.status === "terminé" ? (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-lg font-bold text-gray-600">Réception</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body whitespace-normal">
              <div className="flex flex-col justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-blue-600"
                  width="8em"
                  height="8em"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                    <path d="M12 17v-5h-.5m0 5h1M12 9.5V9" />
                    <path d="M5.98 10.762C8.608 5.587 9.92 3 12 3c2.08 0 3.393 2.587 6.02 7.762l.327.644c2.182 4.3 3.274 6.45 2.287 8.022C19.648 21 17.208 21 12.327 21h-.654c-4.88 0-7.321 0-8.307-1.572c-.987-1.572.105-3.722 2.287-8.022z" />
                  </g>
                </svg>
                <p>Cet approvisionnement est déjà livré.</p>

                {/* Affichage des informations supplémentaires */}
                <div className="mt-4 w-full text-left">
                  <p><strong>Produit :</strong> {provision?.produit?.name}</p>
                  <p><strong>Référence :</strong> {provision?.produit?.reference}</p>
                  <p><strong>Quantité :</strong> {provision?.quantity} unités</p>
                  <p><strong>Prix unitaire :</strong> {provision?.prix_vente} FCFA</p>
                  <p><strong>Total prix d'achat :</strong> {provision?.total_prix_achat} FCFA</p>
                  <p><strong>Fournisseur :</strong> {provision?.fournisseur?.nom}</p>
                  <p><strong>Entrepôt :</strong> {provision?.warehouse?.name}</p>
                  <p><strong>Date approvisionnement :</strong> {formatDateWithTime(provision?.created_at)}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {isLoading ? (
                <button
                  type="button"
                  disabled
                  className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-2 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Chargement...
                </button>
              ) : (
                <>
                  <button
                    onClick={handleDeleteProvision}
                    className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                  >
                    Supprimer
                  </button>
                </>
              )}
              <button
                type="button"
                data-bs-dismiss="modal"
                id={`confirm_reception_close_modal_${provision.id}`}
                className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                Fermer
              </button>
            </div>
          </div>
        ) : (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-lg font-bold text-gray-600">Confirmer la réception</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body whitespace-normal">
              <div className="flex flex-col justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-blue-600"
                  width="8em"
                  height="8em"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                    <path d="M12 17v-5h-.5m0 5h1M12 9.5V9" />
                    <path d="M5.98 10.762C8.608 5.587 9.92 3 12 3c2.08 0 3.393 2.587 6.02 7.762l.327.644c2.182 4.3 3.274 6.45 2.287 8.022C19.648 21 17.208 21 12.327 21h-.654c-4.88 0-7.321 0-8.307-1.572c-.987-1.572.105-3.722 2.287-8.022z" />
                  </g>
                </svg>
                <p>
                  Voulez-vous vraiment confirmer la réception ?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              {isLoading ? (
                <button
                  type="button"
                  disabled
                  className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-2 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Chargement...
                </button>
              ) : (
                <>
                  <button
                    onClick={handleConfirmReception}
                    className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300 me-2"
                  >
                    Confirmer
                  </button>
                  <button
                    onClick={handleDeleteProvision}
                    className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
                  >
                    Supprimer
                  </button>
                </>
              )}
              <button
                type="button"
                data-bs-dismiss="modal"
                id={`confirm_reception_close_modal_${provision.id}`}
                className="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
              >
                Fermer
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
