import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

import { useCart } from '../../../context/CartContext';
import { useDataContext } from '../../../context/DataContext';
import { APP_API_URL } from '../../../utils';
import Add from '../../customers/add';
import Loader from '../../../components/loader';
import formatNumber from '../../../utils/functions';
import UICustomerDropdown from '../../../components/ui/ui-customer-dropdown';
import { useSelector } from 'react-redux';
import AdminOrderLayout from '../../../layout/adminorder-layout';

const OauthAccess = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { categories } = useDataContext();
  const { cart, setCart, selectedCustomer, setSelectedCustomer, remise, setRemise, fraisLivraison, setTotal, total, subTotal, setSubTotal } = useCart(); // Utiliser le contexte
  const [categoriesData, setCategoriesData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPaiement, setSelectedPaiement] = useState({ label: 'Choisissez un mode de paiement', value: null });
  const [remisePercentage, setRemisePercentage] = useState(0);
  const [remiseFixed, setRemiseFixed] = useState(0);
  const [loading, setLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [motif, setMotif] = useState('');
  const [montantDeduis, setMontantDeduis] = useState(null);

  useEffect(() => {
    if (categories && categories.length > 0) {
      const filteredCategories = categories.filter(category => category.type_categorie === null);
      setCategoriesData(filteredCategories);
    }
  }, [categories]);

  const fetchProductsData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/variants`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProductsData(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des variants :', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFilteredVariants = async (categoryId) => {
    setProductLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/search/variants`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: { categorie_id: categoryId }
      });
      setProductsData(response.data.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des variants :', error);
    } finally {
      setProductLoading(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APP_API_URL}/customers`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCustomers(response.data.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des clients :', error);
    }
  };

  useEffect(() => {
    fetchProductsData();
    fetchCustomers();
  }, []);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    fetchFilteredVariants(categoryId); // Récupérer les variantes filtrées par catégorie
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredItem = productsData.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCustomersSelect = (option) => {
    setSelectedCustomer(option);
  };

  const handleAddCustomer = (newCustomer) => {
    setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
    setSelectedCustomer({
      label: newCustomer.name,
      value: newCustomer.id,
      customer: newCustomer,
    });
  };

  const addToCart = (product) => {
    setCart((prevCart) => {

      const productInCart = prevCart.find(item => item.id === product.id);
      let updatedCart;

      if (productInCart) {
        updatedCart = prevCart.map(item =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        updatedCart = [
          ...prevCart,
          {
            ...product,
            quantity: 1, // Quantité initiale
            initialQuantity: product.quantity,
          }
        ];
      }

      return updatedCart;
    });
  };

  // Mettre à jour la quantité
  const updateQuantity = (productId, quantity) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.map(item =>
        item.id === productId ? { ...item, quantity: Math.max(0, quantity) } : item
      );
      // saveCartToLocalStorage(updatedCart); // Sauvegarder après mise à jour
      return updatedCart;
    });
  };

  // Supprimer du panier
  const removeFromCart = (productId) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.filter(item => item.id !== productId);
      // saveCartToLocalStorage(updatedCart); // Sauvegarder après suppression
      return updatedCart;
    });
  };

  const isProductInCart = (product) => {
    return cart.some(item => item.id === product.id);
  };

  const calculateSubtotal = () => {
    return cart.reduce((subtotal, product) => subtotal + product.price * product.quantity, 0);
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const totalRemisePercentage = remisePercentage > 0 ? (remisePercentage / 100) * subtotal : 0;
    const totalRemiseFixed = remiseFixed > 0 ? remiseFixed : 0;

    // Calculer la remise totale (peut être uniquement un type de remise)
    const totalRemise = totalRemisePercentage + totalRemiseFixed;

    // Mettre à jour la remise dans le contexte
    setRemise(totalRemise);

    return subtotal - totalRemise + fraisLivraison;
  };

  useEffect(() => {
    setTotal(calculateTotal()); // Met à jour le total
  }, [cart, remisePercentage, remiseFixed, fraisLivraison]);

  useEffect(() => {
    const newSubtotal = calculateSubtotal();
    setSubTotal(newSubtotal);
  }, [cart]);

  const handleRefresh = async () => {
    await fetchCustomers();
  };

  const resetFilters = () => {
    setSearchQuery(""); // Réinitialiser la recherche
    setSelectedCategory(null); // Réinitialiser la catégorie sélectionnée
    fetchProductsData(); // Recharger tous les produits
    setCart([]);
    localStorage.removeItem('cart');
    setSelectedCustomer({ label: "Sélectionner un produit", value: "" })
    setSelectedPaiement({ label: "Sélectionner un produit", value: "" })
    setRemisePercentage('');
    setRemiseFixed('');
    setRemise(0);
  };

  const handleSaveStandardFormat = async () => {
    setLoading(true);
    const productCount = cart.length; // Utiliser le nombre de produits dans le panier

    const cartState = {
      cart,
      selectedCustomer,
      selectedPaiement,
      remisePercentage,
      remiseFixed,
      subTotal,
      total,
      remise,
      id: `ASB-${Date.now()}_${productCount}`, // Remplacer Math.floor par productCount
      isProFormat: false,
    };

    localStorage.setItem('cartState', JSON.stringify(cartState));

    const tvaPourcentage = parseFloat(cartState.tva || "18");
    const aibPourcentage = parseFloat(cartState.aib || "1");

    const montantTTC = cartState.cart.reduce((total, produit) => {
      return total + (produit.price) * produit.quantity;
    }, 0);

    // Recalculer le montant HT depuis le montant TTC
    const totalTaxPercentage = tvaPourcentage + aibPourcentage;
    const montantHT = montantTTC / (1 + totalTaxPercentage / 100);

    // Appliquer la remise sur le montant HT
    const montantHTAvecRemise = montantHT - (cartState.remise || 0);

    // Recalculer les taxes sur le montant HT après remise
    const montantTVA = montantHTAvecRemise * (tvaPourcentage / 100);
    const montantAIB = montantHTAvecRemise * (aibPourcentage / 100);

    // Le montant net est calculé sur la base du montant HT avec les taxes ajoutées
    const montantNet = montantHTAvecRemise + montantTVA + montantAIB;

    const nouveauxProduits = cartState.cart.map((produit) => ({
      variant_id: produit.id,
      quantite: produit.quantity,
      prix_unitaire: produit.price,
      unite: produit.unit,
      produit_id: produit.product.id,
      name: produit.name,
    }));

    const formData = {
      designation: cartState.id,
      statut_commande: 'approuvé',
      remarques: motif,
      customer_id: cartState.selectedCustomer.value,
      user_id: user?.id,
      store_id: user?.stores[0]?.id || user?.personnels[0]?.store_id,
      produits: nouveauxProduits,
      tva: `${montantTVA}`,
      aib: `${montantAIB}`,
      montant_ht: montantHTAvecRemise,
      montant_net: montantNet,
      montant_net: montantNet,
    };

    const invalidProduct = cartState.cart.some(produit => produit.quantity <= 0);

    if (invalidProduct) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Un ou plusieurs produits ont une quantité inférieure ou égale à zéro.',
      });
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${APP_API_URL}/oauth-access`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Succès!',
          text: 'La commande a été enregistrée avec succès.',
          timer: 2000,
          showConfirmButton: false
        });
        navigate('/admin/oauth-access/orders');
      }

    } catch (error) {
      if (error.response && error.response.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur de validation',
          text: 'Certains champs sont invalides. Veuillez vérifier et réessayer.',
        });
      } else {
        console.error('Erreur lors de l\'envoi du formulaire', error);
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors de l\'envoi du formulaire. Veuillez réessayer plus tard.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <AdminOrderLayout>
      <div className='row mb-8'>
        <div className='col-xl-8 contact'>
          <div className='flex flex-col lg:flex-row lg:items-center mb-6'>
            <div className="pagetitle gap-x-2 flex md:flex-row flex-col gap-y-2">
              <input
                type="search"
                className='form-control form-control-sm w-full sm:w-auto'
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Rechercher un produit"
              />
              <button title='Rafraichir' onClick={resetFilters} type="button" className="inline-flex items-center border rounded-md bg-gray-50 px-2.5 py-2 text-sm font-semibold text-gray-950 hover:bg-gray-300 transition-all duration-300">
                <i className="bi bi-arrow-clockwise"></i>
              </button>
            </div>
          </div>

          <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center'>
            <div className="pagetitle">
              <h1>Categories</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">Sélectionnez parmi les catégories ci-dessous</li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row row-cols-2 row-cols-md-2 row-cols-md-4 g-3">
            {categoriesData.map((item, index) => (
              <div key={index} className={`cursor-pointer`} onClick={() => handleCategoryClick(item.id)}>
                <div className={`info-box card text-center mr-4 ${selectedCategory === item.id && 'bg-lightgreen/75 text-white'}`}>
                  <h3>{item.nom}</h3>
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center'>
            <div className="pagetitle">
              <h1>Produits</h1>
            </div>
          </div>

          {productLoading ? ( // Condition pour afficher le loader
            <div className="row row-cols-1 row-cols-md-2 row-cols-md-4 g-3">
              {/* Générer plusieurs skeleton cards */}
              {[...Array(8)].map((_, index) => (
                <div className="col" key={`skeleton-${index}`}>
                  <div className="animate-pulse bg-white rounded-md shadow-sm border-gray-300 border-[1px] p-3">
                    {/* Skeleton du titre */}
                    <div className="h-5 bg-gray-200 rounded w-2/3 mb-3"></div>

                    {/* Skeleton des informations */}
                    <div className="flex flex-row justify-between items-center">
                      <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/3"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : filteredItem.length === 0 ? (
            // Afficher ce message si la liste de produits est vide
            <div className="h-96 flex flex-row justify-center items-center text-center">
              <img src="/assets/img/no-product.png" width={300} height={300} alt="no-products" />
            </div>
          ) : (
            // Afficher les produits si la liste n'est pas vide
            <div className="row row-cols-1 row-cols-md-2 row-cols-md-4 g-3">
              {filteredItem.map((product) => (
                <div className="col" onClick={() => addToCart(product)} key={'product-' + product.id}>
                  <div
                    className={`group relative bg-white rounded-md cursor-pointer shadow-sm ${isProductInCart(product) ? 'border-lightgreen' : 'border-gray-400'} border-[1px] p-3 hover:border-lightgreen`}
                  >
                    <span className={`bg-lightgreen group-hover:block absolute rounded-full right-3 top-3 text-white p-0.5 ${isProductInCart(product) ? 'block' : 'hidden'}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-check feather-16"
                      >
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                    </span>

                    <div className='mb-3'>
                      <h1 className='font-semibold text-base text-darkgreen'>{product.name}</h1>
                      <h1 className='font-semibold text-xs text-darkgreen'>{product.sku}</h1>
                    </div>

                    <div className='flex flex-row justify-between items-center'>
                      <h1 className='text-blue-700 text-base'>{product.quantity} {product.product?.unit?.symbol}</h1>
                      <h1 className='text-lightgreen text-base'>{formatNumber(product.price)} fcfa</h1>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className='col-xl-4 bg-white p-3'>
          <div className='position-sticky top-20'>
            <div className='flex flex-col sm:flex-row sm:justify-between sm:items-start'>
              <div className="pagetitle">
                <h1>Commande</h1>
              </div>
            </div>

            <div className="col-12 mb-3">
              <div className="flex flex-row justify-between items-center mb-2">
                <label htmlFor="variant" className="form-label mb-0">Informations sur les clients</label>
                <button
                  className='text-xs'
                  title='Ajouter un client'
                  data-bs-toggle="modal"
                  data-bs-target="#addCustomerModal"
                >
                  <i className={`bi bi-plus`}></i> Ajouter un client
                </button>
              </div>
              <UICustomerDropdown
                options={customers.map(item => ({
                  label: item.name,
                  value: item.id,
                  phone: item.numero,
                  client_code: item.client_code,
                  email: item.email,
                  customer: item
                }))}
                selectedOption={selectedCustomer}
                onSelect={handleCustomersSelect}
                enableSearch={true}
              />
            </div>

            <div className='pt-4 my-4 border-t border-gray-200'>
              <div className='flex flex-row justify-between items-center'>
                <h1 className='font-bold flex items-center'>Produit ajouté <span className="w-[15px] h-[15px] flex items-center justify-center bg-lightgreen rounded-full text-white text-[10px] font-semibold ml-2">{cart.length}</span></h1>

                <button
                  className='text-sm text-red-500'
                  onClick={() => {
                    setCart([]);
                    localStorage.removeItem('cart');
                    setSelectedCustomer({ label: "Sélectionner un produit", value: "" }) // Supprime le panier du localStorage
                  }}
                >
                  <i className={`bi bi-x`}></i> Nettoyer
                </button>
              </div>
            </div>

            <div className='max-h-60 overflow-y-scroll no-scrollbar'>
              {cart.length === 0 ? (
                <div className="h-full flex flex-row justify-center items-center text-center">
                  <img src="/assets/img/no-product.png" width={200} height={200} alt="no-products" />
                </div>
              ) : (
                <div className='table-responsive table-card'>
                  {cart.map((product) => (
                    <div className="mb-3 border rounded-xl p-3" key={product.id}>
                      <div className="flex flex-row items-start space-x-4 g-0">
                        <div className="flex-1">
                          <div className="flex justify-between items-start">
                            <span className="badge bg-success">{product.sku}</span>
                            <div>
                              <button
                                title="Supprimer"
                                onClick={() => removeFromCart(product.id)}
                                type="button"
                              >
                                <i className="bi bi-trash text-red-600"></i>
                              </button>
                            </div>
                          </div>
                          <input
                            type="number"
                            name="quantity"
                            className="px-[9px] py-[1px] bg-[#FAFBFE] rounded-lg h-[28px] text-[14px] form-control"
                            value={product.quantity}
                            max={product.initialQuantity}
                            onChange={(e) => {
                              const enteredQuantity = parseFloat(e.target.value);
                              let message = "";
                              let error = "";

                              if (enteredQuantity > product.initialQuantity || enteredQuantity < 0 || isNaN(enteredQuantity)) {
                                // Quantité dépassant le stock initial
                                if (enteredQuantity > product.initialQuantity) {
                                  error = `Quantité demandée (${enteredQuantity}) dépasse la quantité disponible (${product.initialQuantity}).`;
                                }
                                if (enteredQuantity < 0 || isNaN(enteredQuantity)) {
                                  // error = `La quantité entrée n'est pas valide.`;
                                }
                                message = "";
                              } else {
                                // Catégorie 17 (m²)
                                if (product.product.category_id === 17) {
                                  const m2PerPiece = product.nb_m2_par_piece || 0;
                                  const m2PerCarton = product.nb_m2_par_carton || 0;

                                  if (m2PerPiece > 0 && m2PerCarton > 0) {
                                    const totalCartons = Math.floor(enteredQuantity / m2PerCarton);
                                    const remainingM2 = enteredQuantity % m2PerCarton;

                                    if (remainingM2 === 0) {
                                      message = `${totalCartons} carton(s)`;
                                    } else {
                                      const totalPieces = Math.floor(remainingM2 / m2PerPiece);
                                      const extraM2 = (remainingM2 % m2PerPiece);

                                      message = `${totalCartons} carton(s), ${totalPieces} pièce(s)` +
                                        (extraM2 > 0 ? ` et ${extraM2} m²` : "");
                                    }
                                  } else {
                                    message = "Valeurs m²/carton non disponibles";
                                  }
                                } else {
                                  // Autres catégories (pièces)
                                  const piecesPerCarton = product.nb_pieces_par_carton || 0;

                                  if (piecesPerCarton > 0) {
                                    const totalCartons = Math.floor(enteredQuantity / piecesPerCarton);
                                    const remainingPieces = enteredQuantity % piecesPerCarton;

                                    if (remainingPieces === 0) {
                                      message = `${totalCartons} carton(s)`;
                                    } else {
                                      message = `${totalCartons} carton(s) et ${remainingPieces} pièce(s)`;
                                    }
                                  } else {
                                    message = `${enteredQuantity} pièce(s)`;
                                  }
                                }

                                error = "";
                              }

                              // Mettre à jour la quantité si valide
                              if (!error) {
                                updateQuantity(product.id, enteredQuantity);
                              }

                              // Affiche les messages directement dans le DOM
                              const parent = e.target.parentElement;
                              const errorEl = parent.querySelector(".error-message");
                              const messageEl = parent.querySelector(".info-message");

                              if (errorEl) errorEl.textContent = error;
                              if (messageEl) messageEl.textContent = message;
                            }}
                            placeholder="Quantité"
                          />
                          <p className="text-xs text-red-600 mt-1 error-message"></p>
                          <p className="text-xs text-gray-600 mt-1 info-message"></p>
                          <p className="text-sm font-bold mt-2">
                            {formatNumber(product.price * (product.quantity || 0))} FCFA
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className='py-3 rounded-lg'>
              <div className="order-total table-responsive">
                <table className="table table-responsive table-borderless text-sm">
                  <tbody className=''>
                    <tr>
                      <td className='font-bold'>Sous-total</td>
                      <td className="text-end">{formatNumber(calculateSubtotal()) || 0.00} FCFA</td>
                    </tr>
                    <tr>
                      <td className='font-bold text-lg'>Total</td>
                      <td className="text-end text-lg">{formatNumber(calculateTotal()) || 0.00} FCFA</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className='border-t pt-4'>
              <div className="row">
                <div className="col-12 mt-3">
                  <label htmlFor="motif" className="form-label text-gray-950">Motif de la commande</label>
                  <input
                    type="text"
                    className="form-control"
                    id="motif"
                    name="motif"
                    value={motif}
                    onChange={(e) => setMotif(e.target.value)}
                    placeholder="Saisissez la motif de la commande"
                  />
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="montantDeduis" className="form-label text-gray-950">Montant deduis</label>
                  <input
                    type="number"
                    className="form-control"
                    id="montantDeduis"
                    name="montantDeduis"
                    value={montantDeduis}
                    onChange={(e) => setMontantDeduis(e.target.value)}
                    placeholder="Saisissez la montant deduis de la commande"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-between mt-4 gap-x-4">
              <button
                onClick={() => {
                  // Afficher la boîte de dialogue de confirmation
                  Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Cela effacera toutes les informations du panier et du client.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Oui, nettoyer!',
                    cancelButtonText: 'Annuler',
                    confirmButtonColor: "#006633",
                    cancelButtonColor: "#d33",
                    reverseButtons: true
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // Si l'utilisateur confirme, effectuer l'action de nettoyage
                      setCart([]);
                      localStorage.removeItem('cart');
                      setSelectedCustomer({ label: "Sélectionner un produit", value: "" });
                      setSelectedPaiement({ label: "Sélectionner un produit", value: "" });
                      setRemisePercentage('');
                      setRemiseFixed('');
                      setRemise(0);

                      // Afficher une notification de succès
                      Swal.fire(
                        'Nettoyé!',
                        'Le panier et les informations ont été réinitialisés.',
                        'success'
                      );
                    }
                  });
                }}
                type="button"
                className="inline-flex items-center justify-center w-1/2 rounded-md bg-gray-500 px-2.5 py-2 text-sm font-semibold text-white hover:bg-gray-800 transition-all duration-300"
              >
                Nettoyer
              </button>
              <button
                type="button"
                className={`inline-flex items-center w-full justify-center rounded-md px-3 py-2.5 text-base font-semibold text-white transition-all duration-300 
                ${motif && calculateTotal() > 0 && selectedCustomer?.value ? 'bg-green-600 hover:bg-green-500' : 'bg-gray-400 cursor-not-allowed'}`}
                onClick={handleSaveStandardFormat}
                disabled={!motif || calculateTotal() === "0" || !selectedCustomer?.value || loading}
              >
                Valider la commande : {formatNumber(calculateTotal())} FCFA
              </button>
            </div>
          </div>
        </div>
      </div>
      <Add refreshData={handleRefresh} onAddCustomer={handleAddCustomer} />
    </AdminOrderLayout>
  );
};

export default OauthAccess;
