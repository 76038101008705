import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { logout } from '../actions/auth-actions';

const IsActif = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Utilisation de SweetAlert pour la confirmation
    Swal.fire({
      title: 'Êtes-vous sûr de vouloir vous déconnecter ?',
      text: "Cette action vous déconnectera de votre compte.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, déconnectez-moi',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        // Si l'utilisateur confirme, déconnectez-le
        dispatch(logout());
        navigate('/auth/login');
        Swal.fire(
          'Déconnecté !',
          'Vous avez été déconnecté avec succès.',
          'success'
        );
      }
    });
  };
  return (
    <div className="container">
      <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <p className="text-lightgreen font-bold text-3xl sm:text-[10rem] leading-none">
          Compte Inactif
        </p>
        <p className="text-darkgreen font-semibold text-center text-xl sm:text-4xl sm:my-4 my-2">
          Votre compte est inactif, veuillez attendre l'activation.
        </p>
        <button className="btn btn-primary" onClick={handleLogout}>
          Déconexion
        </button>
      </section>
    </div>
  );
};

export default IsActif;
