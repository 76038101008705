import React from 'react'
import { useLocation } from 'react-router-dom'
import FactureProFormatComponent from '../../components/pro-format'
import AdminOrderLayout from '../../layout/adminorder-layout'

export default function AdminProFormatInvoice () {
  const location = useLocation()
  const cartState = location.state

  const handlePrint = () => {
    window.print()
  }

  return (
    <AdminOrderLayout>
      <div className='mx-auto w-full max-w-6xl bg-white p-4 sm:p-6 lg:p-8'>
        <div className='printable w-full'>
          <FactureProFormatComponent invoiceData={cartState} isCopy={false} />

          <div className='modal-footer py-4 space-x-4 no-print'>
            <button
              type='button'
              onClick={handlePrint}
              className='inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300'
            >
              Imprimer
            </button>
          </div>
        </div>
      </div>
    </AdminOrderLayout>
  )
}
