import React from 'react';
import CaissierHeader from '../components/caissier-header';

const CaissierLayout = ({ children }) => {
  return (
    <div>
      <CaissierHeader />

      <section className="px-3 mt-20">
        {children}
      </section>
    </div>
  );
};

export default CaissierLayout;
